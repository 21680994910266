import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import ChangeUserPassword from "../users/Manage/ChangeUserPassword";

const actions = { closeModal };

const changeUserPasswordModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Change User Password" onClose={closeModal}>
      <ChangeUserPassword />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(changeUserPasswordModal);

import firebase from "../../../app/config/firebase";

const asyncValidate = async email => {
  const firestore = firebase.firestore();

  return await firestore
    .collection("users")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().email === email) {
          throw { email: "Supplied emailis taken" };
        }
      })
    );
};

export default asyncValidate;

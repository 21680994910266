import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
// import EnableMerchant from "./EnableMerchant";
// import { activateMerchant } from "../../../merchants/merchantActions";

class Index extends Component {
  state = {
    openAdditionalInfoPanel: false,
    selectedRefund: null
  };

  activateMerchantPanelOpenClasses = [classes.RevealMore];

  handleRefundClick = refund => {
    this.setState({
      openAdditionalInfoPanel: true,
      selectedRefund: null
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null
    });
  };

  render() {
    console.log("this.props", this.props);

    let { refundsList, activateMerchant } = this.props;
    // const loading =
    //   requesting[`merchant_balance?where=transactionType:==:'Bank Refund'`];
    let { openAdditionalInfoPanel, selectedRefund } = this.state;

    let numberOfRecords = refundsList && refundsList.length;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    // if (loading)
    //   return (
    //     <Fragment>
    //       <LoadingComponent inverted={true} />
    //     </Fragment>
    //   );

    // merchantList &&
    //   this.setState({
    //     loadingInitial: false
    //   });

    // let refunds = (
    //   <Fragment>
    //     <LoadingComponent inverted={true} />
    //   </Fragment>
    // );

    const refunds =
      refundsList &&
      Object.values(refundsList).map((refund, index) => {
        return (
          <tr
            className={classes.MerchantRow}
            key={index}
            onClick={() => this.handleMerchantClick(refund)}
          >
            <td>
              {format(
                refund.transactionDateTime &&
                  refund.transactionDateTime.toDate(),
                "YYYY-MM-DD"
              )}
            </td>{" "}
            <td>{refund.transactionAmount}</td> <td>{refund.refundMethod}</td>
            <td>{refund.balance}</td>
            <td>
              {format(
                refund.dateCreated && refund.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {refunds && refunds.length === 0 ? (
            <h3>Merchant has no refunds for the selected period.</h3>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Refund Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col"> Refund Method</th>
                      <th className={"hide-s"} scope="col">
                        Balance
                      </th>
                      <th className={"hide-s"} scope="col">
                        Date Recorded
                      </th>
                    </tr>
                  </thead>
                  <tbody>{refunds}</tbody>
                </table>

                {openAdditionalInfoPanel ? (
                  <div className={classes.EnableMerchant}>
                    {/* <div className={classes.EnableMerchant}>
                      <div className={"card card-transparent"}>
                        <div
                          className={"card-header "}
                          style={{
                            padding: "0",
                            minHeight: "4.1rem",
                            padding: "1rem",
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500"
                          }}
                        >
                          <div
                            className={"card-title semi-bold"}
                            style={{
                              color: "#666",
                              fontSize: "1.4rem",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textTransform: "none",
                              marginLeft: "0.7rem"
                            }}
                          >
                            Enable Merchant
                          </div>
                          <div className={"card-controls"}>
                            <ul>
                              <li>
                                <a
                                  data-toggle="close"
                                  className={"card-close"}
                                  href="#"
                                  onClick={this.handleCloseClick}
                                >
                                  <i className={"card-icon card-icon-close"} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"card-body"}>
                          <div className={"panel"}>
                            <Fragment>
                              <ul
                                className={
                                  "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                }
                              >
                                <li className={"nav-item active"}>
                                  <a
                                    data-toggle="tab"
                                    href="#activation"
                                    className={"active show"}
                                  >
                                    Enable Merchant
                                  </a>
                                </li>
                              </ul>
                              <div className={"tab-content"}>
                                <div
                                  className={"tab-pane active"}
                                  id="activation"
                                >
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <EnableMerchant
                                        merchant={selectedMerchant}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                ) : null}
              </div>
              <div className={classes.Row}>
                <div>
                  <div
                    className={attachedPaginateSimpleNumbersClasses.join(" ")}
                  >
                    <ul>
                      <li>
                        <a href="">
                          <i className={classes.pg_arrow_left} />
                        </a>
                      </li>
                      <li className={attachedPaginateActiveClasses.join(" ")}>
                        <a href="">1</a>
                      </li>
                      <li
                        className={attachedPaginateNextClasses.join(" ")}
                        id="tableWithExportOptions_next"
                      >
                        <a href="">
                          <i className={classes.pg_arrow_right} />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={classes.dataTables_info}
                    id="tableWithExportOptions_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing <b>1 to {numberOfRecords}</b> of {numberOfRecords}{" "}
                    entries
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

// let query = [
//   {
//     collection: "merchant_balance",
//     where: ["transactionType", "==", "Bank Refund"]
//   }
// ];

// const query = [
//   {
//     collection: "merchants",
//     orderBy: ["name", "desc"]
//   }
// ];

// const mapStateToProps = state => {
//   const { items, searchText } = state.financials;
//   return {
//     refundsList: state.financials.filter((item) => item.transactionType == "Bank Refund)
//   };
// };

// refundsList: state.financials.filter((item) => item.transactionType == "Bank Refund)
const mapStateToProps = state => ({
  refundsList: state.financials.filter(
    item => item.transactionType == "Withdrawal"
  )
});

// const actions = {
//   activateMerchant
// };

export default connect(mapStateToProps)(Index);
//   firestoreConnect(ownProps => [
//     {
//       collection: "merchants",
//       doc: ownProps.match.params.account
//     }
//   ]),

// function mapStateToProps(state) {
//   const { items, searchText } = state.searchSimple;
//   return {
//       filteredItems: items.filter((item) => item.startsWith(searchText))
//   };
// }

import React, { Component, Fragment } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";

// import {fetchMerchantTransactionsList} from '../../../../app/data/firebaseAPI'
import classes from "./ItemisedReport.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ItemisedReport from "./Itemised";

// {merchant,merchantTransactions}

class index extends Component {
  state = {
    initialLoading: true
  };
  // async componentDidMount() {
  //   const { firestore, match } = this.props;

  //   let merchant = await firestore.get(`merchants/${match.params.account}`);

  //   if (!merchant.exists) {
  //     toastr.error("Not found", "This is not the merchant you are looking for");
  //     this.props.history.push("/merchants");
  //   }

  //   firestore.setListener(`merchants/${match.params.account}`);

  //   this.setState({
  //     initialLoading: false
  //   });
  // }

  render() {
    const { merchant, outlets, match } = this.props;
    const merchantsLink = `/merchants`;
    const selectedMerchantLink = `/merchants/${match.params.account}`;
    const itemisedReportLink = `/merchants/${match.params.account}/reports`;

    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (!outlets || !merchant) return <LoadingComponent inverted={true} />;

    itemisedReport = outlets && <ItemisedReport outlets={outlets} />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={merchantsLink}>Merchants</Link>
                {" - "}
                <Link to={selectedMerchantLink}>{merchant[0].name}</Link>
                {" - "}
                <Link to={itemisedReportLink}>Reports</Link> {" - "} Itemised
              </span>
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Itemised Report</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {itemisedReport}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// export default compose(
//   firestoreConnect(ownProps => [
//     {
//       collection: "merchants",
//       storeAs: "outlets",
//       doc: ownProps.match.params.account,
//       subcollections: [{ collection: "outlets" }]
//     }
//   ]),
//   connect(({ firestore: { ordered } }, props) => ({
//     outlets: ordered.outlets
//   }))
// )(index);

// const mapStateToProps = state => ({
//   merchant: state.firestore.ordered.merchant,
//   outlets: state.firestore.ordered.outlets
// });

const mapStateToProps = state => ({
  merchant: state.firestore.ordered.merchant,
  outlets: state.firestore.ordered.outlets
});

const query = ownProps => {
  return [
    {
      collection: "merchants",
      storeAs: "merchant",
      doc: ownProps.match.params.account
    },
    {
      collection: "merchants",
      storeAs: "outlets",
      doc: ownProps.match.params.account,
      subcollections: [{ collection: "outlets" }]
    }
  ];
};

export default compose(
  firestoreConnect(query),
  connect(mapStateToProps)
)(index);

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

import LoadingComponent from "../../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
import ApproveCredit from "./ApproveCredit";
import DeclineCredit from "./DeclineCredit";
import {
  approveCredit,
  declineCredit
} from "../../../financials/financialActions";

class index extends Component {
  state = {
    approveCreditPanelOpen: false,
    selectedCredit: null,
    loadingInitial: true,
    selectedRow: -1
  };

  TableRowClasses = null;
  approveCreditPanelOpenClasses = [classes.RevealMore];

  handleCreditItemClick = (credit, index) => {
    this.setState({
      approveCreditPanelOpen: true,
      selectedCredit: credit,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    this.setState({
      approveCreditPanelOpen: false,
      selectedCredit: null,
      selectedRow: -1
    });
  };
  render() {
    let { creditList, approveCredit, requesting } = this.props;
    const loading = requesting[`credit?where=status:==:"Credit Application"`];

    const { approveCreditPanelOpen, selectedCredit, selectedRow } = this.state;

    !selectedRow
      ? (this.TableRowClasses = [classes.TableRow])
      : (this.TableRowClasses = [
          classes.TableRowRow,
          classes.SelectedTableRow
        ]);

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    // merchantList &&
    //   this.setState({
    //     loadingInitial: false
    //   });

    const creditLimit =
      creditList &&
      Object.values(creditList).map((item, index) => {
        return (
          <tr
            key={index}
            onClick={() => this.handleCreditItemClick(item, index)}
            className={
              this.state.selectedRow === index
                ? classes.SelectedTableRow
                : classes.TableRow
            }
          >
            <td>
              {format(
                item.dateCreated && item.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{item.name}</td>
            <td>{item.account}</td>

            <td>{moneyFormat(item.amount)}</td>
            <td>
              {format(
                item.expiryDate && item.expiryDate.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{item.status}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {creditList && creditList.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>There are no Credit Applications to approve.</h3>
            </div>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Date Applied</th>
                      <th scope="col">Name</th>
                      <th scope="col">Account</th>
                      <th scope="col">Credit Requested</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Request Status</th>
                    </tr>
                  </thead>
                  <tbody>{creditLimit}</tbody>
                </table>

                {approveCreditPanelOpen ? (
                  <div className={classes.EnableMerchant}>
                    <div className={classes.EnableMerchant}>
                      <div className={"card card-transparent"}>
                        <div
                          className={"card-header "}
                          style={{
                            padding: "0",
                            minHeight: "4.1rem",
                            padding: "1rem",
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500"
                          }}
                        >
                          <div
                            className={"card-title semi-bold"}
                            style={{
                              color: "#666",
                              fontSize: "1.4rem",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textTransform: "none",
                              marginLeft: "0.7rem"
                            }}
                          >
                            Credit Application
                          </div>
                          <div className={"card-controls"}>
                            <ul>
                              <li>
                                <a
                                  data-toggle="close"
                                  className={"card-close"}
                                  href="#"
                                  onClick={this.handleCloseClick}
                                >
                                  <i className={"card-icon card-icon-close"} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"card-body"}>
                          <div className={"panel"}>
                            <Fragment>
                              <ul
                                className={
                                  "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                }
                              >
                                <li className={"nav-item active"}>
                                  <a
                                    data-toggle="tab"
                                    href="#approve"
                                    className={"active show"}
                                  >
                                    Approve
                                  </a>
                                </li>
                                <li className={"nav-item active"}>
                                  <a data-toggle="tab" href="#decline">
                                    Decline
                                  </a>
                                </li>
                              </ul>
                              <div className={"tab-content"}>
                                <div className={"tab-pane active"} id="approve">
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <ApproveCredit
                                        credit={selectedCredit}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className={"tab-pane"} id="decline">
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <DeclineCredit
                                        credit={selectedCredit}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

let query = [
  {
    collection: "credit",
    where: [["status", "==", "Pending Approval"]]
  }
];

const mapStateToProps = state => ({
  creditList: state.firestore.ordered.credit,
  requesting: state.firestore.status.requesting
});

const actions = {
  approveCredit
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(index));

const validate = values => {
  const errors = {};
  if (!values.outletName) {
    errors.outletName = "Required";
  }

  if (!values.outletCity) {
    errors.outletCity = "Required";
  }
  if (!values.outletSuburb) {
    errors.outletSuburb = "Required";
  }
  if (!values.outlet_contact_name) {
    errors.outlet_contact_name = "Required";
  }
  if (!values.outlet_contact_email) {
    errors.outlet_contact_email = "Required";
  }
  if (!values.outlet_contact_telephone) {
    errors.outlet_contact_telephone = "Required";
  }

  return errors;
};

export default validate;

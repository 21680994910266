import {
    combineReducers
} from 'redux';
import {
    firebaseReducer
} from 'react-redux-firebase';
import {
    firestoreReducer
} from 'redux-firestore';
import {
    reducer as FormReducer
} from 'redux-form';
import modalReducer from '../features/modals/modalReducer'
import sideDrawerReducer from '../features/sideDrawer/sideDrawerReducer'
import merchantReducer from '../features/merchants/merchantReducer';
import usersReducer from '../features/users/usersReducer';
import inventoryReducer from '../features/inventory/inventoryReducer';
import reportReducer from '../features/reports/reportReducer';
import financialsReducer from '../features/financials/financialsReducer';
import transactionsReducer from '../features/transactions/transactionsReducer';

import settingsReducer from '../features/settings/settingsReducer';
import asyncReducer from '../features/async/asyncReducer';
import {
    reducer as toastrReducer
} from 'react-redux-toastr';

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: FormReducer,
    modals: modalReducer,
    sideDrawer: sideDrawerReducer,
    merchants: merchantReducer,
    inventory: inventoryReducer,
    reports: reportReducer,
    financials: financialsReducer,
    transactions: transactionsReducer,
    settings: settingsReducer,
    async: asyncReducer,
    users: usersReducer,
    toastr: toastrReducer
});

export default rootReducer;


// export const createRootReducer = asyncReducers => {
//     const appReducer = combineReducers({
//         myReducer...asyncReducers
//     });
//     return (state, action) => {
//         if (action.type === 'LOGOUT_USER') {
//             state = undefined;
//         }
//         return appReducer(state, action);
//     }
// };
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import ReactExport from "react-data-export";
import format from "date-fns/format";

// import { ExcelFile, ExcelSheet } from "react-data-export";

import { openModal } from "../modals/modalActions";
import LoadingComponent from "../../app/layout/LoadingComponent";
import classes from "./Merchants.module.css";
import Modal from "../../app/UI/Modal/Modal";
import MerchantForm from "./MerchantForm/MerchantForm";
import MerchantList from "./MerchantList/MerchantList";
import { fetchMerchants } from "./merchantActions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let searchBykeyword = "";

class Merchants extends Component {
  state = {
    contextRef: {},
    loadingInitial: true,
    filtered: [],
    showModal: false
  };

  // async componentDidMount() {
  //   let payload = await this.props.fetchMerchants();

  //   if (payload) {
  //     this.setState({
  //       loadingInitial: false
  //     });
  //   }
  // }

  filterByMerchantName = merchant => {
    //let {merchants} = this.props;

    let keyword = merchant.target.value.toLowerCase();
    let filtered = this.props.merchants.filter(merchant => {
      return merchant.name.toLowerCase().indexOf(keyword) > -1;
    });
    this.setState({
      filtered
    });

    searchBykeyword = keyword;
  };

  filterByAccountNumber = merchant => {
    let keyword = merchant.target.value;
    let filtered = this.props.merchants.filter(merchant => {
      return merchant.account.indexOf(keyword) > -1;
    });
    this.setState({
      filtered
    });

    searchBykeyword = keyword;
  };

  // handleContextRef = contextRef => this.setState({ contextRef });

  render() {
    const { openModal, auth, profile } = this.props;
    let merchantsFiltered = this.state.filtered;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;
    const userAccount = profile.account;
    const userGroup = profile.group;

    let merchants =
      userType === "Merchant"
        ? userRole === "Group Administrator"
          ? this.props.merchants.filter(
              merchant => merchant.group === userGroup
            )
          : this.props.merchants.filter(
              merchant => merchant.account === userAccount
            )
        : this.props.merchants;

    // let showModal = this.state.showModal;
    let merchantList = null;
    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let multiDataSet;

    if (merchants) {
      searchBykeyword.length > 0 && merchantsFiltered.length === 0
        ? (merchantList = (
            <div>
              <div style={{ paddingBottom: "30px" }}>
                <h3> No merchants returned for query "{searchBykeyword}" </h3>
              </div>
            </div>
          ))
        : (merchantList = (
            <div>
              {console.log("merchants", merchants && merchants)}

              <MerchantList
                merchants={
                  merchantsFiltered.length === 0 ? merchants : merchantsFiltered
                }
                filterByMerchantName={this.filterByMerchantName}
                filterByAccountNumber={this.filterByAccountNumber}
              />
            </div>
          ));

      let merchantData = merchants.map(merchant => {
        return [
          {
            value: merchant.name,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: merchant.account,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: merchant.contact.telephone,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: merchant.group,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: format(merchant.dateCreated.toDate(), "YYYY-MM-DD"),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: merchant.createdBy,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      multiDataSet = [
        {
          columns: [
            {
              title: "Merchant",
              width: { wpx: 150 },
              style: {
                font: { sz: "16", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Account Number",
              width: { wpx: 150 },
              style: { font: { bold: true } }
            },
            {
              title: "Contact Number",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Group",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Date Registerd",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Created By",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            }
          ],
          data: merchantData
        }
      ];

      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                <span className={classes.Header}>Merchants</span>
                <br />
                <span className={classes.ByLine}>
                  You are in the <span className={"bold"}>Merchants</span> panel
                </span>
              </div>
            </div>

            <div className={classes.Content}>
              <div className={classes.WelcomeContainer}>
                <div className={classes.MerchantProfile}>
                  <div className={"card card-default"}>
                    {/* <div className={"card-header"}>
                <div className={"card-title semi-bold"}>Inventory</div>
              </div> */}
                    <div className={"card-body"}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          <div className={classes.Tools}>
                            <div className={classes.PageFilter}>
                              <h3 className="m-r-20"> Search:</h3>

                              {/* <!-- START Form Control--> */}
                              <div className={attachedClasses.join(" ")}>
                                <label>Merchant Account</label>
                                <div className={"controls"}>
                                  <input
                                    id="merchant-id"
                                    type="text"
                                    className={classes.FormControl}
                                    placeholder="Account Number"
                                    onChange={this.filterByAccountNumber}
                                  />
                                </div>
                              </div>
                              {/* <!-- END Form Control--> */}

                              {/* <!-- START Form Control--> */}
                              <div className={attachedClasses.join(" ")}>
                                <label>Merchant Name</label>
                                <div className={"controls"}>
                                  <input
                                    id="merchant-name"
                                    type="text"
                                    className={classes.FormControl}
                                    placeholder="Merchant Name"
                                    onChange={this.filterByMerchantName}
                                  />
                                </div>
                              </div>
                              {/* <!-- END Form Control--> */}
                            </div>
                            <div className={classes.PrintToolsWrapper}>
                              {userType === "PrepaidPlus" && (
                                <div className={classes.AddNewMerchant}>
                                  <button
                                    className={classes.AddNewMerchantBtn}
                                    onClick={() =>
                                      openModal("CreateMerchantModal")
                                    }
                                  >
                                    Add New Merchant
                                  </button>
                                </div>
                              )}
                              <div className={classes.ToolsPrintIconWrapper}>
                                <ExcelFile
                                  element={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24"
                                      width="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <g>
                                        <path
                                          id="path1"
                                          transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                          fill="#707070"
                                          d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                        />
                                      </g>
                                    </svg>
                                  }
                                >
                                  <ExcelSheet
                                    dataSet={multiDataSet}
                                    name="Organization"
                                  />
                                </ExcelFile>
                              </div>
                              <div
                                className={classes.ToolsDataExportIconWrapper}
                              >
                                {/* <ExcelFile
                                element={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <g>
                                      <path
                                        id="path1"
                                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                        fill="#707070"
                                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                      />
                                    </g>
                                  </svg>
                                }
                              >
                                <ExcelSheet
                                  dataSet={multiDataSet}
                                  name="Organization"
                                />
                              </ExcelFile> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </header>

                      <div className={"card card-transparent"}>
                        <div className={classes.CardBody}>
                          {merchants && merchants.length === 0 ? (
                            <div style={{ paddingBottom: "30px" }}>
                              <h3>No merchants. Add a merchant to proceed</h3>
                            </div>
                          ) : (
                            merchantList
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                <span className={classes.Header}>Merchants</span>
                <br />
                <span className={classes.ByLine}>
                  You are in the <span className={"bold"}>Merchants</span> panel
                </span>
              </div>
            </div>
            <LoadingComponent inverted={true} />
          </div>
        </Fragment>
      );
    }
  }
}

const query = [
  {
    collection: "merchants",
    orderBy: ["name", "asc"]
  }
];

const mapStateToProps = state => ({
  merchants: state.firestore.ordered.merchants,
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

const actions = {
  openModal
};

export default compose(
  firestoreConnect(query),
  connect(
    mapStateToProps,
    actions
  )
)(Merchants);

import { SubmissionError, reset } from "redux-form";
import { closeModal } from "../modals/modalActions";
import { toastr } from "react-redux-toastr";

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";

import { hashPassword } from "../../app/common/util/helpers";

import { history } from "../../app/common/util/history";

export const login = creds => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);
      history.push("/merchants");
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error: error.message
      });
    }
  };
};

export const registerUser = user => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  // const user = values.user;

  try {
    const displayName = `${user.firstName} ${user.lastName}`;
    let newUser;

    let outlets = [];

    for (let i = 0; i < user.outletId.length; i++) {
      let outlet = user.outletId[i].value;
      outlets.push(outlet);
    }
    console.log("outlets", outlets);

    if (user.userType === "Merchant") {
      newUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: displayName,
        email: user.email,
        account: user.account,
        group: user.group,
        outlets: outlets,
        password: hashPassword(user.password),
        role: user.role.value,
        userType: user.userType,
        dateCreated: firestore.FieldValue.serverTimestamp()
      };
    } else {
      newUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: displayName,
        email: user.email,
        account: user.account,
        outletId: user.outletId,
        password: hashPassword(user.password),
        role: user.role.value,
        userType: user.userType,
        dateCreated: firestore.FieldValue.serverTimestamp()
      };
    }

    // create the user in firebase auth
    let createdUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);

    createdUser = firebase.auth().currentUser;

    // createdUser.sendEmailVerification();

    // update the auth profile
    await createdUser.updateProfile({
      displayName: displayName
    });

    await firestore.set(`users/${createdUser.uid}`, { ...newUser });
    dispatch(closeModal());
  } catch (error) {
    console.log(error);
    throw new SubmissionError({
      _error: error.message
    });
  }
};

export const updatePassword = values => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;

  try {
    dispatch(asyncActionStart());

    await user.updatePassword(values.newPassword);
    await dispatch(reset("account"));

    dispatch(closeModal());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Your password has been updated");
  } catch (error) {
    console.log("error.message", error.message);
    // dispatch(asyncActionError());
    // toastr.error("Oops", "Something went wrong");
    throw new SubmissionError({
      _error: error.message
    });
  }
};

export const updateNames = values => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  const currentUser = firebase.auth().currentUser;
  const now = new Date();

  console.log("values", values);

  try {
    dispatch(asyncActionStart());

    currentUser
      .updateProfile({
        displayName: values.displayName
      })
      .then(async user => {
        await firestore
          .collection("users")
          .doc(currentUser.uid)
          .set(
            {
              displayName: values.displayName,
              updatedBy: currentUser.displayName,
              dateUpdated: now
            },
            {
              merge: true
            }
          );
      });

    await dispatch(reset("account"));

    dispatch(closeModal());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Your names have been updated");
  } catch (error) {
    console.log("error.message", error.message);
    // dispatch(asyncActionError());
    // toastr.error("Oops", "Something went wrong");
    throw new SubmissionError({
      _error: error.message
    });
  }
};

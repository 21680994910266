import React, { Component, Fragment } from "react";

import styles from "./Layout.module.css";
import Toolbar from "../../features/nav/Toolbar/Toolbar";
import Sidebar from "../../features/nav/Sidebar/Sidebar";
import ModalManager from "../../features/modals/ModalManager";

class Layout extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <div className={styles.Wrapper}>
          <div className={styles.ToolBar}>
            <Toolbar drawerToggleClicked={this.sidedrawerToggleHandler} />
          </div>

          <div className={styles.FlexColumns}>
            <Sidebar />
            <main className={styles.Content}>{this.props.children}</main>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Layout;

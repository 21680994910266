import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { toastr } from "react-redux-toastr";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { combineValidators, isRequired } from "revalidate";

import { allocateInventoryItem } from "../../inventoryActions";
import SelectInput from "../../../../app/common/form/SelectInput";

const validate = combineValidators({
  device: isRequired("device"),
  sim: isRequired("sim")
});

//
const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler"
};

class RegisterDeviceWithOutlet extends Component {
  state = {
    files: [],
    devices: [],
    simCards: [],
    fileName: ""
  };

  onDrop = files => {
    console.log("uploaded files", files);
    this.setState({
      files,
      fileName: files[0].name
    });
  };

  render() {
    const { allocateInventoryItem, handleSubmit, error } = this.props;

    // function submit(values) {
    //   return sleep(1000).then(() => {
    //     // simulate server latency
    //     if (!["john", "paul", "george", "ringo"].includes(values.username)) {
    //       throw new SubmissionError({
    //         username: "User does not exist",
    //         _error: "Login failed!"
    //       });
    //     } else if (values.password !== "redux-form") {
    //       throw new SubmissionError({
    //         password: "Wrong password",
    //         _error: "Login failed!"
    //       });
    //     } else {
    //       window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
    //     }
    //   });
    // }

    const onFormSubmit = values => {
      if (this.state.files[0]) {
        allocateInventoryItem(values, this.state.files[0]);
      } else {
        throw new SubmissionError({
          _error: "Attach a Merchant Agreement to proceed"
        });
      }
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <Field
              name="device"
              options={this.props.devices}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              component={SelectInput}
              placeholder="Select Device..."
              className={"form-control"}
              required="true"
              aria-required="true"
              
            />
          </div>

          <div className={"col-md-6"}>
            <Field
              name="sim"
              options={this.props.simCards}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              component={SelectInput}
              placeholder="Select Number..."
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
        </div>

        <div className={"row clearfix m-t-30"}>
          <div className={"col-lg-6"} style={{ paddingRight: "7px" }}>
            <div className={"card card-default"}>
              <div className={"card-header "} style={{ zIndex: 0 }}>
                <div className={"card-title"}>
                  Upload Signed Merchant Acceptance Form
                </div>
                <div className={"tools"}>
                  <a className={"collapse"} href="javascript:;" />
                  <a
                    className={"config"}
                    data-toggle="modal"
                    href="#grid-config"
                  />
                  <a className={"reload"} href="javascript:;" />
                  <a className={"remove"} href="javascript:;" />
                </div>
              </div>
              {this.state.files.length > 0 ? (
                <div className={"card-body no-scroll no-padding dropzone "}>
                  <p
                    style={{
                      lineHeight: "100px",
                      margin: 0,
                      textAlign: "center"
                    }}
                  >
                    {this.state.files[0].name}
                  </p>
                </div>
              ) : (
                <div className={"card-body no-scroll no-padding dropzone "}>
                  <Dropzone
                    config={componentConfig}
                    onDrop={this.onDrop}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div
                          {...getRootProps()}
                          style={{ textAlign: "center" }}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p style={{ lineHeight: "100px", margin: 0 }}>
                              Drop files here...
                            </p>
                          ) : (
                            <p style={{ lineHeight: "100px", margin: 0 }}>
                              Click to select files to upload.
                            </p>
                          )}
                        </div>
                      );
                    }}
                  </Dropzone>
                </div>
              )}
            </div>
          </div>
        </div>
        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Register Device with Outlet
        </button>
      </form>
    );
  }
}

const query = [
  {
    collection: "inventory",
    where: ["isAllocated", "==", false]
  }
];

const mapStateToProps = state => ({
  devices:
    state.firestore.ordered.inventory &&
    state.firestore.ordered.inventory
      .filter(item => item.type === "Device")
      .map(item => {
        return {
          label: `${item.model} - ${item.macAddress}`,
          value: `${item.serialNumber}`
        };
      }),
  simCards:
    state.firestore.ordered.inventory &&
    state.firestore.ordered.inventory
      .filter(item => item.type === "SIM")
      .map(item => {
        return {
          label: `${item.network} - ${item.cellNumber}`,
          value: `${item.cellNumber}`
        };
      }),
  initialValues: {
    outletId: state.modals.modalProps.outletId,
    account: state.modals.modalProps.account
  },
  loading: state.async.loading
});

const actions = {
  allocateInventoryItem
};

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerDeviceWithOutlet",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  firestoreConnect(query)
)(RegisterDeviceWithOutlet);

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { firestoreConnect } from "react-redux-firebase";

import validate from "./validate";
import { registerMerchantComputer } from "../../../../../merchantActions";
import AsyncTextInput from "../../../../../../../app/common/form/AsyncTextInput";
import SelectInput from "../../../../../../../app/common/form/SelectInput";

import { asyncInventoryComputersValidate as asyncValidate } from "../../../../../../../app/common/validation/asyncValidate.jsx";

const RegisterComputer = ({
  registerMerchantComputer,
  handleSubmit,
  error,
  deviceTypes,
  invalid,
  submitting,
  merchantId
}) => {
  const onFormSubmit = values => {
    console.log("values", values);
    registerMerchantComputer(values);
  };

  let deviceTypesList = {};

  deviceTypesList =
    deviceTypes &&
    deviceTypes.map(deviceType => {
      return {
        label: deviceType.type,
        value: deviceType.type
      };
    });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="Name"
            name="name"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Computer Name"
            required="required"
          />
        </div>
        <div className={"col-md-4"}>
          <Field
            name="deviceType"
            options={deviceTypesList}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            component={SelectInput}
            placeholder="Select Device Type"
            className={"form-control"}
            required="true"
            aria-required="true"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="MAC Address"
            name="macAddress"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="MAC Address"
            required="required"
          />
        </div>
      </div>

      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}

      <button className={"btn btn-primary btn-cons m-t-10"}>
        Register A Computer{" "}
      </button>
    </form>
  );
};

const mapStateToProps = state => ({
  deviceTypes: state.firestore.ordered.macAddressTypes,
  initialValues: {
    outletId: state.modals.modalProps.outletId,
    account: state.modals.modalProps.account,
    outletName: state.modals.modalProps.outletName,
    merchantName: state.modals.modalProps.merchantName
  },
  enableReinitialize: true
});

const actions = {
  registerMerchantComputer
};

const query = [
  {
    collection: "macAddressTypes",
    orderBy: ["type", "desc"]
  }
];

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerComputer",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["macAddress", "serialNumber"],
    validate
  }),
  firestoreConnect(query)
)(RegisterComputer);

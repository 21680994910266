import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.css";
import ReportBody from "./ReportBody";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    const reportsLink = `/reports`;

    let reportBody = <ReportBody />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={reportsLink}>Reports</Link> {" - "} Sales by Category
              </span>

              <br />
              <span className={classes.ByLine}>
                You are in the PrepaidPlus Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Group Sales by Category</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {reportBody}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Index;

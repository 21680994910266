//import liraries
import React from 'react';
import classes from './Logo.module.css';
import companyLogo from '../../assets/logo.png';

// create a component
const Logo = props => (
  <div className={classes.Logo} style={{ height: props.height }}>
    <img src={companyLogo} alt="My Logo" />
  </div>
);

//make this component available to the app
export default Logo;

const validate = values => {
  const errors = {};
  if (!values.seller_id) {
    errors.seller_id = "Required";
  }
  if (!values.client_id) {
    errors.client_id = "Required";
  }
  if (!values.user_id) {
    errors.user_id = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.base_address) {
    errors.base_address = "Required";
  }

  return errors;
};

export default validate;

import React, { Fragment } from "react";
import Select from "react-select";

const SelectInput = ({
  input,
  type,
  onChange,
  placeholder,
  multiple,
  options,
  theme,
  meta: { touched, error }
}) => {
  let attachedClasses = ["form-group"];

  //  error={condition && value}, pass error={condition ? value : undefined}

  // console.log("input", input);

  return (
    <div className={attachedClasses.join(" ")}>
      <Select
        // value={input.value || ""}
        onChange={input.onChange}
        // onBlur={input.onBlur}
        onFocus={input.onFocus}
        placeholder={placeholder}
        options={options}
        isMulti={multiple}
        theme={theme}
      />
      {touched && error && <label className={"error"}>{error}</label>}
    </div>
  );
};

export default SelectInput;

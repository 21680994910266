import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";
import format from "date-fns/format";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Sales extends Component {
  state = {
    summaryView: true,
    viewText: "Itemised View"
  };

  toggleViews = () => {
    console.log("summaryView", this.state.summaryView);
    this.setState({
      summaryView: !this.state.summaryView,
      viewText: this.state.summaryView ? "Summary View" : "Itemised View"
    });
  };

  handleSalesReport = (sales, salesTotal, totalsByProvider, summaryView) => {
    let reportData = [];

    if (summaryView) {
      reportData = totalsByProvider.map(record => {
        return [
          {
            value: record.saleType,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: record.provider,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: formatMoney(record.transactionAmount),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      reportData.push([
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "Total",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
          }
        },
        {
          value: formatMoney(salesTotal),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
          }
        }
      ]);

      let reportDataSet = [
        {
          columns: ["Type", "Provider", "Amount"],
          data: reportData
        }
      ];

      return reportDataSet;
    } else {
      reportData = sales.map(record => {
        return [
          {
            value: format(
              record.transactionDateTime.toDate(),
              "YYYY-MM-DD  HH:mm:ss"
            ),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: record.saleType,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: record.provider,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: formatMoney(record.transactionAmount),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      reportData.push([
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "Total",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
          }
        },
        {
          value: salesTotal,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
          }
        }
      ]);

      let reportDataSet = [
        {
          columns: ["Date", "Type", "Provider", "Amount"],
          data: reportData
        }
      ];

      return reportDataSet;
    }
  };

  render() {
    const { sales, salesTotal, totalsByProvider, requesting } = this.props;
    const { summaryView } = this.state;

    let reportDataSet;
    let reportData = [];

    let group;
    let outletName;
    let reportDate;

    let salesRecords = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (sales.length > 0) {
      reportDataSet = this.handleSalesReport(
        sales,
        salesTotal,
        totalsByProvider,
        summaryView
      );
    }

    sales.length > 0
      ? (salesRecords = this.state.summaryView ? (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Sale Type</th>
                <th scope="col">Provider</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {totalsByProvider.map((sale, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>{sale.saleType}</td>
                    <td>{sale.provider}</td>

                    <td>{formatMoney(sale.transactionAmount)}</td>
                  </tr>
                );
              })}

              <tr className={classes.Totals}>
                <td colSpan="2">
                  <div className={classes.MoneyTotalLabel}> Total:</div>
                </td>
                <td className={classes.MoneyTotal}>
                  {formatMoney(salesTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Sale Type</th>
                <th scope="col">Provider</th>
                <th scope="col">Amount</th>
                {/* <th scope="col">Balance</th>
                  <th scope="col">Type</th> */}
                {/* <th scope="col">Sales Value2</th> */}
              </tr>
            </thead>
            <tbody>
              {sales.map((sale, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>
                      {format(
                        sale.transactionDateTime.toDate(),
                        "YYYY-MM-DD  HH:mm:ss"
                      )}
                    </td>
                    <td>{sale.saleType}</td>
                    <td scope="col">{sale.provider}</td>
                    <td>{sale.transactionAmount}</td>
                    {/* <td>{report.transactionValue2}</td> */}
                  </tr>
                );
              })}
              <tr className={classes.Totals}>
                <td colSpan="3">
                  <div className={classes.MoneyTotalLabel}> Total:</div>
                </td>
                <td className={classes.MoneyTotal}>
                  {formatMoney(salesTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        ))
      : (salesRecords = <h4>Merchant has no sales for selected period.</h4>);

    return (
      <div className={classes.Container}>
        <header className={classes.TabHeader}>
          <div className={classes.PageHeader}>
            <h4>Sales</h4>
          </div>
          <div className={classes.ExportReport}>
            <div className={classes.EmailIconWrapper}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
                focusable="false"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,3.20550012588501) scale(0.75,0.75)  "
                    fill="#999999"
                    d="M0,0.85201934L0.024002075,0.85201934 7.5710008,8.2449855 15.976002,16.49699 24.381009,8.2449855 31.928008,0.85201934 32.000001,0.85201934 32.000001,23.452 0,23.452z M1.902001,0L16.000003,0 30.096999,0 23.049009,5.9290093 16.000003,11.858019 8.9509985,5.9290093z"
                  />
                </g>
              </svg>
            </div>
            <div className={classes.ExportIconWrapper}>
              <ExcelFile
                element={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    focusable="false"
                  >
                    <g>
                      <path
                        id="path1"
                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                        fill="#707070"
                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                      />
                    </g>
                  </svg>
                }
              >
                <ExcelSheet dataSet={reportDataSet} name="Organization" />
              </ExcelFile>
            </div>
          </div>
        </header>

        <h4 onClick={() => this.toggleViews()}>{this.state.viewText}</h4>

        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>{salesRecords}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  // getTransactionsForDashboard
};

const mapStateToProps = state => ({
  sales: state.financials.filter(item => item.transactionType === "Sale"),
  salesTotal: state.financials
    .filter(item => item.transactionType === "Sale")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  totalsByProvider: state.financials
    .filter(item => item.transactionType === "Sale")
    .map(doc => {
      return {
        saleType: doc.saleType,
        provider: doc.provider,
        transactionAmount: doc.transactionAmount
      };
    })
    .reduce(function(arr, doc) {
      let transactionType = doc.saleType + " - " + doc.provider;

      if (!(transactionType in arr)) {
        arr.push((arr[transactionType] = doc));
        return arr;
      }

      arr[transactionType].transactionAmount += parseFloat(
        doc.transactionAmount
      )
        ? parseFloat(doc.transactionAmount)
        : 0;

      return arr;
    }, [])
});

export default connect(
  mapStateToProps,
  actions
)(Sales);

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import TextInput from "../../../../../../../app/common/form/TextInput";
import { asyncOutletNameValidate as asyncValidate } from "../../../../../../../app/common/validation/asyncValidate";
import validate from "./validate";

import { updateOutletField } from "../../../../../merchantActions";

const editName = props => {
  const { name, account, handleSubmit, closePanel, updateOutletField } = props;
  const onFormSubmit = values => {
    let type = "Name";
    updateOutletField(values, props.account, props.outletId, type);
    closePanel();
  };

  console.log("name", name);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="outletName"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Name"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons"}
              type="submit"
              // onClick={handleNextClick}
            >
              <span>Update Outlet Name</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateOutletField
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editOutletFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(editName)
);

// export default connect(
//   mapStateToProps,
//   actions
// )(
//   reduxForm({
//     form: "editOutletFieldForm",
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     validate,
//     asyncValidate,
//     asyncBlurFields: ["outletName"]
//   })(editName)
// );

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import ReactExport from "react-data-export";

import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Index extends Component {
  state = {
    openAdditionalInfoPanel: false,
    selectedCommission: null
  };

  activateMerchantPanelOpenClasses = [classes.RevealMore];

  handleCommissionClick = commission => {
    this.setState({
      openAdditionalInfoPanel: true,
      selectedCommission: null
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null
    });
  };

  exportCommissionReport = (commissionList, commissionTotal, salesTotal) => {
    let reportData = [];

    reportData = commissionList.map(record => {
      return [
        {
          value: record.commissionType,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: record.provider,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: formatMoney(record.sales),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: record.commissionRate,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: formatMoney(record.transactionAmount),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: format(record.transactionDateTime.toDate(), "YYYY-MM-DD"),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        }
      ];
    });

    reportData.push([
      {
        value: "Total",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
        }
      },
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
        }
      },
      {
        value: formatMoney(salesTotal),
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
        }
      },
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } }
        }
      },
      {
        value: formatMoney(commissionTotal),
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
        }
      },
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
        }
      }
    ]);

    let reportDataSet = [
      {
        columns: ["Type", "Provider", "Sales", "Rate", "Amount", "Date"],
        data: reportData
      }
    ];

    return reportDataSet;
  };

  render() {
    let { commissionList, commissionTotal, salesTotal } = this.props;

    let reportDataSet = [];

    if (commissionList.length > 0) {
      reportDataSet = this.exportCommissionReport(
        commissionList,
        commissionTotal,
        salesTotal
      );
    }

    const commission =
      commissionList &&
      Object.values(commissionList).map((commission, index) => {
        return (
          <tr className={classes.MerchantRow} key={index}>
            <td>{commission.commissionType}</td>
            <td>{commission.provider}</td>
            <td>{formatMoney(commission.sales)}</td>
            <td>{commission.commissionRate}</td>
            <td>{formatMoney(commission.transactionAmount)}</td>
            <td>
              {format(
                commission.transactionDateTime &&
                  commission.transactionDateTime.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.Container}>
          <header className={classes.TabHeader}>
            <div className={classes.PageHeader}>
              <h4>Commission</h4>
            </div>
            <div className={classes.ExportReport}>
              <div className={classes.EmailIconWrapper}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,3.20550012588501) scale(0.75,0.75)  "
                      fill="#999999"
                      d="M0,0.85201934L0.024002075,0.85201934 7.5710008,8.2449855 15.976002,16.49699 24.381009,8.2449855 31.928008,0.85201934 32.000001,0.85201934 32.000001,23.452 0,23.452z M1.902001,0L16.000003,0 30.096999,0 23.049009,5.9290093 16.000003,11.858019 8.9509985,5.9290093z"
                    />
                  </g>
                </svg>
              </div>
              <div className={classes.ExportIconWrapper}>
                <ExcelFile
                  element={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      focusable="false"
                    >
                      <g>
                        <path
                          id="path1"
                          transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                          fill="#707070"
                          d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                        />
                      </g>
                    </svg>
                  }
                >
                  <ExcelSheet dataSet={reportDataSet} name="Organization" />
                </ExcelFile>
              </div>
            </div>
          </header>

          <div className={classes.MerchantTableWrapper}>
            {commission && commission.length === 0 ? (
              <h3>
                {" "}
                There are no Commission transactions for selected period.
              </h3>
            ) : (
              <Fragment>
                <div className={classes.TableWrapper}>
                  <table className={classes.MerchantTable}>
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Provider</th>
                        <th scope="col">Sales</th>
                        <th scope="col">Rate</th>
                        <th className={"hide-s"} scope="col">
                          Amount
                        </th>
                        <th className={"hide-s"} scope="col">
                          Date Recorded
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {commission}
                      <tr className={classes.Totals}>
                        <td colSpan="2">
                          <div className={classes.MoneyTotalLabel}> Totals</div>
                        </td>
                        <td className={classes.MoneyTotal}>
                          {formatMoney(salesTotal)}
                        </td>
                        <td />
                        <td className={classes.MoneyTotal}>
                          {formatMoney(commissionTotal)}
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  commissionList: state.financials.filter(
    item => item.transactionType === "Commission"
  ),
  commissionTotal: state.financials
    .filter(item => item.transactionType === "Commission")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  salesTotal: state.financials
    .filter(item => item.transactionType === "Commission")
    .reduce(function(total, doc) {
      total += parseFloat(doc.sales) ? parseFloat(doc.sales) : 0;

      return total;
    }, 0),
  totalsByProvider: state.financials
    .filter(item => item.transactionType === "Commission")
    .map(doc => {
      return {
        saleType: doc.saleType,
        provider: doc.provider,
        transactionAmount: doc.transactionAmount
      };
    })
    .reduce(function(arr, doc) {
      let transactionType = doc.saleType + " - " + doc.provider;

      if (!(transactionType in arr)) {
        arr.push((arr[transactionType] = doc));
        return arr;
      }

      arr[transactionType].transactionAmount += parseFloat(
        doc.transactionAmount
      )
        ? parseFloat(doc.transactionAmount)
        : 0;

      return arr;
    }, [])
});

export default connect(mapStateToProps)(Index);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import TextInput from "../../../../app/common/form/TextInput";

import { createSeller } from "../../settingsActions";

import validate from "./validate";

class CreateSellerForm extends Component {
  state = {};

  render() {
    const { handleSubmit, createSeller, error } = this.props;

    const onFormSubmit = values => {
      console.log("values", values);
      createSeller({
        values
      });
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="Seller ID"
              name="seller_id"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Seller ID"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              label="Client ID"
              name="client_id"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Seller ID"
              required="required"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="User Id"
              name="user_id"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="User Id"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              label="Password"
              name="password"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Password"
              required="required"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-12"}>
            <Field
              label="Base Address"
              name="base_address"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Base Address"
              required="required"
            />
          </div>
        </div>
        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Create Seller
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  enableReinitialize: true
});

const actions = {
  createSeller
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "createSellerForm", validate })(CreateSellerForm));

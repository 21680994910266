import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import asyncValidate from "../asyncValidate";
import validate from "../Validate";
import TextInput from "../../../../app/common/form/TextInput";
import AsyncTextInput from "../../../../app/common/form/TextInput";
import SelectInput from "../../../../app/common/form/SelectInput";

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler"
};

class MerchantDetails extends Component {
  state = {
    files: [],
    fileName: ""
  };

  render() {
    const { handleSubmit } = this.props;

    let sellers = {};

    sellers =
      this.props.sellerId &&
      this.props.sellerId.map(seller => {
        return {
          label: seller.sellerId,
          value: seller.sellerId
        };
      });

    return (
      <form onSubmit={handleSubmit}>
        <p>
          <strong>Merchant Details</strong>
        </p>
        <div>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <Field
                name="merchantName"
                component={AsyncTextInput}
                type="text"
                className={"form-control"}
                placeholder="Name"
                required="required"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Merchant Group"
                name="merchantGroup"
                component={TextInput}
                type="text"
                className={"form-control"}
                placeholder="Group"
                required="required"
              />
            </div>

            <div className={"col-sm-4"}>
              <Field
                name="merchantSellerId"
                options={sellers}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0"
                  }
                })}
                component={SelectInput}
                placeholder="Select Seller Id..."
                className={"form-control"}
                required="true"
              />
            </div>
          </div>
          <br />
          <p>
            <strong> Upload Signed Merchant Registration Form</strong>
          </p>
          <div className={"row clearfix"}>
            <div className={"col-lg-6"} style={{ paddingRight: "7px" }}>
              <div className={"card card-default"}>
                {this.props.files && this.props.files.length > 0 ? (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <p
                      style={{
                        lineHeight: "50px",
                        margin: 0,
                        textAlign: "center"
                      }}
                    >
                      {this.props.files[0].name}
                    </p>
                  </div>
                ) : (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <Dropzone
                      config={componentConfig}
                      onDrop={this.props.onDrop}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            style={{ textAlign: "center" }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Drop files here...
                              </p>
                            ) : (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Click to select files to upload.
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <p>
          <strong>Contact and Address</strong>
        </p>
        <div>
          <div className={"row clearfix"}>
            <div className={"col-sm-6"}>
              <Field
                // label="City"
                name="merchantCity"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="City"
                required="required"
                autoComplete="off"
              />
            </div>
            <div className={"col-sm-6"}>
              <Field
                // label="Suburb"
                name="merchantSuburb"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="Suburb"
                required="required"
              />
            </div>
          </div>

          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <Field
                // label="Contact Name"
                name="merchant_contact_name"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="Contact Person"
                required="required"
              />
            </div>

            <div className={"col-sm-4"}>
              <Field
                // label="Contact Email"
                name="merchant_contact_email"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="Contact Email"
                required="required"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Telephone Number"
                name="merchant_contact_telephone"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="Telephone Number"
                required="required"
              />
            </div>
          </div>
        </div>
        <br />
        <div
          className={
            "p-t-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix"
          }
        >
          <ul className={"pager wizard no-style"}>
            <li className="next">
              <button
                className={"btn btn-primary btn-cons pull-right"}
                type="submit"
                // onClick={handleNextClick}
              >
                <span>Next</span>
              </button>
            </li>
          </ul>
        </div>
      </form>
    );
  }
}

const query = [
  {
    collection: "sellerId",
    orderBy: ["sellerId", "desc"]
  }
];

const mapState = state => ({
  sellerId: state.firestore.ordered.sellerId
});

export default compose(
  connect(mapState),
  reduxForm({
    form: "merchantForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["merchantName"],
    validate
  }),
  firestoreConnect(query)
)(MerchantDetails);

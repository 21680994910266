const validate = values => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = "Required";
  }

  if (!values.merchantGroup) {
    errors.merchantGroup = "Required";
  }

  if (!values.merchantSellerId) {
    errors.merchantSellerId = "Required";
  }

  if (!values.merchantCity) {
    errors.merchantCity = "Required";
  }
  if (!values.merchantSuburb) {
    errors.merchantSuburb = "Required";
  }
  if (!values.merchant_contact_name) {
    errors.merchant_contact_name = "Required";
  }
  if (!values.merchant_contact_email) {
    errors.merchant_contact_email = "Required";
  }
  if (!values.merchant_contact_telephone) {
    errors.merchant_contact_telephone = "Required";
  }

  return errors;
};

export default validate;

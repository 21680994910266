import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import MerchantAgreement from "../merchants/Merchant/MerchantProfile/Documents/MerchantAgreement";

const actions = { closeModal };

const merchantAgreementModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="New Merchant Agreement" onClose={closeModal}>
      <MerchantAgreement />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(merchantAgreementModal);

import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import format from "date-fns/format";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";

import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import { reportByDate } from "../../reportActions";
import SelectInput from "../../../../app/common/form/SelectInput";
import TextInput from "../../../../app/common/form/TextInput";
import classes from "./ReportBody.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

const $ = window.$;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId")
});

class ReportBody extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {}
  };

  async componentDidMount() {
    $(findDOMNode(this.reportStartDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "0d"
    });
    $(findDOMNode(this.reportEndDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "0d"
    });
    var _this = this;
    $(findDOMNode(this.reportStartDate)).on("changeDate", function(e) {
      _this.props.change("reportStartDate", e.date);
    });
    $(findDOMNode(this.reportEndDate)).on("changeDate", function(e) {
      _this.props.change("reportEndDate", e.date);
    });

    await this.props.reportByDate({
      reportStartDate: new Date(this.props.initialValues.reportStartDate),
      reportEndDate: new Date(this.props.initialValues.reportEndDate),
      outletId: this.props.initialValues.outletId
    });

    this.setState({ loadingInitial: false });
  }

  componentWillUnmount() {
    $(findDOMNode(this.reportStartDate)).datepicker("destroy");
    $(findDOMNode(this.reportEndDate)).datepicker("destroy");
  }

  render() {
    const { handleSubmit, reportByDate, transactions, requesting } = this.props;
    const loading = requesting[`merchants`];

    let outlets = Object.values(this.props.outlets).map(outlet => {
      return {
        label: `${outlet.id} - ${outlet.name}`,
        value: outlet.id
      };
    });

    const onFormSubmit = async values => {
      console.log("values", values);

      const outletId = values.outletId.value;
      const reportStartDate = new Date(values.reportStartDate);
      const reportEndDate = new Date(values.reportEndDate);

      this.setState({ loadingInitial: true });
      await reportByDate({
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        outletId: outletId
      });
      this.setState({ loadingInitial: false });
    };

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let reportDataSet;

    // let itemisedReport = "Spinner.....";
    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    let group;
    let outletName;
    let reportDate;

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (!this.state.loadingInitial) {
      let salesTotal = transactions.reduce(function(accumulator, transaction) {
        return accumulator + transaction.transactionAmount;
      }, 0);

      let firstrow =
        transactions.length > 0
          ? transactions[0]
          : { group: "None", outletName: "None" };

      group = firstrow.group;
      outletName = firstrow.outletName;

      // let salesTotal = 0;

      transactions.length > 0
        ? (itemisedReport = (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date of Sale</th>
                  <th scope="col">Sale Value</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((report, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>{report.transactionDateTime}</td>
                      <td>{moneyFormat(report.transactionAmount)}</td>
                    </tr>
                  );
                })}
                <tr className={classes.Totals}>
                  <td>
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {moneyFormat(salesTotal)}
                  </td>
                  {/* <td className={classes.MoneyTotal}>{salesTotal2}</td> */}
                </tr>
              </tbody>
            </table>
          ))
        : (itemisedReport = (
            <h4>Selected Outlet has no transactions for selected period.</h4>
          ));

      let reportData = transactions.map(report => {
        return [
          {
            value: report.transactionDateTime,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: moneyFormat(report.transactionAmount),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      reportData.push([
        {
          value: "Total",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true }
          }
        },
        {
          value: moneyFormat(salesTotal),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true }
          }
        }
      ]);

      // 920202;
      //4e0000

      // Itemised Sales Report
      //

      reportDate = `${this.props.initialValues.reportStartDate} to ${
        this.props.initialValues.reportStartDate
      }`;
      let reportStartDate = `Start Date: ${
        this.props.initialValues.reportStartDate
      }`;
      let reportEndDate = `End Date: ${this.props.initialValues.reportEndDate}`;
      // Period: 01 Nov 2018 To 30 Nov 2018

      // {value: "Bold", style: {font: {bold: true}}},

      reportDataSet = [
        // {
        //   columns: [
        //     { title: "Headings", width: { wpx: 80 } }, //pixels width
        //     { title: "Text Style", width: { wch: 40 } }, //char width
        //     { title: "Colors", width: { wpx: 90 } }
        //   ],
        //   data: [
        //     [
        //       { value: "H1", style: { font: { sz: "24", bold: true } } },
        //       { value: "Bold", style: { font: { bold: true } } },
        //       {
        //         value: "Red",
        //         style: {
        //           fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
        //         }
        //       }
        //     ]
        //   ]
        // },
        {
          // ySteps: 1,
          columns: [
            {
              title: "",
              width: { wpx: 890 },
              style: {
                font: { sz: "18", bold: true }
              }
            },
            { title: "", width: { wpx: 10 } }
          ],
          data: [[group, outletName]]
        },
        {
          // ySteps: 1,

          columns: [
            { title: "", width: { wpx: 10 } },
            { title: "", width: { wpx: 10 } }
          ],
          data: [
            [
              {
                value: reportStartDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              },
              {
                value: reportEndDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              }
            ]
          ]
        },

        {
          // xSteps: 1, // Will start putting cell with 1 empty cell on left most
          // ySteps: 1,
          columns: [
            {
              title: "Sale Type",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Sale Value",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            }
          ],
          data: reportData
        }
      ];
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <Field
                      name="outletId"
                      options={outlets}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "neutral0"
                        }
                      })}
                      component={SelectInput}
                      placeholder="Select Outlet..."
                      className={"form-control"}
                      required="true"
                      aria-required="true"
                    />
                  </div>

                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <div className={"col-md-4 offset-1"}>
                        <div className={"input-group date col-md-12 p-l-0"}>
                          <Field
                            type="text"
                            className={"form-control"}
                            component="input"
                            placeholder="Pick Deposit date"
                            name="reportStartDate"
                            id="reportStartDate"
                            ref={input => {
                              this.reportStartDate = input;
                            }}
                          />
                          <div className={"input-group-append"}>
                            <span className={"input-group-text"}>
                              <i className={"fa fa-calendar"} />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={"col-md-4"}>
                        <div className={"input-group date col-md-12 p-l-0"}>
                          <Field
                            type="text"
                            className={"form-control"}
                            component="input"
                            placeholder="Pick Deposit date"
                            name="reportEndDate"
                            id="reportEndDate"
                            ref={input => {
                              this.reportEndDate = input;
                            }}
                          />
                          <div className={"input-group-append"}>
                            <span className={"input-group-text"}>
                              <i className={"fa fa-calendar"} />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={"col-md-3"}>
                        <button className={classes.FilterReportBtn}>
                          Filter Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
              <div className={classes.ToolsPrintIconWrapper}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,0.00300121307373047) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M8.0009928,24.949988L24.000992,24.949988 24.000992,25.949988 8.0009928,25.949988z M8.0009928,22.034003L24.000992,22.034003 24.000992,23.034003 8.0009928,23.034003z M8.0839996,18.991996L24.084,18.991996 24.084,19.991996 8.0839996,19.991996z M6.0009928,17.006983L6.0009928,29.991994 26.000992,29.991994 26.000992,23.011647 26,22.991996 26,17.006983z M2,6.9919958L2,21.991996 4,21.991996 4,15.991996C4,15.439017,4.447998,14.991996,5,14.991996L27,14.991996C27.517502,14.991996,27.943594,15.384886,27.994835,15.889638L27.995474,15.902311 27.995827,15.90462C27.999241,15.938271,28.000992,15.97242,28.000992,16.006982L28.000992,21.991996 30,21.991996 30,6.9919958z M6.0009928,1.9999976L6.0009928,4.983994 26.000992,4.983994 26.000992,1.9999976z M5.0009928,0L27.000992,0C27.552992,0,28.000992,0.44699955,28.000992,0.99999905L28.000992,4.9919958 31,4.9919958C31.552002,4.9919958,32,5.4390173,32,5.9919958L32,22.991996C32,23.544974,31.552002,23.991996,31,23.991996L28.000992,23.991996 28.000992,30.991996C28.000992,31.544995,27.552992,31.991996,27.000992,31.991996L5.0009928,31.991996C4.4479923,31.991996,4.0009928,31.544995,4.0009928,30.991996L4.0009928,23.991996 1,23.991996C0.44799805,23.991996,0,23.544974,0,22.991996L0,5.9919958C0,5.4390173,0.44799805,4.9919958,1,4.9919958L4.0009928,4.9919958 4.0009928,0.99999905C4.0009928,0.44699955,4.4479923,0,5.0009928,0z"
                    />
                  </g>
                </svg>
              </div>
              <div className={classes.ToolsDataExportIconWrapper}>
                <ExcelFile
                  element={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path
                          id="path1"
                          transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                          fill="#707070"
                          d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                        />
                      </g>
                    </svg>
                  }
                >
                  <ExcelSheet dataSet={reportDataSet} name="Itemised Report" />
                </ExcelFile>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>{itemisedReport}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  reportByDate
};

const mapStateToProps = (state, props) => ({
  transactions: state.reports,
  requesting: state.firestore.status.requesting,
  initialValues: {
    outletId: Object.values(props.outlets)[0].id,
    reportStartDate: format(new Date(), "YYYY-MM-DD"),
    reportEndDate: format(new Date(), "YYYY-MM-DD")
  },
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterReportForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(ReportBody)
);

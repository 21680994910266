import firebase from "../../../app/config/firebase";

const asyncValidate = async name => {
  const firestore = firebase.firestore();

  return await firestore
    .collection("merchants")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().name === name.merchantName) {
          throw { merchantName: "Merchant name is taken" };
        }
      })
    );
};

export default asyncValidate;

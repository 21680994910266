import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import TextInput from "../../../../../app/common/form/TextInput";
import validate from "./validate";

import { updateMerchantField } from "../../../merchantActions";

const editContact = props => {
  const {
    name,
    account,
    handleSubmit,
    closePanel,
    updateMerchantField
  } = props;
  const onFormSubmit = values => {
    let type = "Contact";
    updateMerchantField(values, account, type);
    closePanel();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="merchant_contact_name"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Full Name"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="merchant_contact_email"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Email"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="merchant_contact_telephone"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Contact Number"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons"}
              type="submit"
              // onClick={handleNextClick}
            >
              <span>Update Contact Details</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateMerchantField
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editMerchantFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(editContact)
);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Tabs from "../../app/UI/Tabs/Tabs";
import { openModal } from "../modals/modalActions";

import classes from "./index.module.css";

class Index extends Component {
  render() {
    const { openModal } = this.props;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Settings</span>

              {/* <span className={classes.Header}><Link to={merchantsLink}>Merchants</Link>  -  <Link to={selectedMerchantLink}>{merchant.name}</Link>  -  Reports</span> */}
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Settings panel
              </span>
            </div>
          </div>

          {/* <MerchantFinancials transactions={merchantTransactions}/> */}

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Settings</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          <Tabs>
                            <div label="Commission Rates">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>Commission Rates</h4>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    onClick={() =>
                                      openModal("CreateCommissionModal")
                                    }
                                  >
                                    Add Commission Rate
                                  </button>
                                </div>
                              </header>
                            </div>
                            <div label="Sellers">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>Registered Sellers</h4>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    onClick={() =>
                                      openModal("CreateSellerModal")
                                    }
                                  >
                                    Create New Seller
                                  </button>
                                </div>
                              </header>
                              {/* <Users account={merchant.id} /> */}
                            </div>
                            <div label="Outlets">
                              <header className={classes.TabHeader}>
                                {/* <div className={classes.PageHeader}>
                        <h4>Outlet(ss)</h4>
                      </div> */}

                                <div className={classes.PageHeader}>
                                  <div className={classes.PageTitle}>
                                    <h4> Outlets</h4>
                                  </div>
                                  {/* <div className={classes.PageFilter}>
   
                          <div className={attachedClasses.join(" ")}>
                            <label>Outlet Name</label>
                            <div className={"controls"}>
                              <input
                                type="text"
                                className={classes.FormControl}
                                placeholder="Search by Outlet Name"
                                value={this.state.outletSearchText}
                                onChange={this.filterByOutletName}
                              />
                            </div>
                          </div>
                        </div> */}
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    // onClick={() =>
                                    //   openModal("CreateOutletModal", merchant.id)
                                    // }
                                  >
                                    Add New Outlet
                                  </button>
                                </div>
                              </header>
                              {/* {outletSearchText.length > 0 &&
                    outletsFiltered.length === 0 ? (
                      <div ref={this.handleContextRef}>
                        <div style={{ paddingBottom: "30px" }}>
                          <h4>
                            {" "}
                            No outlets matched for query "{
                              outletSearchText
                            }"{" "}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <Outlets
                        outlets={
                          outletsFiltered.length === 0
                            ? merchant.outlets
                            : outletsFiltered
                        }
                        account={merchant.account}
                      />
                    )} */}
                            </div>

                            <div label="Documents">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>Documents</h4>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    // onClick={() =>
                                    //   openModal("MerchantAgreementModal", merchant.id)
                                    // }
                                  >
                                    Upload New Agreement
                                  </button>
                                </div>
                              </header>
                              {/* <Documents
                      merchant={merchant}
                      filterByOutletName={this.filterByOutletName}
                    /> */}
                            </div>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const actions = {
  openModal
};
export default connect(
  mapStateToProps,
  actions
)(Index);

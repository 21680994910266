import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM,
  SELECT_INVENTORY_ITEM,
  FETCH_INVENTORY_ITEMS,
  FETCH_INVENTORY_UNALLOCATED_ITEMS
} from "./inventoryConstants";

const initialState = [];

export const createInventoryItem = (state, payload) => {
  return [...state, Object.assign({}, payload.inventoryItem)];
};

export const updateInventoryItem = (state, payload) => {
  return [
    ...state.filter(inventoryItem => inventoryItem.id !== payload.inventoryItem.id),
    Object.assign({}, payload.inventoryItem)
  ];
};

export const deleteInventoryItem = (state, payload) => {
  return [...state.filter(inventoryItem => inventoryItem.id !== payload.inventoryItemId)];
};

export const selectInventoryItem = (selectedInventoryItem = null, action) => {
  if (action.type === "SELECT_INVENTORY_ITEM") {
    return action.payload;
  }
  return selectedInventoryItem;
};

export const fetchInventoryItems = (state, payload) => {
  return payload.inventoryItems;
};

// export const fetchUnallocatedItems = (state, payload) => {
//   // console.log('payload',payload)
//   return payload;
// };

export default createReducer(initialState, {
  [CREATE_INVENTORY_ITEM]: createInventoryItem,
  [UPDATE_INVENTORY_ITEM]: updateInventoryItem,
  [DELETE_INVENTORY_ITEM]: deleteInventoryItem,
  [SELECT_INVENTORY_ITEM]: selectInventoryItem,
  [FETCH_INVENTORY_ITEMS]:fetchInventoryItems

  // [FETCH_INVENTORY_UNALLOCATED_ITEMS]:fetchUnallocatedItems
});

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import { compose } from "redux";

import classes from "./ItemisedReport.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ItemisedReport from "./Itemised";

class index extends Component {
  state = {
    initialLoading: true
  };
  async componentDidMount() {
    const { firestore, match } = this.props;

    let merchant = await firestore.get(`merchants/${match.params.account}`);

    if (!merchant.exists) {
      toastr.error("Not found", "This is not the merchant you are looking for");
      this.props.history.push("/merchants");
    }

    firestore.setListener(`merchants/${match.params.account}`);

    this.setState({
      initialLoading: false
    });
  }

  render() {
    const { merchant, match } = this.props;
    const merchantsLink = `/merchants`;
    const selectedMerchantLink = `/merchants/${merchant.account}`;
    const itemisedReportLink = `/merchants/${merchant.account}/reports`;

    const account = match.params.account;

    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (this.state.initialLoading) return <LoadingComponent inverted={true} />;

    if (!this.state.initialLoading) {
      itemisedReport = <ItemisedReport account={account} />;
    }

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={merchantsLink}>Merchants</Link>
                {" - "}
                <Link to={selectedMerchantLink}>{merchant.name}</Link>
                {" - "}
                <Link to={itemisedReportLink}>Reports</Link> {" - "} Itemised
              </span>
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Itemised Report</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {itemisedReport}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// const mapStateToProps = (state, ownProps) => {

//   return {
//     account
//   };
// };

// const mapStateToProps = (state, ownProps) => {
//   console.log("ownProps", ownProps);
//   const account = ownProps.match.params.account;
//   let merchant = {};

//   if (
//     state.firestore.ordered.merchants &&
//     state.firestore.ordered.merchants.length > 0
//   ) {
//     merchant = state.firestore.ordered.merchants.filter(
//       merchant => merchant.account === account
//     )[0];
//   }

//   return {
//     requesting: state.firestore.status.requesting,
//     loading: state.async.loading,
//     auth: state.firebase.auth,
//     merchant,
//     account
//   };
// };

// export default connect(mapStateToProps)(index);

const mapState = (state, ownProps) => {
  const account = ownProps.match.params.account;
  let merchant = {};

  if (
    state.firestore.ordered.merchants &&
    state.firestore.ordered.merchants.length > 0
  ) {
    merchant = state.firestore.ordered.merchants.filter(
      merchant => merchant.account === account
    )[0];
  }

  return {
    requesting: state.firestore.status.requesting,
    loading: state.async.loading,
    auth: state.firebase.auth,
    merchant
  };
};

export default compose(
  withFirestore,
  connect(mapState)
)(index);

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { updateMerchantField } from "../../../merchantActions";

const editStatus = props => {
  const {
    name,
    account,
    handleSubmit,
    closePanel,
    updateMerchantField,
    status
  } = props;
  const onFormSubmit = values => {
    let type = "Status";
    values.status = props.status;
    updateMerchantField(values, account, type);
    closePanel();
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <h5>
        Status:{" "}
        <span className={"semi-bold"}>{status ? "Enabled" : "Disabled"}</span>
      </h5>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons"}
              type="submit"
              // onClick={handleNextClick}
            >
              <span>{!status ? "Enable" : "Disabled"} Merchant</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateMerchantField
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editMerchantFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(editStatus)
);

import React, { Fragment } from "react";
import format from "date-fns/format";

import classes from "./SIMCard.module.css";

const simCard = props => {
  let { inventoryItemList } = props;

  let inventoryItems = Object.values(inventoryItemList).map(
    (inventoryItem, index) => {
      return (
        <tr className={classes.MerchantRow} key={index}>
          <td>{inventoryItem.network}</td>
          <td>{inventoryItem.cellNumber}</td>
          <td>{inventoryItem.simNumber}</td>
          <td>{inventoryItem.pinCode}</td>
          <td>{inventoryItem.isAllocated ? "Yes" : "No"}</td>
          <td>
            {format(
              inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(),
              "YYYY-MM-DD"
            )}
          </td>
        </tr>
      );
    }
  );

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr>
            <th scope="col">Network</th>
            <th scope="col">Cell Number</th>
            <th scope="col">SIM Number</th>
            <th className={"hide-s"} scope="col">
              PIN Code
            </th>
            <th className={"hide-s"} scope="col">
              Is Allocated?
            </th>
            <th className={"hide-s"} scope="col">
              Date Added
            </th>
          </tr>
        </thead>
        <tbody>
          {console.log("inventoryItems.length", inventoryItems.length == 0)}

          {inventoryItems.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>No SIMCards or Query yielded no results.</h3>
            </div>
          ) : (
            inventoryItems
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default simCard;

import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import RegisterSIMCard from "../inventory/SIMCard/RegisterSIMCard"

const actions = { closeModal };

const registerSIMCardModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Register New SIM Card" onClose={closeModal}>
      <RegisterSIMCard />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(registerSIMCardModal);

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import TextInput from "../../../../../app/common/form/TextInput";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthGreaterThan
} from "revalidate";
import classes from "./ChangeUserPassword.module.css";
import { changePortalUserName } from "../../../merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    hasLengthGreaterThan(5)({
      message: "Must be 6 characters or longer"
    })
  )()
});

const changeUserName = props => {
  const { user, changePortalUserName, handleSubmit, closePanel } = props;

  const onFormSubmit = values => {
    changePortalUserName(values, user.id);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              User:
              <span className={"semi-bold"}>{user.displayName}</span>
            </h4>
            <br />
            <div className={"row clearfix"}>
              <div className={"col-sm-6"}>
                <Field
                  name="displayName"
                  component={TextInput}
                  type="text"
                  className={"form-control"}
                  placeholder="Display Name"
                  pattern="[0-9]{4}"
                  required
                />
              </div>
            </div>
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Update User Names</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  changePortalUserName
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "changeUserNameForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(changeUserName)
);

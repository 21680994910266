// import React from "react";
// import { connect } from "react-redux";
// import { reduxForm, Field } from "redux-form";

// import TextInput from "../../../../../app/common/form/TextInput";
// import {
//   createValidator,
//   combineValidators,
//   composeValidators,
//   isRequired,
//   isNumeric,
//   hasLengthBetween
// } from "revalidate";
// import classes from "./EnableUser.module.css";
// import { enablePortalUser } from "../../../merchantActions";

// const customIsRequired = isRequired({ message: "Required" });

// const validate = combineValidators({
//   password: composeValidators(
//     customIsRequired,
//     isNumeric({
//       message: "Must be a number"
//     }),
//     hasLengthBetween(4, 4)({
//       message: "Must be 4 numbers"
//     })
//   )()
// });

// const enableOutletUser = props => {
//   const { user, enablePortalUser, handleSubmit, closePanel } = props;

//   const onFormSubmit = values => {
//     console.log("enablePortalUser", user.id);
//     enablePortalUser(user.id);
//     closePanel();
//   };

//   return (
//     <div label="Deposits">
//       <header className={classes.TabHeader}>
//         <div className={classes.PageHeader}>
//           <form onSubmit={handleSubmit(onFormSubmit)}>
//             <h4>
//               User:
//               <span className={"semi-bold"}>{user.displayName}</span>
//             </h4>

//             <br />

//             <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
//               <ul className={"pager wizard no-style"}>
//                 <li className="next">
//                   <button
//                     className={"btn btn-primary btn-cons"}
//                     type="submit"
//                     // onClick={handleNextClick}
//                   >
//                     <span>Enable User</span>
//                   </button>
//                 </li>
//               </ul>
//             </div>
//           </form>
//         </div>
//       </header>
//     </div>
//   );
// };

// const actions = {
//   enablePortalUser
// };

// export default connect(
//   null,
//   actions
// )(
//   reduxForm({
//     form: "enableUserForm",
//     destroyOnUnmount: true,
//     forceUnregisterOnUnmount: true,
//     validate
//   })(enableOutletUser)
// );

import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import classes from "./EnableUser.module.css";
import { enablePortalUser } from "../../../merchantActions";

const enableUser = props => {
  const { user, enablePortalUser, handleSubmit, closePanel } = props;

  const onFormSubmit = values => {
    enablePortalUser(user.id);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              Selected User:
              <span className={"semi-bold"}>{user.displayName}</span>
            </h4>
            <br />
            {/* <br /> */}
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Enable User</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  enablePortalUser
};

export default connect(
  null,
  actions
)(
  reduxForm({
    form: "enableUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(enableUser)
);

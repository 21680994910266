import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { combineValidators, composeValidators, isRequired } from "revalidate";

import LoadingComponent from "../../../../../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
import EnableOutletUser from "./EnableOutletUser";
import DisableOutletUser from "./DisableOutletUser";
import UpdateOutletUserPassword from "./UpdateOutletUserPassword";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(customIsRequired)(),
  confirmPassword: composeValidators(customIsRequired)()
});

class Index extends Component {
  state = {
    activateUserPanelOpen: false,
    selectedUser: null,
    selectedRow: -1
  };

  activateUserPanelOpenClasses = [classes.RevealMore];
  MerchantTableClasses = [classes.MerchantTable];

  handleUserClick = (user, index) => {
    this.setState({
      activateUserPanelOpen: true,
      selectedUser: user,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    console.log(this.state.activateUserPanelOpen);
    this.setState({
      activateUserPanelOpen: false,
      selectedUser: null,
      selectedRow: -1
    });
  };

  // handleUserClick = userId => {
  //   console.log(this.state.activateUserPanelOpen);
  //   this.setState({
  //     activateUserPanelOpen: !this.state.activateUserPanelOpen,
  //     selectedUser: userId
  //   });
  // };

  render() {
    let { handleSubmit, outletUsers, requesting, outletId } = this.props;
    const loading = requesting[`outlet_users?where=outletId:==:${outletId}`];

    let { activateUserPanelOpen, selectedUser, selectedRow } = this.state;

    !selectedRow
      ? (this.transactionRowClasses = [classes.MerchantRow])
      : (this.transactionRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction
        ]);

    console.log("loading", loading);
    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    let users =
      outletUsers &&
      outletUsers.map((user, index) => {
        return (
          <tr
            key={user.userId}
            onClick={() => this.handleUserClick(user, index)}
            className={
              this.state.selectedRow === index
                ? classes.SelectedTransaction
                : classes.MerchantRow
            }
          >
            <td>{user.userId}</td>
            <td>{user.role}</td>
            <td>{user.isUserEnabled ? "Yes" : "No"}</td>
            <td>
              {format(
                user.dateCreated && user.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{user.createdBy}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Is Enabled</th>
                  <th className={"hide-s"} scope="col">
                    Date Created
                  </th>
                  <th className={"hide-s"} scope="col">
                    Created By
                  </th>
                </tr>
              </thead>
              <tbody>{users}</tbody>
            </table>
            {activateUserPanelOpen ? (
              <div className={classes.EnableUser}>
                <div className={classes.EnableUser}>
                  <div className={"card card-transparent"}>
                    <div
                      className={"card-header "}
                      style={{
                        padding: "0",
                        minHeight: "4.1rem",
                        padding: "1rem",
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500"
                      }}
                    >
                      <div
                        className={"card-title semi-bold"}
                        style={{
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          textTransform: "none",
                          marginLeft: "0.7rem"
                        }}
                      >
                        Enable User
                      </div>
                      <div className={"card-controls"}>
                        <ul>
                          <li>
                            <a
                              data-toggle="close"
                              className={"card-close"}
                              href="#"
                              onClick={this.handleCloseClick}
                            >
                              <i className={"card-icon card-icon-close"} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={"card-body"}>
                      <div className={"panel"}>
                        {/* {!selectedUser.isUserEnabled
                              ? () =>
                                  openSideDrawer(
                                    "EnableUserSideDrawer",
                                    selectedUser
                                  )
                              : () =>
                                  openSideDrawer(
                                    "DisableUserSideDrawer",
                                    selectedUser
                                  )} */}
                        {!selectedUser.isUserEnabled ? (
                          <Fragment>
                            <ul
                              className={
                                "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                              }
                            >
                              <li className={"nav-item active"}>
                                <a
                                  data-toggle="tab"
                                  href="#activation"
                                  className={"active show"}
                                >
                                  Enable User
                                </a>
                              </li>
                            </ul>
                            <div className={"tab-content"}>
                              <div
                                className={"tab-pane active"}
                                id="activation"
                              >
                                <div className={"row column-seperation"}>
                                  <div className={"col-md-12"}>
                                    <EnableOutletUser
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <ul
                              className={
                                "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                              }
                            >
                              <li className={"nav-item active"}>
                                <a
                                  data-toggle="tab"
                                  href="#activation"
                                  className={"active show"}
                                >
                                  Disable User
                                </a>
                              </li>
                              <li className={"nav-item active"}>
                                <a data-toggle="tab" href="#password">
                                  Change Password
                                </a>
                              </li>
                            </ul>
                            <div className={"tab-content"}>
                              <div
                                className={"tab-pane active"}
                                id="activation"
                              >
                                <div className={"row column-seperation"}>
                                  <div className={"col-md-12"}>
                                    <DisableOutletUser
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={"tab-pane"} id="password">
                                <div className={"row column-seperation"}>
                                  <div className={"col-md-12"}>
                                    <div label="Deposits">
                                      <UpdateOutletUserPassword
                                        user={selectedUser}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

const query = props => {
  console.log("props", props);
  return [
    {
      collection: "outlet_users",
      where: ["outletId", "==", props.outletId]
    }
  ];
};

console.log("query", query);

const mapState = state => ({
  outletUsers: state.firestore.ordered.outlet_users,
  requesting: state.firestore.status.requesting
});

// const actions = {
//   openSideDrawer
// };

export default compose(
  connect(mapState),
  reduxForm({
    form: "manageOutletUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  firestoreConnect(query)
)(Index);

// export default compose(
//   connect(mapState),
//   reduxForm({
//     form: "merchantForm",
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     validate
//   }),
//   firestoreConnect(query)
// )(merchantOutlet);

// const mapStateToProps = state => ({
//   users: state.users,
//   loading: state.async.loading
// });

// const actions = {
//   fetchMerchantUsers,
//   resetMerchantUsers,
//   openModal
// };

// export default compose(
//   withFirestore,
//   connect(
//     mapStateToProps,
//     actions
//   )
// )(Users);

//import liraries
import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";
import format from "date-fns/format";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";
import toMomentObject from "../../app/common/util/dateFormat";
import {
  getTransactionsForDashboard,
  clearTransactionsForDashboard
} from "./financialActions";

import LoadingComponent from "../../app/layout/LoadingComponent";
import { openModal } from "../modals/modalActions";
import classes from "./index.module.css";
import Tabs from "../../app/UI/Tabs/Tabs";
import Deposits from "./Deposits";
import Reversals from "./Reversals";
import Summary from "./Summary";
import Commissions from "./Commissions";
import Withdrawals from "./Withdrawals";
import Sales from "./Sales";

import { formatMoney } from "../../app/common/util/helpers";
import { isThisQuarter } from "date-fns";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId")
});
const $ = window.$;
// create a component

class Index extends Component {
  state = {
    loadingInitial: true,
    startDate: toMomentObject(new Date()),
    endDate: toMomentObject(new Date()),
    today: toMomentObject(new Date()),
    csvData: []
  };

  // startDate: toMomentObject(new Date()),
  // endDate: toMomentObject(new Date()),

  async componentDidMount() {
    const { match } = this.props;

    await this.props.getTransactionsForDashboard({
      reportStartDate: new Date(this.props.initialValues.reportStartDate),
      reportEndDate: new Date(this.props.initialValues.reportEndDate),
      account: match.params.account
    });

    this.setState({ loadingInitial: false });
  }

  componentWillUnmount() {
    // $(findDOMNode(this.reportStartDate)).datepicker("destroy");
    // $(findDOMNode(this.reportEndDate)).datepicker("destroy");

    this.props.clearTransactionsForDashboard();
  }

  handleCustomDatesClick = async account => {
    console.log("handleTodayClick", account);
    let reportStartDate = this.state.startDate.toDate();
    reportStartDate.setHours(0, 0, 0, 0);
    let reportEndDate = this.state.endDate.toDate();
    reportEndDate.setHours(23, 59, 59, 999);

    console.log("dates", reportStartDate, reportEndDate);

    this.setState({
      loadingInitial: true
    });
    await this.props.getTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      account: account
    });

    this.setState({ loadingInitial: false });
  };

  handleTodayClick = async account => {
    console.log("handleTodayClick", account);
    let reportStartDate = new Date();
    reportStartDate.setHours(0, 0, 0, 0);
    let reportEndDate = new Date();
    reportEndDate.setHours(23, 59, 59, 999);

    this.setState({
      loadingInitial: true,
      startDate: moment(reportStartDate),
      endDate: moment(reportEndDate)
    });

    await this.props.getTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      account: account
    });

    this.setState({ loadingInitial: false });
  };

  handleLast7DaysClick = async account => {
    let reportStartDate = new Date();
    reportStartDate.setDate(reportStartDate.getDate() - 7);
    let reportEndDate = new Date();

    this.setState({
      loadingInitial: true,
      startDate: moment(reportStartDate),
      endDate: moment(reportEndDate)
    });
    await this.props.getTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      account: account
    });

    this.setState({ loadingInitial: false });
  };

  handleMonthToDateClick = async account => {
    const today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();

    let reportStartDate = new Date(currentYear, currentMonth, 1);
    let reportEndDate = new Date();

    this.setState({
      loadingInitial: true,
      startDate: moment(reportStartDate),
      endDate: moment(reportEndDate)
    });
    await this.props.getTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      account: account
    });

    this.setState({ loadingInitial: false });
  };

  handleSummaryReport = (starting, closing, transactions) => {
    let reportData = [];
    let group;
    let outletName;

    let startingBalance = {
      transactionType: "Starting Balance",
      transactionAmount:
        starting.transactionType === "Sale"
          ? starting.balance + starting.transactionAmount
          : starting.balance - starting.transactionAmount
    };

    let closingBalance = {
      transactionType: "Closing Balance",
      transactionAmount: closing.balance
    };

    let financials = transactions
      .map(doc => {
        return {
          transactionType: doc.transactionType,
          transactionAmount: doc.transactionAmount
        };
      })
      .reduce(function(arr, doc) {
        if (!(doc.transactionType in arr)) {
          arr.push((arr[doc.transactionType] = doc));
          return arr;
        }

        arr[doc.transactionType].transactionAmount += parseFloat(
          doc.transactionAmount
        )
          ? parseFloat(doc.transactionAmount)
          : 0;

        return arr;
      }, []);

    console.log("startingBalance", startingBalance);

    financials.unshift(startingBalance);
    financials.push(closingBalance);

    reportData = financials.map(record => {
      return [
        {
          value: record.transactionType,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: formatMoney(record.transactionAmount),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        }
      ];
    });

    let reportDate = `${this.props.initialValues.reportStartDate} to ${
      this.props.initialValues.reportStartDate
    }`;
    let reportStartDate = `Start Date: ${
      this.props.initialValues.reportStartDate
    }`;
    let reportEndDate = `End Date: ${this.props.initialValues.reportEndDate}`;
    // Period: 01 Nov 2018 To 30 Nov 2018

    // {value: "Bold", style: {font: {bold: true}}},

    let reportDataSet = [
      {
        columns: ["Type", "Amount"],
        data: reportData
      }
    ];

    return reportDataSet;
  };

  render() {
    const {
      startingBalance,
      closingBalance,
      financials,
      commission,
      sales,
      deposits,
      reversals,
      merchant,
      openModal,
      requesting,
      match,
      auth,
      profile,
      getTransactionsForDashboard
    } = this.props;

    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;

    const loading = requesting[`merchants/${match.params.account}`];
    const account = match.params.account;
    const merchantsLink = `/merchants`;
    const selectedMerchantLink = `/merchants/${match.params.account}`;

    let reportDataSet = [];

    if (this.state.loadingInitial) return <LoadingComponent inverted={true} />;

    if (financials.length > 0) {
      reportDataSet = this.handleSummaryReport(
        startingBalance,
        closingBalance,
        financials
      );
    }

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={merchantsLink}>Merchants</Link> -{" "}
                <Link to={selectedMerchantLink}>{merchant.name}</Link> -
                Financials
              </span>
              <br />
              <span className={classes.ByLine}>
                You are in the {merchant.name} Financials panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3> Financials</h3>
                        <h5>
                          <strong>
                            {format(this.state.startDate, "YYYY-MM-DD")}
                          </strong>{" "}
                          to{" "}
                          <strong>
                            {format(this.state.endDate, "YYYY-MM-DD")}
                          </strong>
                        </h5>
                      </div>
                      <div
                        className={"row"}
                        style={{ alignItems: "center", flex: "1 1 auto" }}
                      >
                        <div className={"col-md-6"}>
                          <div className={classes.FilterWrapper}>
                            <ul className={classes.FilterPeriods}>
                              <li>
                                <span
                                  onClick={() => this.handleTodayClick(account)}
                                >
                                  Today
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.handleLast7DaysClick(account)
                                  }
                                >
                                  Last 7 Days
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() =>
                                    this.handleMonthToDateClick(account)
                                  }
                                >
                                  Month To Date
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"col-md-6"}>
                          <div className={classes.PageFilter}>
                            <div className={"row"}>
                              <DateRangePicker
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                startDateId="startDate" // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId="endDate" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) =>
                                  this.setState({ startDate, endDate })
                                } // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput =>
                                  this.setState({ focusedInput })
                                } // PropTypes.func.isRequired,
                                numberOfMonths={1}
                                isOutsideRange={date =>
                                  date.isAfter(this.state.today)
                                }
                                displayFormat={"YYYY-MM-DD"}
                                minimumNights={0}
                                small={true}
                                readOnly={true}
                              />

                              <button
                                className={classes.FilterReportBtn}
                                style={{ marginLeft: "1rem" }}
                                onClick={() =>
                                  this.handleCustomDatesClick(account)
                                }
                              >
                                <svg
                                  height="18"
                                  width="18"
                                  viewBox="0 0 18 18"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path
                                      id="path1"
                                      transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                      fill="#FFFFFF"
                                      d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </div>

                            {/* <!-- END Form Control--> */}
                          </div>
                        </div>
                      </div>
                    </header>

                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Sales for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {" "}
                                    {sales && formatMoney(sales)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Commission for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {commission && formatMoney(commission)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Deposits for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {deposits && formatMoney(deposits)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Reversals for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {reversals && formatMoney(reversals)}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Tabs>
                          <div label={`Summary`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.Header}>
                                <h4>Summary</h4>
                              </div>
                              <div className={classes.ExportReport}>
                                <div className={classes.EmailIconWrapper}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                  >
                                    <g>
                                      <path
                                        id="path1"
                                        transform="rotate(0,12,12) translate(0,3.20550012588501) scale(0.75,0.75)  "
                                        fill="#999999"
                                        d="M0,0.85201934L0.024002075,0.85201934 7.5710008,8.2449855 15.976002,16.49699 24.381009,8.2449855 31.928008,0.85201934 32.000001,0.85201934 32.000001,23.452 0,23.452z M1.902001,0L16.000003,0 30.096999,0 23.049009,5.9290093 16.000003,11.858019 8.9509985,5.9290093z"
                                      />
                                    </g>
                                  </svg>
                                </div>
                                <div className={classes.ExportIconWrapper}>
                                  <ExcelFile
                                    element={
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        focusable="false"
                                      >
                                        <g>
                                          <path
                                            id="path1"
                                            transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                            fill="#707070"
                                            d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                          />
                                        </g>
                                      </svg>
                                    }
                                  >
                                    <ExcelSheet
                                      dataSet={reportDataSet}
                                      name="Organization"
                                    />
                                  </ExcelFile>
                                </div>
                              </div>
                            </header>
                            <Summary account={merchant.account} />
                          </div>
                          <div label={`Sales`}>
                            <Sales account={merchant.account} />
                          </div>
                          <div label={`Commission`}>
                            <Commissions />
                          </div>
                          <div label={`Deposits`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Deposits</h4>
                              </div>
                              {userRole === "Administrator" &&
                                userType === "PrepaidPlus" && (
                                  <div className={classes.AddUser}>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        openModal("DepositModal", {
                                          account: merchant.account,
                                          merchantName: merchant.name
                                        })
                                      }
                                    >
                                      Make a Deposit
                                    </button>
                                  </div>
                                )}
                            </header>
                            <Deposits account={merchant.id} />
                          </div>
                          <div label="Reversals">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Reversals</h4>
                              </div>
                            </header>
                            <Reversals account={merchant.id} />
                          </div>
                          <div label={`Withdrawals`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Withdrawals</h4>
                              </div>
                            </header>
                            <Withdrawals />
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  startingBalance: state.financials[0],
  closingBalance: state.financials[state.financials.length - 1],
  deposits: state.financials
    .filter(item => item.transactionType === "Deposit")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  reversals: state.financials
    .filter(item => item.transactionType === "Reversal")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),

  sales: state.financials
    .filter(item => item.transactionType === "Sale")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  commission: state.financials
    .filter(item => item.transactionType === "Commission")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  financials: state.financials,
  requesting: state.firestore.status.requesting,
  merchant:
    state.firestore.ordered.merchant && state.firestore.ordered.merchant[0],
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD 00:00:00"),
    reportEndDate: moment().format("YYYY-MM-DD 23:59:59")
  },
  enableReinitialize: true
});

const actions = {
  openModal,
  getTransactionsForDashboard,
  clearTransactionsForDashboard
};

export default compose(
  firestoreConnect(props => [
    {
      collection: "merchants",
      storeAs: "merchant",
      doc: props.match.params.account
    }
  ]),
  connect(
    mapStateToProps,
    actions
  )
)(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(Index)
);

// connect((state, props) => ({
//   merchant:
//     state.firestore.ordered.merchant && state.firestore.ordered.merchant[0],
//   requesting: state.firestore.status.requesting
// }))

// { firestore: { ordered } },

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import LoadingComponent from "../../../../../../app/layout/LoadingComponent";
import EnableDevice from "./Devices/EnableDevice";
import DisableDevice from "./Devices/DisableDevice";
import GeneratePairingCode from "./Devices/GeneratePairingCode";
import UnassignDevice from "./Devices/UnassignDevice";
// import GeneratePairingCode from "./Devices/GeneratePairingCode";
import {
  fetchOutletInventory,
  resetOutletInventory
} from "../../../../../inventory/inventoryActions";
import classes from "./OutletInventory.module.css";

class OutletInventory extends Component {
  state = {
    openManageDevicePanel: false,
    selectedDevice: null
  };

  activateDevicePanelOpenClasses = [classes.RevealMore];

  handleDeviceClick = device => {
    this.setState({
      openManageDevicePanel: true,
      selectedDevice: device
    });
  };

  handleCloseClick = () => {
    this.setState({
      openManageDevicePanel: false,
      selectedDevice: null
    });
  };

  async componentDidMount() {
    await this.props.fetchOutletInventory(this.props.outletId);
  }

  async componentWillUnmount() {
    await this.props.resetOutletInventory();
  }

  render() {
    const { openManageDevicePanel, selectedDevice } = this.state;

    let { outlet_inventory, requesting, outlet, merchantName } = this.props;
    const loading =
      requesting[`outlet_inventory?where=outletId:==:${outlet.id}`];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let numberOfRecords = outlet_inventory && outlet_inventory.length;

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    let outletInventoryItems =
      outlet_inventory &&
      Object.values(outlet_inventory).map((inventoryItem, index) => {
        return (
          <tr
            className={classes.MerchantRow}
            key={index}
            onClick={() => this.handleDeviceClick(inventoryItem)}
          >
            <td>{inventoryItem.model}</td>
            <td>{inventoryItem.network}</td>
            <td>{inventoryItem.cellNumber}</td>
            <td>{inventoryItem.isActivated ? "Yes" : "No"}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>

        {outlet_inventory && outlet_inventory.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>Outlet does not have any devices.</h3>
            </div>
          ) : (




            <Fragment>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Model</th>
                  <th className={"hide-s"} scope="col">
                    Network
                  </th>
                  <th className={"hide-s"} scope="col">
                    Cell Number
                  </th>
                  <th className={"hide-s"} scope="col">
                    Is Enabled{" "}
                  </th>
                </tr>
              </thead>
              <tbody>{outletInventoryItems}</tbody>
            </table>

            {openManageDevicePanel ? (
              <div className={classes.EnableDevice}>
                <div className={classes.EnableDevice}>
                  <div className={"card card-transparent"}>
                    <div
                      className={"card-header "}
                      style={{
                        padding: "0",
                        minHeight: "4.1rem",
                        padding: "1rem",
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500"
                      }}
                    >
                      <div
                        className={"card-title semi-bold"}
                        style={{
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          textTransform: "none",
                          marginLeft: "0.7rem"
                        }}
                      >
                        Manage Device
                      </div>
                      <div className={"card-controls"}>
                        <ul>
                          <li>
                            <a
                              data-toggle="close"
                              className={"card-close"}
                              href="#"
                              onClick={this.handleCloseClick}
                            >
                              <i className={"card-icon card-icon-close"} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={"card-body"}>
                      <div className={"panel"}>
                        <Fragment>
                          <ul
                            className={
                              "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                            }
                          >
                            <li className={"nav-item active"}>
                              <a
                                data-toggle="tab"
                                href="#activation"
                                className={"active show"}
                              >
                                {selectedDevice.isActivated
                                  ? "Disable"
                                  : "Enable"}
                              </a>
                            </li>
                            <li className={"nav-item active"}>
                              <a data-toggle="tab" href="#pairing">
                                Pairing
                              </a>
                            </li>
                            <li className={"nav-item active"}>
                              <a data-toggle="tab" href="#unlink">
                                Unlink
                              </a>
                            </li>
                            <li className={"nav-item active"}>
                              <a data-toggle="tab" href="#agreement">
                                Agreement
                              </a>
                            </li>
                          </ul>
                          <div className={"tab-content"}>
                            <div className={"tab-pane active"} id="activation">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  {selectedDevice.isActivated ? (
                                    <DisableDevice
                                      device={selectedDevice}
                                      outletId={selectedDevice.outletId}
                                      account={selectedDevice.account}
                                      closePanel={this.handleCloseClick}
                                    />
                                  ) : (
                                    <EnableDevice
                                      device={selectedDevice}
                                      outletId={selectedDevice.outletId}
                                      account={selectedDevice.account}
                                      closePanel={this.handleCloseClick}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={"tab-pane"} id="pairing">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <div>
                                    <GeneratePairingCode
                                      device={selectedDevice}
                                      outlet={outlet}
                                      merchantName={merchantName}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"tab-pane"} id="unlink">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <div>
                                    <UnassignDevice
                                      device={selectedDevice}
                                      outlet={outlet}
                                      merchantName={merchantName}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"tab-pane"} id="agreement">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <div>
                                    <GeneratePairingCode
                                      device={selectedDevice}
                                      outlet={outlet}
                                      merchantName={merchantName}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={classes.Row}>
            <div>
              <div className={attachedPaginateSimpleNumbersClasses.join(" ")}>
                <ul>
                  <li>
                    <a href="">
                      <i className={classes.pg_arrow_left} />
                    </a>
                  </li>
                  <li className={attachedPaginateActiveClasses.join(" ")}>
                    <a href="">1</a>
                  </li>
                  <li
                    className={attachedPaginateNextClasses.join(" ")}
                    id="tableWithExportOptions_next"
                  >
                    <a href="">
                      <i className={classes.pg_arrow_right} />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={classes.dataTables_info}
                id="tableWithExportOptions_info"
                role="status"
                aria-live="polite"
              >
                Showing <b>1 to {numberOfRecords}</b> of {} entries
              </div>
            </div>
          </div>
</Fragment>

        )} </div>
        
      </Fragment>
    );
  }
}

const query = props => {
  console.log("props", props);
  return [
    {
      collection: "outlet_inventory",
      where: ["outletId", "==", props.outlet.id]
    }
  ];
};

const mapStateToProps = state => ({
  outlet_inventory: state.firestore.ordered.outlet_inventory,
  requesting: state.firestore.status.requesting
});

const actions = {
  fetchOutletInventory,
  resetOutletInventory
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(OutletInventory));

// const mapStateToProps = state => ({
//   deviceList: state.firestore.ordered.outlet_inventory,
//   requesting: state.firestore.status.requesting
// });

// const actions = {
//   activateDevice,
//   openSideDrawer
// };

// export default connect(
//   mapStateToProps,
//   actions
// )(firestoreConnect(query)(index));

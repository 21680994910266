import { SIDE_DRAWER_OPEN, SIDE_DRAWER_CLOSE } from "./sideDrawerConstants";

export const openSideDrawer = (sideDrawerType, sideDrawerProps) => {
  return {
    type: SIDE_DRAWER_OPEN,
    payload: {
      sideDrawerType,
      sideDrawerProps
    }
  };
};

export const closeSideDrawer = (sideDrawerType, sideDrawerProps) => {
  return {
    type: SIDE_DRAWER_CLOSE
  };
};

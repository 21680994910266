import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import toMomentObject from "../../app/common/util/dateFormat";
import {
  getTransactionsForDashboard,
  clearTransactionsForDashboard
} from "./transactionsActions";
import { openModal } from "../modals/modalActions";

import classes from "./index.module.css";
import LoadingComponent from "../../app/layout/LoadingComponent";
import Transactions from "./Transactions";

// {merchant,merchantTransactions}

class Index extends Component {
  state = {
    loadingInitial: true,
    startDate: toMomentObject(new Date()),
    endDate: toMomentObject(new Date()),
    today: toMomentObject(new Date()),
    csvData: []
  };

  async componentDidMount() {
    const { match } = this.props;

    await this.props.getTransactionsForDashboard({
      transactionStartDate: new Date(
        this.props.initialValues.transactionStartDate
      ),
      transactionEndDate: new Date(this.props.initialValues.transactionEndDate),
      account: ""
    });

    this.setState({ loadingInitial: false });
  }

  componentWillUnmount() {
    // $(findDOMNode(this.reportStartDate)).datepicker("destroy");
    // $(findDOMNode(this.reportEndDate)).datepicker("destroy");

    this.props.clearTransactionsForDashboard();
  }

  render() {
    const { merchant, loading } = this.props;

    let transactions = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (this.state.initialLoading) return <LoadingComponent inverted={true} />;

    if (!this.state.initialLoading) {
      transactions = <Transactions />;
    }

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Transactions</span>

              <br />
              <span className={classes.ByLine}>
                You are in the Transactions panel
              </span>
            </div>
          </div>
         
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Merchant Transactions</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {transactions}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions,
  initialValues: {
    outletId: "",
    transactionStartDate: moment().format("YYYY-MM-DD 00:00:01"),
    transactionEndDate: moment().format("YYYY-MM-DD 23:59:59")
  },
  enableReinitialize: true
});

const actions = {
  openModal,
  getTransactionsForDashboard,
  clearTransactionsForDashboard
};

export default connect(
  mapStateToProps,
  actions
)(Index);

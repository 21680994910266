import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import AsyncTextInput from "../../../../..//../../app/common/form/AsyncTextInput";

import { asyncInventoryComputersValidate as asyncValidate } from "../../../../../../../app/common/validation/asyncValidate.jsx";

import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthGreaterThan
} from "revalidate";
import classes from "./ChangeMACAddress.module.css";
import { changeMACAddressAction } from "../../../../../merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthGreaterThan(5)({
      message: "Must be 6 characters or longer"
    })
  )()
});

const changeMACAddress = props => {
  const {
    computer,
    account,
    changeMACAddressAction,
    handleSubmit,
    closePanel
  } = props;

  const onFormSubmit = values => {
    console.log("values", values);
    changeMACAddressAction(values, account);

    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              Type: <span className={"semi-bold"}>{computer.deviceType}</span>
            </h4>
            <br />
            <div className={"row clearfix"}>
              <div className={"col-sm-6"}>
                {/* <Field
                  name="macAddress"
                  component={TextInput}
                  type="text"
                  className={"form-control"}
                  placeholder="MAC Address"
                  pattern="[0-9]{4}"
                  required
                /> */}

                <Field
                  label="MAC Address"
                  name="macAddress"
                  component={AsyncTextInput}
                  type="text"
                  className={"form-control "}
                  placeholder="MAC Address"
                  required="required"
                />
              </div>
            </div>
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Update MAC Address</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  changeMACAddressAction
};

const mapStateToProps = (state, props) => ({
  initialValues: {
    computerId: props.computer.id
  },
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "changeMACAddressForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["macAddress"],
    validate
  })(changeMACAddress)
);

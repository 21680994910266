import React, { Component } from "react";
import classes from "./MerchantForm.module.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withFirestore } from "react-redux-firebase";
import { Field, reduxForm, formValueSelector } from "redux-form";

import { createMerchant, updateMerchant } from "../merchantActions";
import MerchantDetails from "./FormSections/MerchantDetails";
import MerchantOutlet from "./FormSections/MerchantOutlet";
import ReviewMerchant from "./FormSections/ReviewMerchant";
import Commissions from "./FormSections/Commissions";

import initialState from "./initialState";

class MerchantForm extends Component {
  attachedFormGroupClasses = [classes.FormGroup, classes.FormGroupDefault];
  attachedCardClasses = ["card", "card-transparent"];
  attachedColClasses = "col-md-6";
  attachedWizardListClasses = ["cf"];
  attachedSplit1 = [classes.Split, classes.One];
  attachedSplit2 = [classes.Split, classes.Two];

  //currentStep = null;

  state = {
    files: [],
    fileName: ""
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  onDrop = files => {
    console.log("uploaded files", files);
    this.setState({
      files,
      fileName: files[0].name
    });
  };

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  onFormSubmit = values => {
    let merchantAgreement = this.state.files ? this.state.files[0] : null;

    console.log("uploaded files", merchantAgreement);

    this.props.createMerchant(values, merchantAgreement);
  };

  render() {
    const { onSubmit } = this.props;
    const page = this.state.page;
    return (
      <div className={this.attachedCardClasses.join(" ")}>
        <div className={classes.CardBody}>
          <div id="rootwizard" className={classes.Wizard}>
            {/* <ul className={classes.Progress}>
              <li className={classes.Active}>
                <span className={classes.Step}>
                  <span className={classes.Number}>
                    <strong>1</strong>
                  </span>
                  <em>Merchant Setup</em>
                </span>
              </li>
              <li className={this.attachedSplit1.join(" ")} />
              <li className={classes.Future}>
                <span className={classes.Step}>
                  <span className={classes.Number}>
                    <strong>2</strong>
                  </span>
                  <em>Merchant Outlet(s)</em>
                </span>
              </li>
              <li className={this.attachedSplit2.join(" ")} />
              <li className={classes.Future}>
                <span className={classes.Step}>
                  <span className={classes.Number}>
                    <strong>3</strong>
                  </span>
                  <em>Review &amp; Save</em>
                </span>
              </li>
            </ul> */}

            <div className={classes.TabContent}>
              {page === 1 && (
                <MerchantDetails
                  onDrop={this.onDrop}
                  files={this.state.files}
                  fileName={this.state.fileName}
                  onSubmit={this.nextPage}
                />
              )}
              {page === 2 && (
                <Commissions
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )}
              {page === 3 && (
                <MerchantOutlet
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )}
              {page === 4 && (
                <ReviewMerchant
                  previousPage={this.previousPage}
                  fileName={this.state.fileName}
                  onSubmit={onSubmit}
                  onFormSubmit={this.onFormSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(mapState)(
//   reduxForm({ form: "merchantForm" })(MerchantForm)
// );
const actions = {
  createMerchant,
  updateMerchant
};

export default withFirestore(
  connect(
    null,
    actions
  )(reduxForm({ form: "merchantForm" })(MerchantForm))
);

import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";

import { createCredit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  expiry_method: composeValidators(customIsRequired)(),
  expiry_bank: composeValidators(customIsRequired)(),
  expiry_amount: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    })
  )(),
  expiry_date: composeValidators(customIsRequired)()
});

const $ = window.$;

class CreditForm extends Component {
  state = {
    expiryDate: null
  };

  componentDidMount() {
    $(findDOMNode(this.expiryDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "0d"
    });
    var _this = this;
    $(findDOMNode(this.expiryDate)).on("changeDate", function(e) {
      _this.props.change("expiry_date", e.date);
    });
  }

  componentWillUnmount() {
    $(findDOMNode(this.expiryDate)).datepicker("destroy");
  }

  render() {
    const { handleSubmit, createCredit, error } = this.props;

    return (
      <form onSubmit={handleSubmit(createCredit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="Credit Limit"
              name="credit_limit"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Credit Limit Amount"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <div className={"input-group date col-md-12 p-l-0"}>
              <Field
                type="text"
                className={"form-control"}
                component="input"
                placeholder="Credit Expiry date"
                name="expiry_date"
                ref={input => {
                  this.expiryDate = input;
                }}
              />

              <div className={"input-group-append"}>
                <span className={"input-group-text"}>
                  <i className={"fa fa-calendar"} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Create Credit
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    account: state.modals.modalProps.account,
    name: state.modals.modalProps.name
  },
  enableReinitialize: true
});

const actions = {
  createCredit,
  change
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "creditForm", validate })(CreditForm));

import firebase from "../../config/firebase";

export const asyncMerchantNameValidate = async values => {
  const firestore = firebase.firestore();

  console.log("asyncname", values);

  return await firestore
    .collection("merchants")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().name === values.merchantName) {
          throw { merchantName: "Merchant name is taken" };
        }
      })
    );
};

export const asyncOutletNameValidate = async values => {
  const firestore = firebase.firestore();

  console.log("asyncname", values);

  return await firestore
    .collection("merchants")
    .doc(values.account)
    .get()
    .then(doc => {
      Object.values(doc.outlets).forEach(doc => {
        if (doc.name === values.outletName) {
          throw { merchantName: "Outlet name is taken" };
        }
      });
    });
};

export const asyncValidateAccountNumber = async account => {
  const firestore = firebase.firestore();

  return await firestore
    .collection("merchants")
    .doc(account)
    .get()
    .then(doc => {
      if (doc.exists) {
        return true;
      } else {
        return false;
      }
    });
};

// export const asyncMACAddressValidate = async values => {
//   const firestore = firebase.firestore();

//   if (values.macAddress) {
//     return await firestore
//       .collection("inventory")
//       .get()
//       .then(querySnapshot =>
//         querySnapshot.forEach(function(doc) {
//           if (doc.data().macAddress === values.macAddress) {
//             throw { macAddress: "MAC Addreess name is registered" };
//           }
//         })
//       );
//   }

//   if (values.serialNumber) {
//     return await firestore
//       .collection("inventory")
//       .get()
//       .then(querySnapshot =>
//         querySnapshot.forEach(function(doc) {
//           if (doc.data().serialNumber === values.serialNumber) {
//             throw { serialNumber: "Serial Number is already registered" };
//           }
//         })
//       );
//   }

// };

export const asyncInventoryDevicesValidate = async values => {
  const firestore = firebase.firestore();
  await firestore
    .collection("inventory")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().macAddress === values.macAddress) {
          throw { macAddress: "MAC Addreess name is registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().serialNumber === values.serialNumber) {
          throw { serialNumber: "Serial is already registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().imei === values.imei) {
          throw { imei: "IMEI Number is already registered" };
        }
      })
    );
};

export const asyncInventoryComputersValidate = async values => {
  const firestore = firebase.firestore();
  await firestore
    .collection("outlet_computers")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().macAddress === values.macAddress) {
          throw { macAddress: "MAC Addreess name is registered" };
        }
      })
    );
};

export const asyncInventorySIMValidate = async values => {
  const firestore = firebase.firestore();
  await firestore
    .collection("inventory")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().simNumber === values.simNumber) {
          throw { simNumber: "SIM Number name is registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(function(doc) {
        if (doc.data().cellNumber === values.cellNumber) {
          throw { cellNumber: "Cell Number is already registered" };
        }
      })
    );
};

// export const asyncSerialNumberValidate = async values => {
//   const firestore = firebase.firestore();

//   return await firestore
//     .collection("inventory")
//     .get()
//     .then(querySnapshot =>
//       querySnapshot.forEach(function(doc) {
//         if (doc.data().serialNumber === values.serialNumber) {
//           throw { serialNumber: "Serial Number is already registered" };
//         }
//       })
//     );
// };

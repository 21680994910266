import moment from "moment";
import crypto from "crypto";
import { asyncValidateAccountNumber } from "../validation/asyncValidate";

export const objectToArray = object => {
  if (object) {
    return Object.entries(object).map(e => Object.assign(e[1], { id: e[0] }));
  }
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const createDataTree = dataset => {
  let hashTable = Object.create(null);
  dataset.forEach(a => (hashTable[a.id] = { ...a, childNodes: [] }));
  let dataTree = [];
  dataset.forEach(a => {
    if (a.parentId) hashTable[a.parentId].childNodes.push(hashTable[a.id]);
    else dataTree.push(hashTable[a.id]);
  });
  return dataTree;
};

export const createNewMerchant = (merchant, currentUser) => {
  let dateNow = new Date();

  let values = merchant;

  return {
    id: values.merchantAccount,
    account: values.merchantAccount,
    city: values.merchantCity,
    group: values.merchantGroup,
    name: values.merchantName,
    sellerId: values.merchantSellerId.value,
    suburb: values.merchantSuburb,
    dateCreated: dateNow,
    isMerchantActive: false,
    createdBy: currentUser,
    contact: {
      email: values.merchant_contact_email,
      name: values.merchant_contact_name,
      telephone: values.merchant_contact_telephone
    }
  };
};

export const createNewOutlet = (outlet, currentUser) => {
  let dateNow = new Date();

  let values = outlet.values;

  return {
    id: values.merchant_outlet_outletId,
    outletId: values.merchant_outlet_outletId,
    name: values.merchant_outlet_name,
    type: values.merchant_outlet_type.value,
    suburb: values.merchant_outlet_suburb,
    city: values.merchant_outlet_city,
    contact: {
      name: values.merchant_outlet_contact_name,
      email: values.merchant_outlet_contact_email,
      telephone: values.merchant_outlet_contact_telephone
    },
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createBankDepositObject = (deposit, currentUser) => {
  let dateNow = new Date();

  let values = deposit.values;

  return {
    account: values.account,
    transactionCategory: values.transactionCategory,
    transactionType: values.transactionType,
    bank: values.deposit_bank.value,
    merchantName: values.merchantName,
    depositMethod: values.deposit_method.value,
    depositType: values.depositType,
    dateOfDeposit: new Date(values.deposit_date),
    isTransactionCharged: values.isTransactionCharged,
    transactionAmount: parseFloat(values.deposit_amount),
    transactionDateTime: dateNow,
    transactionStatus: "Successful",
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createCreditObject = (credit, currentUser) => {
  let dateNow = new Date();

  let values = credit;

  return {
    account: values.account,
    name: values.name,
    dateApplied: dateNow,
    amount: values.credit_limit,
    expiryDate: values.expiry_date,
    status: "Pending Approval",
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createNewUser = user => {
  return {};
};

export const createDeviceInventoryObject = (item, currentUser) => {
  let dateNow = new Date();

  let values = item;
  // console.log('item.values',item)
  // console.log('item.values',item.values)

  return {
    imei: values.imei,
    isAllocated: false,
    macAddress: values.macAddress,
    manufacturer: values.manufacturer,
    model: values.model,
    serialNumber: values.serialNumber,
    type: "Device",
    dateCreated: dateNow,
    wifiAddress: values.wifiAddress,
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createComputerInventoryObject = (item, currentUser) => {
  let dateNow = new Date();

  let values = item;
  // console.log('item.values',item)
  // console.log('item.values',item.values)

  return {
    macAddress: values.macAddress,
    type: "Computer",
    dateCreated: dateNow,
    wifiAddress: values.wifiAddress,
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createSIMInventoryObject = (item, currentUser) => {
  let dateNow = new Date();

  let values = item;

  return {
    isAllocated: false,
    network: values.network,
    cellNumber: values.cellNumber,
    simNumber: values.simNumber,
    pinCode: values.pinCode,
    type: "SIM",
    dateCreated: dateNow,
    pin2Code: values.pin2Code,
    pukNumber: values.pukNumber,
    createdBy: currentUser,
    dateCreated: dateNow
  };
};

export const createMerchantComputerObject = (item, currentUser) => {
  let dateNow = new Date();

  let values = item;
  // console.log('item.values',item)
  // console.log('item.values',item.values)

  return {
    name: values.name,
    type: "Computer",
    macAddress: values.macAddress.toLowerCase(),
    dateCreated: dateNow,
    createdBy: currentUser,
    account: values.account,
    merchantName: values.merchantName,
    outletId: values.outletId,
    outletName: values.outletName,
    deviceType: values.deviceType.value,
    reportsUser: "41",
    reportsUserPassword: hashPassword("1234"),
    enabledBy: currentUser,
    dateEnabled: dateNow,
    isEnabled: true
  };
};

export const createDeviceAllocationObject = (
  account,
  outletId,
  device,
  sim,
  currentUser
) => {
  let dateNow = new Date();

  return {
    account: account,
    type: "Device",
    outletId: outletId,
    isActivated: false,
    model: `${device.manufacturer}-${device.model}`,
    macAddress: device.macAddress,
    serialNumber: device.serialNumber,
    network: sim.network,
    cellNumber: sim.cellNumber,
    dateCreated: dateNow,
    createdBy: currentUser
  };
};

export const createOutletUser = (
  userId,
  role,
  account,
  outletId,
  currentUser
) => {
  let dateNow = new Date();
  console.log("account", account);
  return {
    userId: userId,
    role: role,
    account: account,
    outletId: outletId,
    dateCreated: dateNow,
    createdBy: currentUser,
    isUserEnabled: false,
    lastUpdated: dateNow
  };
};

export const createReversalTransaction = (
  transaction,
  transactionCategory,
  currentUser
) => {
  let dateNow = new Date();

  switch (transaction.transactionType) {
    case "Sale":
      return {
        account: transaction.account,
        createdBy: currentUser,
        dateCreated: dateNow,
        isTransactionCharged: false,
        reversalType: transaction.saleType,
        transactionAmount: transaction.transactionAmount,
        transactionCategory: transactionCategory,
        transactionDateTime: dateNow,
        transactionStatus: transaction.transactionStatus,
        transactionType: "Reversal",
        reversalReference: transaction.id,
        reversalReason: transaction.reversalReason
      };
      break;
    case "Deposit":
      return {
        account: transaction.account,
        createdBy: currentUser,
        dateCreated: dateNow,
        isTransactionCharged: false,
        reversalType: transaction.depositType,
        transactionAmount: transaction.transactionAmount,
        transactionCategory: transactionCategory,
        transactionDateTime: dateNow,
        transactionStatus: transaction.transactionStatus,
        transactionType: "Reversal",
        reversalReference: transaction.id,
        reversalReason: transaction.reversalReason
      };
      break;
    case "Commission":
      return {
        account: transaction.account,
        createdBy: currentUser,
        dateCreated: dateNow,
        isTransactionCharged: false,
        reversalType: "Commission",
        transactionAmount: transaction.transactionAmount,
        transactionCategory: transactionCategory,
        transactionDateTime: dateNow,
        transactionStatus: transaction.transactionStatus,
        transactionType: "Reversal",
        reversalReference: transaction.id,
        reversalReason: transaction.reversalReason
      };
      break;
    case "Withdrawal":
      return {
        account: transaction.account,
        createdBy: currentUser,
        dateCreated: dateNow,
        isTransactionCharged: false,
        reversalType: transaction.withdrawalType,
        transactionAmount: transaction.transactionAmount,
        transactionCategory: transactionCategory,
        transactionDateTime: dateNow,
        transactionStatus: transaction.transactionStatus,
        transactionType: "Reversal",
        reversalReference: transaction.id,
        reversalReason: transaction.reversalReason
      };
      break;
    default:
      return null;
  }
};

// Create a string of random alphanumeric characters, of a given length
export const createRandomString = strLength => {
  strLength = typeof strLength == "number" && strLength > 0 ? strLength : false;
  if (strLength) {
    // Define all the possible characters that could go into a string
    var possibleCharacters = "abcdefghijklmnopqrstuvwxyz0123456789";

    // Start the final string
    var str = "";
    for (let i = 1; i <= strLength; i++) {
      // Get a random charactert from the possibleCharacters string
      var randomCharacter = possibleCharacters.charAt(
        Math.floor(Math.random() * possibleCharacters.length)
      );
      // Append this character to the string
      str += randomCharacter;
    }
    // Return the final string
    return str;
  } else {
    return false;
  }
};

// Create a string of random alphanumeric characters, of a given length
export const createRandomAccountNumber = strLength => {
  strLength = typeof strLength == "number" && strLength > 0 ? strLength : false;
  if (strLength) {
    // Define all the possible characters that could go into a string
    var possibleCharacters = "123456789";

    // Start the final string
    var str = "";
    for (let i = 1; i <= strLength; i++) {
      // Get a random charactert from the possibleCharacters string
      var randomCharacter = possibleCharacters.charAt(
        Math.floor(Math.random() * possibleCharacters.length)
      );
      // Append this character to the string
      str += randomCharacter;
    }
    // Return the final string
    return asyncValidateAccountNumber(str) ? str : createRandomAccountNumber(4);
  } else {
    return false;
  }
};

const hashingSecret = "thisIsASecret";
// Create a SHA256 hash
export const hashPassword = str => {
  if (typeof str == "string" && str.length > 0) {
    var hash = crypto
      .createHmac("sha256", hashingSecret)
      .update(str)
      .digest("hex");
    return hash;
  } else {
    return false;
  }
};

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import { compose } from "redux";

// import {fetchMerchantTransactionsList} from '../../../../app/data/firebaseAPI'
import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ReportBody from "./ReportBody";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    const reportsLink = `/reports`;

    let reportBody = <ReportBody />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={reportsLink}>Reports</Link> {" - "} Transactions by
                Date
              </span>

              <br />
              <span className={classes.ByLine}>
                You are in the PrepaidPlus Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Group Sales by Date</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {reportBody}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// const mapStateToProps = (state, ownProps) => {

//   return {
//     account
//   };
// };

// const mapStateToProps = (state, ownProps) => {
//   console.log("ownProps", ownProps);
//   const account = ownProps.match.params.account;
//   let merchant = {};

//   if (
//     state.firestore.ordered.merchants &&
//     state.firestore.ordered.merchants.length > 0
//   ) {
//     merchant = state.firestore.ordered.merchants.filter(
//       merchant => merchant.account === account
//     )[0];
//   }

//   return {
//     requesting: state.firestore.status.requesting,
//     loading: state.async.loading,
//     auth: state.firebase.auth,
//     merchant,
//     account
//   };
// };

// export default connect(mapStateToProps)(Index);

const mapState = (state, ownProps) => {
  const account = ownProps.match.params.account;
  let merchant = {};

  if (
    state.firestore.ordered.merchants &&
    state.firestore.ordered.merchants.length > 0
  ) {
    merchant = state.firestore.ordered.merchants.filter(
      merchant => merchant.account === account
    )[0];
  }

  return {
    requesting: state.firestore.status.requesting,
    loading: state.async.loading,
    auth: state.firebase.auth,
    merchant
  };
};

export default compose(
  withFirestore,
  connect(mapState)
)(Index);

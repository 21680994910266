//import liraries
import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItem.module.css";

// create a component
const navigationItem = props => (
  <li className={classes.NavigationItem}>
    {/* <a href="{props.link}" className={props.active ? classes.active : null}>
      {props.children}
    </a> */}
    <NavLink to={props.link} activeClassName={classes.Selected}>{props.children}</NavLink>
  </li>
);

//make this component available to the app
export default navigationItem;

import React from "react";
import { reduxForm, Field } from "redux-form";
import Dropzone from "react-dropzone";

import {
  combineValidators,
  createValidator,
  composeValidators,
  isRequired
} from "revalidate";

import TextInput from "../../../../app/common/form/TextInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import { isNumber } from "util";

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler"
};
const customIsRequired = isRequired({ message: "Required" });
const frequencyIsRequired = isRequired({
  message: "Select Commission Frequency"
});

const isAFloat = createValidator(
  message => value => {
    if (isNaN(parseFloat(value))) {
      return message;
    }
  },

  field => `${field} should be numeric`
);

const commissionFrequency = [
  {
    label: "Daily",
    value: "Daily"
  },
  {
    label: "Monthly",
    value: "Monthly"
  }
];

const validate = combineValidators({
  airtime_bemobile: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  airtime_mascom: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  airtime_orange: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  electricity_bpc: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  collections_botswanalife: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  bill_BPC: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  bill_DSTV: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  bill_WUC: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  bill_BTCL: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number"
    })
  )(),
  commission_frequency: composeValidators(frequencyIsRequired)()
});

const commissions = props => {
  const { handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <strong>Airtime Commission</strong>
      </p>

      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            // label="Outlet Name"
            name="airtime_bemobile"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="beMobile "
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            // label="Outlet Name"
            name="airtime_mascom"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Mascom "
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            // label="Outlet Name"
            name="airtime_orange"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Orange "
            required="required"
          />
        </div>
      </div>

      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <p>
            <strong>Electricity Commission</strong>
          </p>
          <Field
            // label="Outlet Name"
            name="electricity_bpc"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="BPC "
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <p>
            <strong>Collections Commission</strong>
          </p>

          <Field
            // label="Outlet Name"
            name="collections_botswanalife"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Botswana Life "
            required="required"
          />
        </div>
      </div>
      <p>
        <strong>Bill Payments</strong>
      </p>
      <div className={"row clearfix"}>
        <div className={"col-sm-3"}>
          <Field
            // label="Outlet Name"
            name="bill_BPC"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="BPC "
            required="required"
          />
        </div>
        <div className={"col-sm-3"}>
          <Field
            // label="Outlet Name"
            name="bill_DSTV"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="DSTV "
            required="required"
          />
        </div>
        <div className={"col-sm-3"}>
          <Field
            // label="Outlet Name"
            name="bill_WUC"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="WUC "
            required="required"
          />
        </div>
        <div className={"col-sm-3"}>
          <Field
            // label="Outlet Name"
            name="bill_BTCL"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="BTCL "
            required="required"
          />
        </div>
      </div>

      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <p>
            <strong>Commission Frequency</strong>
          </p>

          <div>
            <Field
              name="commission_frequency"
              options={commissionFrequency}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0"
                }
              })}
              component={SelectInput}
              placeholder="Commission Frequency"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
        </div>
      </div>

      <br />
      <div
        className={"p-t-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix"}
      >
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons pull-right"}
              type="submit"
            >
              <span>Next</span>
            </button>
          </li>
          <li className={"previous"}>
            <button
              className={"btn btn-default btn-cons pull-right"}
              type="button"
              onClick={previousPage}
            >
              <span>Previous</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "merchantForm", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true,
  validate
})(commissions);

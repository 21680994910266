import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";

import firebase from "../../../app/config/firebase";

import { compose } from "redux";

import { openModal } from "../../modals/modalActions";
import classes from "./CreditLimit.module.css";

class CreditLimit extends Component {
  state = {
    loadingInitial: true,
    agreementUrl: "",
    showModal: false
  };

  state = {
    //showSideDrawer: false
  };

  render() {
    let { requesting } = this.props;
    const loading = requesting[`credit?where=status:==:"Credit Application"`];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let is;

    let limits =
      this.props.limits &&
      this.props.limits.map(limit => {
        return (
          <tr className={classes.MerchantRow} key={limit.email}>
            <td>
              {format(
                limit.dateCreated && limit.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{limit.amount}</td>

            <td>
              {format(
                limit.dateCreated && limit.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{limit.status}</td>
            <td>{limit.actionedBy}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Approved</th>
                  <th scope="col">Credit Amount</th>
                  <th scope="col">Expiry Date</th>
                  <th>Status</th>

                  <th className={"hide-s"} scope="col">
                    Authorised By
                  </th>
                </tr>
              </thead>
              <tbody>{limits}</tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

let query = props => [
  {
    collection: "credit",
    where: [["account", "==", props.account]]
  }
];

const mapStateToProps = state => ({
  limits: state.firestore.ordered.credit,
  requesting: state.firestore.status.requesting
});

const actions = {
  openModal
};

// export default connect(
//   mapStateToProps,
//   actions
// )(CreditLimit);

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(CreditLimit));

import { SubmissionError, reset } from "redux-form";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";
import firebase from "../../app/config/firebase";

import { FETCH_BALANCES } from "./financialsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import { closeModal } from "../modals/modalActions";

import {
  createBankDepositObject,
  createCreditObject
} from "../../app/common/util/helpers";

export const createDeposit = deposit => {
  console.log("deposit", deposit);

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newDeposit = createBankDepositObject(deposit, user);
    try {
      console.log("await firestore", "await firestore DepositObject");
      let createdDeposit = await firestore
        .collection("transactions")
        .add(newDeposit);

      dispatch(closeModal());

      toastr.success("Success", "Deposit has been created");
    } catch (error) {
      console.log("Deposit error", error);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createCredit = credit => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    console.log("credit", credit);

    let newCredit = createCreditObject(credit, user);
    try {
      console.log("await firestore", "await firestore DepositObject");
      await firestore.collection("credit").add(newCredit);

      dispatch(closeModal());

      toastr.success("Success", "Credit has been created");
    } catch (error) {
      console.log("Credit error", error);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const approveCredit = credit => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    try {
      dispatch(asyncActionStart());
      await firestore
        .collection("credit")
        .doc(credit.id)
        .set(
          {
            actionedBy: currentUser,
            status: "Approved",
            dateActioned: now
          },
          { merge: true }
        );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Device has been activated");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};

export const declineCredit = credit => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    try {
      dispatch(asyncActionStart());
      await firestore
        .collection("credit")
        .doc(credit.id)
        .set(
          {
            actionedBy: currentUser,
            status: "Declined",
            dateActioned: now
          },
          { merge: true }
        );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Device has been activated");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};

export const getTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  let today = new Date(Date.now());

  console.log("getTransactionsForDashboard ", values);

  const account = values.account;
  let reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  console.log("reportStartDate is ", reportStartDate);
  console.log("reportEndDate is ", reportEndDate);

  let reportStartDateYear = reportStartDate.getFullYear();
  let reportStartDateMonth = reportStartDate.getMonth();
  let reportStartDateDay = reportStartDate.getDate();

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportStartDate = new Date(
    reportStartDateYear,
    reportStartDateMonth,
    reportStartDateDay,
    "00",
    "00",
    "00"
  );

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  console.log("reportEndDate is ", reportEndDate);
  // console.log("outletId is ", outletId);
  // console.log("reportStartDate is ", reportStartDate);
  // console.log("reportEndDate is ", reportEndDate);
  // console.log("today is ", today);

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("merchant_balance");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("account", "==", account)
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate);
    // .orderBy("transactionDateTime", "desc");

    let querySnap = await query.get();

    console.log("querySnap.docs.length", querySnap.docs.length);
    let balances = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_BALANCES, payload: { balances } });
      dispatch(asyncActionFinish());
      // return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      balances.push(transaction);
    }

    console.log("balances is ", balances);

    dispatch({ type: FETCH_BALANCES, payload: { balances } });
    dispatch(asyncActionFinish());
    // return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getCachetTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  let reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportStartDateYear = reportStartDate.getFullYear();
  let reportStartDateMonth = reportStartDate.getMonth();
  let reportStartDateDay = reportStartDate.getDate();

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportStartDate = new Date(
    reportStartDateYear,
    reportStartDateMonth,
    reportStartDateDay,
    "00",
    "00",
    "00"
  );

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("merchant_balance");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate);

    let querySnap = await query.get();

    console.log("querySnap.docs.length", querySnap.docs.length);
    let balances = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_BALANCES, payload: { balances } });
      dispatch(asyncActionFinish());
      // return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      balances.push(transaction);
    }

    console.log("balances is ", balances);

    dispatch({ type: FETCH_BALANCES, payload: { balances } });
    dispatch(asyncActionFinish());
    // return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const clearTransactionsForDashboard = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch(asyncActionStart());

    let balances = [];
    dispatch({ type: FETCH_BALANCES, payload: { balances } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

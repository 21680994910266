import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import classes from "./DisableComputer.module.css";
import { disableComputerAction } from "../../../../../merchantActions";

const disableComputer = ({
  disableComputerAction,
  handleSubmit,
  closePanel,
  computer
}) => {
  const onFormSubmit = values => {
    disableComputerAction(computer.id);
    closePanel();
  };

  return (
    <div label="Computers">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Computer Name:{" "}
              <span className={"semi-bold"}>{computer.name}</span>
            </h5>
            <h5>
              MAC Address:{" "}
              <span className={"semi-bold"}>{computer.macAddress}</span>
            </h5>

            <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Disable Computer</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  disableComputerAction
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableComputerForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(disableComputer)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import firebase from "../../../../app/config/firebase";

import TextInput from "../../../../app/common/form/TextInput";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween
} from "revalidate";
import classes from "./SetRateActive.module.css";
import { setCommissionRateActive } from "../../../settings/settingsActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthBetween(4, 4)({
      message: "Must be 4 numbers"
    })
  )()
});

class SetRateActive extends Component {
  render() {
    const {
      setCommissionRateActive,
      handleSubmit,
      closePanel,
      rateId,
      outletId,
      account
    } = this.props;

    const onFormSubmit = values => {
      setCommissionRateActive(rateId);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={classes.TabHeader}>
          <div className={classes.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Outlet Id: <span className={"semi-bold"}>{outletId}</span>
              </h5>
              {/* <h5>
                Agreement:{" "}
                <a
                  className={"semi-bold"}
                  href={this.state.agreementUrl}
                  target="_blank"
                >
                  {`${outletId} Device Agreement`}
                </a>
              </h5> */}
              <br />

              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={"btn btn-primary btn-cons"}
                      type="submit"
                      // onClick={handleNextClick}
                    >
                      <span>Set Rate Active</span>
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  setCommissionRateActive
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(SetRateActive)
);

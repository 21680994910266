import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class FinancialRecords extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {}
  };

  render() {
    const {
      financials,
      startingBalance,
      closingBalance,
      requesting,
      account
    } = this.props;

    // const loading = requesting[`merchants/${account}`];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let reportDataSet;

    let financialRecords = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    financials && financials.length > 0
      ? (financialRecords = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Amount</th>

                {/* <th scope="col">Sales Value2</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className={classes.MerchantRow}>
                <td>Starting Balance</td>
                <td>
                  {startingBalance.transactionType === "Sale"
                    ? formatMoney(
                        startingBalance.balance +
                          startingBalance.transactionAmount
                      )
                    : formatMoney(
                        startingBalance.balance -
                          startingBalance.transactionAmount
                      )}
                </td>
              </tr>

              {financials.map((report, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>{report.transactionType}</td>
                    <td>{formatMoney(report.transactionAmount)}</td>
                  </tr>
                );
              })}
              <tr className={classes.MerchantRow}>
                <td>Closing Balance</td>
                <td>{formatMoney(closingBalance.balance)}</td>
              </tr>

              {/* <tr className={classes.Totals}>
              <td colSpan="4">
                <div className={classes.MoneyTotalLabel}> Total:</div>
              </td>
              <td className={classes.MoneyTotal}>{salesTotal}</td>
            </tr> */}
            </tbody>
          </table>
        ))
      : (financialRecords = (
          <h3>
            Selected Merchant has no financial transactions for selected period.
          </h3>
        ));

    let group;
    let outletName;
    let reportDate;

    // if (loading)
    //   return (
    //     <Fragment>
    //       <LoadingComponent inverted={true} />
    //     </Fragment>
    //   );

    // if (!this.state.loadingInitial) {
    //   let salesTotal = financials.reduce(function(accumulator, transaction) {
    //     return accumulator + transaction.transactionAmount;
    //   }, 0);

    //   let firstrow =
    //     financials.length > 0
    //       ? financials[0]
    //       : { group: "None", outletName: "None" };

    //   group = firstrow.group;
    //   outletName = firstrow.outletName;

    //   // let salesTotal = 0;

    // financials.length > 0
    //   ? (financialRecords = (
    //       <table className={classes.MerchantTable}>
    //         <thead>
    //           <tr>
    //             <th scope="col">Account</th>
    //             <th scope="col">Reference</th>
    //             <th scope="col">Type</th>
    //             <th scope="col">Date</th>
    //             <th scope="col">Amount</th>
    //             <th scope="col">Balance</th>

    //             {/* <th scope="col">Sales Value2</th> */}
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {financials.map((report, index) => {
    //             return (
    //               <tr className={classes.MerchantRow} key={index}>
    //                 <td>{report.account}</td>
    //                 <td scope="col">{report.id}</td>
    //                 <td>{report.transactionType}</td>
    //                 <td>
    //                   {format(
    //                     report.transactionDateTime.toDate(),
    //                     "YYYY-MM-DD  HH:mm:ss"
    //                   )}
    //                 </td>
    //                 <td>{report.transactionAmount}</td>
    //                 <td>{report.balance}</td>

    //                 {/* <td>{report.transactionValue2}</td> */}
    //               </tr>
    //             );
    //           })}
    //           {/* <tr className={classes.Totals}>
    //             <td colSpan="4">
    //               <div className={classes.MoneyTotalLabel}> Total:</div>
    //             </td>
    //             <td className={classes.MoneyTotal}>{salesTotal}</td>
    //           </tr> */}
    //         </tbody>
    //       </table>
    //     ))
    //   : (financialRecords = (
    //       <h4>
    //         Selected Merchant has no financial transactions for selected
    //         period.
    //       </h4>
    //     ));

    //   let reportData = financials.map(report => {
    //     return [
    //       {
    //         value: report.outletName,
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       },
    //       {
    //         value: report.createdBy,
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       },
    //       {
    //         value: format(
    //           report.transactionDateTime.toDate(),
    //           "YYYY-MM-DD  HH:mm:ss"
    //         ),
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       },
    //       {
    //         value: report.saleTypeDetail,
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       },
    //       {
    //         value: report.meterNumber,
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       },
    //       {
    //         value: report.transactionAmount,
    //         style: {
    //           fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
    //         }
    //       }
    //     ];
    //   });

    //   reportData.push([
    //     {
    //       value: "",
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } }
    //       }
    //     },
    //     {
    //       value: "",
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } }
    //       }
    //     },
    //     {
    //       value: "",
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } }
    //       }
    //     },
    //     {
    //       value: "",
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } }
    //       }
    //     },
    //     {
    //       value: "Total",
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } },
    //         font: { sz: "12", bold: true }
    //       }
    //     },
    //     {
    //       value: salesTotal,
    //       style: {
    //         fill: { patternType: "solid", fgColor: { rgb: "920202" } },
    //         font: { sz: "12", bold: true }
    //       }
    //     }
    //   ]);

    //   // 920202;
    //   //4e0000

    //   // Itemised Sales Report
    //   //

    //   reportDate = `${this.props.initialValues.reportStartDate} to ${
    //     this.props.initialValues.reportStartDate
    //   }`;
    //   let reportStartDate = `Start Date: ${
    //     this.props.initialValues.reportStartDate
    //   }`;
    //   let reportEndDate = `End Date: ${this.props.initialValues.reportEndDate}`;
    //   // Period: 01 Nov 2018 To 30 Nov 2018

    //   // {value: "Bold", style: {font: {bold: true}}},

    //   reportDataSet = [
    //     {
    //       columns: [
    //         { title: "Headings", width: { wpx: 80 } }, //pixels width
    //         { title: "Text Style", width: { wch: 40 } }, //char width
    //         { title: "Colors", width: { wpx: 90 } }
    //       ],
    //       data: [
    //         [
    //           { value: "H1", style: { font: { sz: "24", bold: true } } },
    //           { value: "Bold", style: { font: { bold: true } } },
    //           {
    //             value: "Red",
    //             style: {
    //               fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //             }
    //           }
    //         ]
    //       ]
    //     },
    //     {
    //       ySteps: 1,
    //       columns: [
    //         {
    //           title: "",
    //           width: { wpx: 890 },
    //           style: {
    //             font: { sz: "18", bold: true }
    //           }
    //         },
    //         { title: "", width: { wpx: 10 } }
    //       ],
    //       data: [[group, outletName]]
    //     },
    //     {
    //       ySteps: 1,

    //       columns: [
    //         { title: "", width: { wpx: 10 } },
    //         { title: "", width: { wpx: 10 } }
    //       ],
    //       data: [
    //         [
    //           {
    //             value: reportStartDate,
    //             width: { wpx: 150 },
    //             style: {
    //               font: { sz: "12", bold: true }
    //             }
    //           },
    //           {
    //             value: reportEndDate,
    //             width: { wpx: 150 },
    //             style: {
    //               font: { sz: "12", bold: true }
    //             }
    //           }
    //         ]
    //       ]
    //     },

    //     {
    //       // xSteps: 1, // Will start putting cell with 1 empty cell on left most
    //       ySteps: 1,
    //       columns: [
    //         {
    //           title: "Store Name",
    //           width: { wpx: 150 },
    //           style: {
    //             font: { sz: "16", bold: true },
    //             fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //           }
    //         },
    //         {
    //           title: "User Name",
    //           width: { wpx: 150 },
    //           style: { font: { bold: true } }
    //         },
    //         {
    //           title: "Date Time",
    //           width: { wpx: 150 },
    //           style: {
    //             font: { bold: true },
    //             fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //           }
    //         },
    //         {
    //           title: "Sale Type",
    //           width: { wpx: 150 },
    //           style: {
    //             font: { bold: true },
    //             fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //           }
    //         },
    //         {
    //           title: "Transaction Detail",
    //           width: { wpx: 150 },
    //           style: {
    //             font: { bold: true },
    //             fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //           }
    //         },
    //         {
    //           title: "Sale Value",
    //           width: { wpx: 150 },
    //           style: {
    //             font: { bold: true },
    //             fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
    //           }
    //         }
    //       ],
    //       data: reportData
    //     }
    //   ];
    // }

    return (
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>{financialRecords}</div>
      </div>
    );
  }
}

const actions = {
  // getTransactionsForDashboard
};

const mapStateToProps = state => ({
  startingBalance: state.financials[0],
  closingBalance: state.financials[state.financials.length - 1],
  deposits: state.financials
    .filter(item => item.transactionType === "Bank Deposit")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  financials: state.financials
    .map(doc => {
      return {
        transactionType: doc.transactionType,
        transactionAmount: doc.transactionAmount
      };
    })
    .reduce(function(arr, doc) {
      if (!(doc.transactionType in arr)) {
        arr.push((arr[doc.transactionType] = doc));
        return arr;
      }

      arr[doc.transactionType].transactionAmount += parseFloat(
        doc.transactionAmount
      )
        ? parseFloat(doc.transactionAmount)
        : 0;

      return arr;
    }, [])
});

// const query = [
//   {
//     collection: "merchants",
//     orderBy: ["name", "desc"]
//   }
// ];

export default connect(
  mapStateToProps,
  actions
)(FinancialRecords);

// export default connect(
//   mapStateToProps,
//   actions
// )(reduxForm({ form: "depositForm", validate })(DepositForm));

import React from "react";
import { connect } from "react-redux";
import TestModal from "./TestModal";
import DepositModal from "./DepositModal";
import RegisterModal from "./RegisterModal";
import CreditLimitModal from "./CreditLimitModal";
import CreateSellerModal from "./CreateSellerModal";
import CreateOutletModal from "./CreateOutletModal";
import RegisterDeviceModal from "./RegisterDeviceModal";
import CreateMerchantModal from "./CreateMerchantModal";
import RegisterSIMCardModal from "./RegisterSIMCardModal";
import CreateCommissionModal from "./CreateCommissionModal";
import MerchantAgreementModal from "./MerchantAgreementModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangeUserPasswordModal from "./ChangeUserPasswordModal";
import RegisterSystemUserModal from "./RegisterSystemUserModal";
import RegisterMechantComputerModal from "./RegisterMechantComputerModal";
import RegisterDeviceWithOutletModal from "./RegisterDeviceWithOutletModal";

const modalLookup = {
  TestModal,
  DepositModal,
  RegisterModal,
  CreditLimitModal,
  CreateSellerModal,
  CreateOutletModal,
  CreateMerchantModal,
  RegisterDeviceModal,
  RegisterSIMCardModal,
  CreateCommissionModal,
  ChangeUserNameModal,
  ChangeUserPasswordModal,
  MerchantAgreementModal,
  RegisterSystemUserModal,
  RegisterMechantComputerModal,
  RegisterDeviceWithOutletModal
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);

import React, { Component, Fragment } from "react";
import format from "date-fns/format";

import SelectedDevice from "./SelectedDevice";
import classes from "./Devices.module.css";

class Devices extends Component {
  state = {
    deviceDetailPanelOpen: true,
    selectedDevice: null,
    selectedRow: -1
  };

  state = {};

  handleDeviceClick = (device, index) => {
    console.log("device", device);
    this.setState({
      deviceDetailPanelOpen: true,
      selectedDevice: device,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    this.setState({
      deviceDetailPanelOpen: false,
      selectedDevice: null,
      selectedRow: -1
    });
  };

  deviceRowClasses = null;
  deviceDetailPanelOpenClasses = [classes.RevealMore];

  render() {
    let { inventoryItemList } = this.props;
    let { deviceDetailPanelOpen, selectedDevice, selectedRow } = this.state;

    !selectedRow
      ? (this.deviceRowClasses = [classes.MerchantRow])
      : (this.deviceRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction
        ]);

    let inventoryItems = Object.values(inventoryItemList).map(
      (inventoryItem, index) => {
        return (
          <tr
            key={index}
            onClick={() => this.handleDeviceClick(inventoryItem, index)}
            className={
              this.state.selectedRow === index
                ? classes.SelectedDevice
                : classes.MerchantRow
            }
          >
            <td>{inventoryItem.manufacturer}</td>
            <td>{inventoryItem.model}</td>
            <td>{inventoryItem.macAddress}</td>

            <td>
              {inventoryItem.cellNumber ? inventoryItem.cellNumber : "N/A"}
            </td>
            <td>
              {inventoryItem.isAllocated
                ? `Yes - ${inventoryItem.allocatedTo}`
                : `No`}
            </td>
            <td>
              {format(
                inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      }
    );

    return (
      <Fragment>
        <table className={classes.MerchantTable}>
          <thead>
            <tr>
              <th scope="col">Manufacturer</th>
              <th scope="col">Model</th>
              <th scope="col">MAC Address</th>
              <th className={"hide-s"} scope="col">
                Cell Number
              </th>
              <th className={"hide-s"} scope="col">
                Is Allocated?
              </th>
              <th className={"hide-s"} scope="col">
                Date Added
              </th>
            </tr>
          </thead>
          <tbody>{inventoryItems}</tbody>
        </table>
        {deviceDetailPanelOpen ? (
          <div className={classes.DeviceDetail}>
            <div
              className={"card card-transparent"}
              style={{ paddingLeft: "1.5rem" }}
            >
              <div
                className={"card-header "}
                style={{
                  minHeight: "3.7rem",
                  padding: "0.8rem",
                  color: "#666",
                  fontSize: "1.4rem",
                  fontFamily: "Roboto",
                  fontWeight: "500"
                }}
              >
                <div
                  className={"card-title semi-bold"}
                  style={{
                    color: "#666",
                    fontSize: "1.4rem",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    textTransform: "none",
                    marginLeft: "0.7rem"
                  }}
                >
                  Device Detail
                </div>
                <div className={"card-controls"}>
                  <ul>
                    <li>
                      <a
                        data-toggle="close"
                        className={"card-close"}
                        href="#"
                        onClick={this.handleCloseClick}
                      >
                        <i className={"card-icon card-icon-close"} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={"card-body"} style={{ padding: "0 1.5rem" }}>
                <Fragment>
                  <div className={"row column-seperation"}>
                    <div className={"col-md-12"}>
                      <SelectedDevice
                        device={selectedDevice}
                        closePanel={this.handleCloseClick}
                      />
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default Devices;

import React from "react";

const LoadingComponent = ({ inverted }) => {
  return (
    // <div className={"progress"}>
    //   <div className={"progress-circle-indeterminate"} />
    // </div>

    <div
      className={"full-height d-flex justify-content-center align-items-center"}
      style={{ width: "100%" }}
    >
      <div className={"card-body text-center"}>
        <img
          className={"image-responsive-height demo-mw-50"}
          src="assets/img/progress.svg"
          alt="Progress"
        />
      </div>
    </div>
  );
};

export default LoadingComponent;

//import liraries
import React, { Fragment, Component } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { Link } from "react-router-dom";
import { compose } from "redux";

import EditName from "./EditFields/EditName";
import EditGroup from "./EditFields/EditGroup";
import EditContact from "./EditFields/EditContact";
import EditLocation from "./EditFields/EditLocation";
import EditSellerDetails from "./EditFields/EditSellerDetails";
import EditStatus from "./EditFields/EditStatus";

import Outlets from "./Outlets/Outlets";
import Documents from "./Documents/Documents";
import CreditLimit from "../../../financials/Credit/CreditLimit";
import Providers from "../../../providers/Providers";

import Users from "./Users/Users";
import { openModal } from "../../../modals/modalActions";
import Tabs from "../../../../app/UI/Tabs/Tabs";

import classes from "./MerchantProfile.module.css";

// create a component
class MerchantProfile extends Component {
  state = {
    openManageDetailsPanel: false,
    selectedField: null,
    selectedFieldHeader: null,
    outletsFiltered: [],
    outletSearchText: ""
  };

  filterByOutletName = event => {
    let outletsList = Object.values(this.props.outlets);
    let keyword = event.target.value.toLowerCase();
    let outletsFiltered = outletsList.filter(outlet => {
      return outlet.name.toLowerCase().indexOf(keyword) > -1;
    });
    this.setState({
      outletsFiltered,
      outletSearchText: event.target.value
    });
  };

  handleFieldClick = selectedField => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    let field;
    switch (selectedField) {
      case "Name":
        field = (
          <EditName
            name={this.props.merchant.name}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Group":
        field = (
          <EditGroup
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Contact":
        field = (
          <EditContact
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Location":
        field = (
          <EditLocation
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "SellerDetails":
        field = (
          <EditSellerDetails
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Status":
        field = (
          <EditStatus
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            status={this.props.merchant.isMerchantActive}
          />
        );
        break;

      default:
        field = null;
    }

    this.setState({
      openManageDetailsPanel: true,
      selectedField: field,
      selectedFieldHeader: selectedField
    });
  };

  handleCloseClick = () => {
    this.setState({
      openManageDetailsPanel: false,
      selectedField: null,
      selectedFieldHeader: null
    });
  };

  render() {
    const { merchant, auth, profile, outlets, commission } = this.props;
    const { outletsFiltered, outletSearchText } = this.state;
    const {
      openManageDetailsPanel,
      selectedField,
      selectedFieldHeader
    } = this.state;

    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;
    const reportsLink = `/merchants/${merchant.account}/reports`;
    const financialsLink = `/merchants/${merchant.account}/financials`;

    let attachedOutletListAreaClasses = [
      classes.OutletListArea,
      classes.clearfix
    ];

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    const { openModal } = this.props;
    return (
      <div className={"card card-default"}>
        {/* <div className={"card-header"}>
          <div className={"card-title"}>Merchant Details</div>
        </div> */}
        <div className={"card-body"}>
          <header className={classes.TabHeader}>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Name
                </h5>
                <h3 className={"no-margin"}>{merchant.name}</h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Account
                </h5>
                <h3 className={"no-margin"}>{merchant.account}</h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Financials
                </h5>

                <h3
                  className={"no-margin bold"}
                  style={{ display: "inline-block", verticalAlign: "top" }}
                >
                  <Link to={financialsLink}>Financials</Link>
                </h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Reports
                </h5>
                <h3 className={"no-margin bold"}>
                  <Link to={reportsLink}>Reports</Link>
                </h3>
              </div>
            </div>
          </header>

          <div className={"card card-transparent"}>
            <div className={classes.CardBody}>
              <Tabs>
                <div label="Details">
                  <div className={classes.Details}>
                    <div className={classes.DetailsContent}>
                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Name")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Name
                            </label>
                            <h5 className={"no-margin"}>{merchant.name}</h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Group")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Group
                            </label>
                            <h5 className={"no-margin"}>{merchant.group}</h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Account
                          </label>
                          <h5 className={"no-margin"}>{merchant.account}</h5>
                        </div>
                      </div>

                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Date Registered
                          </label>
                          <h5 className={"no-margin"}>
                            {format(
                              merchant.dateCreated &&
                                merchant.dateCreated.toDate(),
                              "YYYY-MM-DD"
                            )}
                          </h5>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Status")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Is Merchant Active
                            </label>
                            <h5 className={"no-margin"}>
                              {merchant.isMerchantActive ? "Yes" : "No"}
                            </h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() =>
                              this.handleFieldClick("SellerDetails")
                            }
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              ClientID
                            </label>
                            <h5 className={"no-margin"}>{merchant.sellerId}</h5>
                          </div>
                        </div>
                      </div>

                      <div className={"row p-t-30"}>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Contact")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Contact
                            </label>

                            <h5 className={"no-margin"}>
                              {merchant.contact.name}
                            </h5>
                            <h5 className={"no-margin"}>
                              {merchant.contact.email}
                            </h5>
                            <h5 className={"no-margin"}>
                              {merchant.contact.telephone}
                            </h5>
                          </div>
                        </div>

                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Location")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Location
                            </label>

                            <h5 className={"no-margin"}>{merchant.suburb}</h5>
                            <h5 className={"no-margin"}>{merchant.city}</h5>
                          </div>
                        </div>
                      </div>

                      <div className={"wizard-footer padding-20"}>
                        <p className={"small hint-text pull-left no-margin"} />
                        <div className={"pull-right"} />
                        <div className={"clearfix"} />
                      </div>
                    </div>

                    {openManageDetailsPanel ? (
                      <div className={classes.DetailsEdit}>
                        <div className={classes.EnableUser}>
                          <div className={classes.EnableUser}>
                            <div className={"card card-transparent"}>
                              <div
                                className={"card-header "}
                                style={{
                                  padding: "0",
                                  minHeight: "4.1rem",
                                  padding: "1rem",
                                  color: "#666",
                                  fontSize: "1.4rem",
                                  fontFamily: "Roboto",
                                  fontWeight: "500"
                                }}
                              >
                                <div
                                  className={"card-title semi-bold"}
                                  style={{
                                    color: "#666",
                                    fontSize: "1.4rem",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    textTransform: "none",
                                    marginLeft: "0.7rem"
                                  }}
                                >
                                  Manage Merchant {selectedFieldHeader}
                                </div>
                                <div className={"card-controls"}>
                                  <ul>
                                    <li>
                                      <a
                                        data-toggle="close"
                                        className={"card-close"}
                                        href="#"
                                        onClick={this.handleCloseClick}
                                      >
                                        <i
                                          className={
                                            "card-icon card-icon-close"
                                          }
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className={"card-body"}>
                                <div className={"panel"}>
                                  <Fragment>{selectedField}</Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div label="Portal Users">
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h4>Merchant Users</h4>
                    </div>
                    {(userRole === "Administrator" ||
                      userType === "PrepaidPlus") && (
                      <div className={classes.AddUser}>
                        <button
                          className={classes.AddUserBtn}
                          onClick={() =>
                            openModal("RegisterModal", {
                              account: merchant.account,
                              group: merchant.group
                            })
                          }
                        >
                          Create New User
                        </button>
                      </div>
                    )}
                  </header>
                  <Users account={merchant.id} />
                </div>
                <div label="Outlets">
                  <header className={classes.TabHeader}>
                    {/* <div className={classes.PageHeader}>
                        <h4>Outlet(ss)</h4>
                      </div> */}

                    <div className={classes.PageHeader}>
                      <div className={classes.PageTitle}>
                        <h4>{merchant.name} Outlets</h4>
                      </div>
                      <div className={classes.PageFilter}>
                        {/* <h4 className="m-r-20"> Search:</h4> */}

                        {/* <!-- START Form Control--> */}
                        <div className={attachedClasses.join(" ")}>
                          <label>Outlet Name</label>
                          <div className={"controls"}>
                            <input
                              type="text"
                              className={classes.FormControl}
                              placeholder="Search by Outlet Name"
                              value={this.state.outletSearchText}
                              onChange={this.filterByOutletName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {userRole === "Administrator" &&
                      userType === "PrepaidPlus" && (
                        <div className={classes.AddUser}>
                          <button
                            className={classes.AddUserBtn}
                            onClick={() =>
                              openModal("CreateOutletModal", merchant.id)
                            }
                          >
                            Add New Outlet
                          </button>
                        </div>
                      )}
                  </header>
                  {outletSearchText.length > 0 &&
                  outletsFiltered.length === 0 ? (
                    <div ref={this.handleContextRef}>
                      <div style={{ paddingBottom: "30px" }}>
                        <h4>
                          {" "}
                          No outlets matched for query "{outletSearchText}"{" "}
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <Outlets
                      outlets={
                        outletsFiltered.length === 0 ? outlets : outletsFiltered
                      }
                      account={merchant.account}
                    />
                  )}
                </div>

                <div label="Limits & Documents">
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h4>Limits & Documents</h4>
                    </div>
                  </header>

                  <Tabs>
                    <div label={"Credit Limit"}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          <h4>Credit Limits</h4>
                        </div>
                        {userRole === "Administrator" &&
                          userType === "PrepaidPlus" && (
                            <div className={classes.AddUser}>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal("CreditLimitModal", {
                                    account: merchant.account,
                                    name: merchant.name
                                  })
                                }
                              >
                                Set New Credit Limit
                              </button>
                            </div>
                          )}
                      </header>
                      <CreditLimit account={merchant.account} />
                    </div>
                    <div label={"Providers"}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          <h4>Manage Providers</h4>
                        </div>
                        {/* {userRole === "Administrator" &&
                          userType === "PrepaidPlus" && (
                            <div className={classes.AddUser}>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal("CreditLimitModal", {
                                    account: merchant.account,
                                    name: merchant.name
                                  })
                                }
                              >
                                Set New Credit Limit
                              </button>
                            </div>
                          )} */}
                      </header>
                      <Providers account={merchant.account} />
                    </div>
                    <div label={`Merchant Agreement`}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          {/* <h4>Merchants</h4> */}
                        </div>
                        {userRole === "Administrator" &&
                          userType === "PrepaidPlus" && (
                            <div className={classes.AddUser}>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal(
                                    "MerchantAgreementModal",
                                    merchant.id
                                  )
                                }
                              >
                                Upload New Agreement
                              </button>
                            </div>
                          )}
                      </header>
                      {commission && (
                        <Fragment>
                          <h5>
                            <strong>Airtime Commission</strong>
                          </h5>

                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem",
                              padding: "10px 0",
                              backgroundColor: "rgba(0,0,255,0.075)"
                            }}
                          >
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  beMobile
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.beMobile.rate} ${
                                    commission[0].airtime.beMobile.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  beMobile
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.mascom.rate} ${
                                    commission[0].airtime.mascom.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>

                              {/* {commission.airtime_mascom} */}
                            </div>
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  beMobile
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.orange.rate} ${
                                    commission[0].airtime.orange.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem"
                            }}
                          >
                            <div
                              className={"col-sm-4"}
                              style={{
                                paddingLeft: "0",
                                paddingRight: "0"
                              }}
                            >
                              <h5 style={{ margin: "0 15px" }}>
                                <strong>Electricity Commission</strong>
                              </h5>
                              <div
                                style={{
                                  marginBottom: "2rem",
                                  padding: "10px 0",
                                  backgroundColor: "rgba(0,0,255,0.075)"
                                }}
                              >
                                <h6
                                  className={
                                    "all-caps small  hint-text semi-bold"
                                  }
                                  style={{ margin: "0 15px" }}
                                >
                                  BPC
                                </h6>
                                <h5 style={{ margin: "0 15px" }}>
                                  {`${commission[0].electricity.bpc.rate} ${
                                    commission[0].electricity.bpc.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div
                              className={"col-sm-4"}
                              style={{
                                paddingLeft: "0",
                                paddingRight: "0"
                              }}
                            >
                              <h5 style={{ margin: "0 15px" }}>
                                <strong>Collections Commission</strong>
                              </h5>
                              <div
                                style={{
                                  marginBottom: "2rem",
                                  padding: "10px 0",
                                  backgroundColor: "rgba(0,0,255,0.075)"
                                }}
                              >
                                <h6
                                  className={
                                    "all-caps smal hint-text semi-bold"
                                  }
                                  style={{ margin: "0 15px" }}
                                >
                                  Botswana Life
                                </h6>
                                <h5 style={{ margin: "0 15px" }}>
                                  {`${commission[0].collections.blil.rate} ${
                                    commission[0].collections.blil.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <h5>
                            <strong>Bill Payments</strong>
                          </h5>
                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem",
                              padding: "10px 0",
                              backgroundColor: "rgba(0,0,255,0.075)"
                            }}
                          >
                            <div className={"col-sm-3"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  BPC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.bpc.rate} ${
                                    commission[0].bills.bpc.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_DSTV} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  BTC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.btc.rate} ${
                                    commission[0].bills.btc.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_WUC} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  DSTV
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.dstv.rate} ${
                                    commission[0].bills.dstv.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_BTCL} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  WUC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.wuc.rate} ${
                                    commission[0].bills.wuc.measument ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className={"row clearfix"}
                            style={{ marginBottom: "3rem" }}
                          >
                            <div className={"col-sm-4"}>
                              <h5>
                                <strong>Commission Frequency</strong>
                              </h5>
                              <h4 className={"no-margin"}>
                                {`${commission[0].frequency} `}
                              </h4>
                              {/* <div>{commission.commission_frequency}</div> */}
                            </div>
                          </div>
                        </Fragment>
                      )}
                      <Documents
                        merchant={merchant}
                        filterByOutletName={this.filterByOutletName}
                      />
                    </div>
                  </Tabs>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   loading: state.async.loading
// });

const actions = {
  openModal
};

const mapStateToProps = state => ({
  outlets: state.firestore.ordered.outlets,
  commission: state.firestore.ordered.commissionRates,
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

export default compose(
  firestoreConnect(props => [
    {
      collection: "merchants",
      storeAs: "outlets",
      doc: props.merchant.account,
      subcollections: [{ collection: "outlets" }]
    },
    {
      collection: "merchant_commission",
      storeAs: "commissionRates",
      where: ["account", "==", props.merchant.account]
    }
  ]),
  connect(
    mapStateToProps,
    actions
  )
)(MerchantProfile);

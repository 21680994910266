import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { login } from "../authActions";
import styles from "./LoginForm.module.css";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

const actions = {
  login
};

const loginContainerClasses = [styles.LoginContainer, styles.FullHeight];
const formGroupClasses = [styles.FormGroup, styles.FormGroupDefault];

const LoginForm = ({ login, handleSubmit, error }) => {
  return (
    <Fragment>
      <div className={"login-container full-height sm-p-t-30"}>
        <div
          className={"d-flex justify-content-center flex-column m-t-50 "}
          style={{ border: "1px solid #e6e6e6" }}
        >
          <div
            className={
              "p-l-50 p-r-50 sm-p-l-15 sm-p-r-15 sm-p-t-40 p-t-50 p-b-50"
            }
            style={{
              background: "#fafafa",
              borderRadius: "0.2rem",
              boxShadow: "0 1px 2px 0 rgba(53,53,53,.1)"
            }}
          >
            <Link to="/">
              <Logo className={styles.Logo} />
            </Link>
            <p className={"p-t-25"} style={{ fontSize: "1.6rem" }}>
              Sign into your PrepaidPlus account
            </p>
            <form
              id="form-login"
              className={"p-t-15"}
              role="form"
              action="index.html"
              noValidate="novalidate"
              onSubmit={handleSubmit(login)}
            >
              <div
                className={"form-group form-group-default"}
                style={{
                  paddingTop: "0.7rem",
                  paddingBottom: "0.4rem",
                  borderColor: "rgba(0, 0, 0, 0.07)"
                }}
              >
                <label>Login</label>
                <div className={"controls"}>
                  <Field
                    name="email"
                    component="input"
                    type="text"
                    className={"form-control"}
                    style={{
                      height: "2.5rem",
                      minHeight: "2.5rem",
                      marginTop: "-0.4rem",
                      width: "100%"
                    }}
                    placeholder="User Name"
                    required=""
                    aria-required="true"
                  />
                </div>
              </div>

              <div
                className={"form-group form-group-default"}
                style={{
                  paddingTop: "0.7rem",
                  paddingBottom: "0.4rem",
                  borderColor: "rgba(0, 0, 0, 0.07)"
                }}
              >
                <label>Password</label>
                <div className={"controls"}>
                  <Field
                    component="input"
                    type="password"
                    className={"form-control"}
                    name="password"
                    placeholder="Credentials"
                    required=""
                    aria-required="true"
                    style={{
                      height: "2.5rem",
                      minHeight: "2.5rem",
                      marginTop: "-0.4rem"
                    }}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"col-md-6 no-padding sm-p-l-10"}>
                  <div className={"checkbox "}>
                    <input type="checkbox" value="1" id="checkbox1" />
                    <label htmlFor="checkbox1">Keep Me Signed in</label>
                  </div>
                </div>
                <div
                  className={
                    "col-md-6 d-flex align-items-center justify-content-end"
                  }
                >
                  <a href="#" className={"text-info small"}>
                    Help? Contact Support
                  </a>
                </div>
              </div>
              {error && <label className={"error semi-bold"}>{error}</label>}
              <button
                className={"btn btn-primary btn-cons m-t-10"}
                type="submit"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

//export default Login;
export default connect(
  null,
  actions
)(reduxForm({ form: "loginForm" })(LoginForm));

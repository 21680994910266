const validate = values => {
  const errors = {};
  if (!values.manufacturer) {
    errors.manufacturer = "Required";
  }

  if (!values.model) {
    errors.model = "Required";
  }

  if (!values.imei) {
    errors.imei = "Required";
  }

  if (!values.macAddress) {
    errors.macAddress = "Required";
  }
  if (!values.wifiAddress) {
    errors.wifiAddress = "Required";
  }
  if (!values.serialNumber) {
    errors.serialNumber = "Required";
  }

  return errors;
};

export default validate;

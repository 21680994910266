import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import {fetchMerchantTransactionsList} from '../../../../app/data/firebaseAPI'
import classes from "./index.module.css";
import Tabs from "../../../app/UI/Tabs/Tabs";

// import ItemisedReport from "./Reports/Itemised";

// {merchant,merchantTransactions}

class index extends Component {
  render() {
    const { account } = this.props;
    // const { merchant, merchantTransactions,loading } = this.props;
    //     const merchantsLink =  `/merchants`
    // const selectedMerchantLink =  `/merchants/${merchant.id}`

    const itemisedReportLink = `/reports/itemised`;
    const categoryReportLink = `/reports/category`;
    const levyReportLink = `/reports/levy`;
    const dateReportLink = `/reports/date`;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Reports</span>

              {/* <span className={classes.Header}><Link to={merchantsLink}>Merchants</Link>  -  <Link to={selectedMerchantLink}>{merchant.name}</Link>  -  Reports</span> */}
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Reports</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          <div className={classes.ReportContainer}>
                            <div
                              className={classes.ReportTiles}
                              style={{
                                maxWidth: "100%",
                                opacity: "1"
                              }}
                            >
                              <div className={"md-tile-container"}>
                                <Link
                                  className={classes.Tile}
                                  to={itemisedReportLink}
                                >
                                  <div className={classes.TileInner}>
                                    <div class={classes.TileIcon}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="48"
                                        width="48"
                                        viewBox="0 0 48 48"
                                      >
                                        <g>
                                          {/* <ellipse
                                                cy="24"
                                                cx="24"
                                                ry="24"
                                                rx="24"
                                                fill="#F5F7F9"
                                              /> */}
                                          <path
                                            id="path1"
                                            transform="rotate(0,24,24) translate(14.2499290130852,12) scale(0.749976576106198,0.749976576106198)  "
                                            fill="#707070"
                                            d="M2.0009785,1.9999937L2.0009785,29.999906 6.0009826,29.999906 6.0009826,9.9999693 12.000988,9.9999693 12.000988,29.999906 14.00099,29.999906 14.00099,5.9999812 20.000996,5.9999812 20.000996,29.999906 24.001,29.999906 24.001,1.9999937z M2.6669948,0L23.334008,0C24.806053,0,26.001002,1.194088,26.001002,2.667106L26.001002,29.334015C26.001002,30.805935,24.806053,32.000999,23.334008,32.000999L2.6669948,32.000999C1.1949475,32.000999,6.2229446E-08,30.805935,0,29.334015L0,2.667106C6.2229446E-08,1.1929894,1.1949475,0,2.6669948,0z"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <div class={classes.TileText}>
                                      Itemised Report
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className={"md-tile-container"}>
                                <Link
                                  className={classes.Tile}
                                  to={categoryReportLink}
                                >
                                  <div className={classes.TileInner}>
                                    <div class={classes.TileIcon}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="48"
                                        width="48"
                                        viewBox="0 0 48 48"
                                      >
                                        <g>
                                          {/* <ellipse
                                                cy="24"
                                                cx="24"
                                                ry="24"
                                                rx="24"
                                                fill="#F5F7F9"
                                              /> */}
                                          <path
                                            id="path1"
                                            transform="rotate(0,24,24) translate(14.2499290130852,12) scale(0.749976576106198,0.749976576106198)  "
                                            fill="#707070"
                                            d="M2.0009785,1.9999937L2.0009785,29.999906 6.0009826,29.999906 6.0009826,9.9999693 12.000988,9.9999693 12.000988,29.999906 14.00099,29.999906 14.00099,5.9999812 20.000996,5.9999812 20.000996,29.999906 24.001,29.999906 24.001,1.9999937z M2.6669948,0L23.334008,0C24.806053,0,26.001002,1.194088,26.001002,2.667106L26.001002,29.334015C26.001002,30.805935,24.806053,32.000999,23.334008,32.000999L2.6669948,32.000999C1.1949475,32.000999,6.2229446E-08,30.805935,0,29.334015L0,2.667106C6.2229446E-08,1.1929894,1.1949475,0,2.6669948,0z"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <div class={classes.TileText}>
                                      Sales By Category
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className={"md-tile-container"}>
                                <Link
                                  className={classes.Tile}
                                  to={dateReportLink}
                                >
                                  <div className={classes.TileInner}>
                                    <div class={classes.TileIcon}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="48"
                                        width="48"
                                        viewBox="0 0 48 48"
                                      >
                                        <g>
                                          {/* <ellipse
                                                cy="24"
                                                cx="24"
                                                ry="24"
                                                rx="24"
                                                fill="#F5F7F9"
                                              /> */}
                                          <path
                                            id="path1"
                                            transform="rotate(0,24,24) translate(14.2499290130852,12) scale(0.749976576106198,0.749976576106198)  "
                                            fill="#707070"
                                            d="M2.0009785,1.9999937L2.0009785,29.999906 6.0009826,29.999906 6.0009826,9.9999693 12.000988,9.9999693 12.000988,29.999906 14.00099,29.999906 14.00099,5.9999812 20.000996,5.9999812 20.000996,29.999906 24.001,29.999906 24.001,1.9999937z M2.6669948,0L23.334008,0C24.806053,0,26.001002,1.194088,26.001002,2.667106L26.001002,29.334015C26.001002,30.805935,24.806053,32.000999,23.334008,32.000999L2.6669948,32.000999C1.1949475,32.000999,6.2229446E-08,30.805935,0,29.334015L0,2.667106C6.2229446E-08,1.1929894,1.1949475,0,2.6669948,0z"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <div class={classes.TileText}>
                                      Sales By Date
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className={"md-tile-container"}>
                                <Link
                                  className={classes.Tile}
                                  to={levyReportLink}
                                >
                                  <div className={classes.TileInner}>
                                    <div class={classes.TileIcon}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="48"
                                        width="48"
                                        viewBox="0 0 48 48"
                                      >
                                        <g>
                                          {/* <ellipse
                                                cy="24"
                                                cx="24"
                                                ry="24"
                                                rx="24"
                                                fill="#F5F7F9"
                                              /> */}
                                          <path
                                            id="path1"
                                            transform="rotate(0,24,24) translate(14.2499290130852,12) scale(0.749976576106198,0.749976576106198)  "
                                            fill="#707070"
                                            d="M2.0009785,1.9999937L2.0009785,29.999906 6.0009826,29.999906 6.0009826,9.9999693 12.000988,9.9999693 12.000988,29.999906 14.00099,29.999906 14.00099,5.9999812 20.000996,5.9999812 20.000996,29.999906 24.001,29.999906 24.001,1.9999937z M2.6669948,0L23.334008,0C24.806053,0,26.001002,1.194088,26.001002,2.667106L26.001002,29.334015C26.001002,30.805935,24.806053,32.000999,23.334008,32.000999L2.6669948,32.000999C1.1949475,32.000999,6.2229446E-08,30.805935,0,29.334015L0,2.667106C6.2229446E-08,1.1929894,1.1949475,0,2.6669948,0z"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <div class={classes.TileText}>
                                      Government Levy Report
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* <Tabs>
                            <div label="Outlet">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>Outlet Reports</h4>
                                </div>
                              </header>

                           </div>
                           
                          </Tabs> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const account = ownProps.match.params.account;

  return {
    account
  };
};

export default connect(mapStateToProps)(index);

// export default index;

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import TextInput from "../../app/common/form/TextInput";
import SelectInput from "../../app/common/form/SelectInput";

import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthGreaterThan
} from "revalidate";
import classes from "./EnableProvider.module.css";
import { changePortalUserPassword } from "../merchants/merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    hasLengthGreaterThan(5)({
      message: "Must be 6 characters or longer"
    })
  )()
});

const enableProvider = props => {
  const {
    provider,
    changePortalUserPassword,
    handleSubmit,
    closePanel
  } = props;

  const onFormSubmit = values => {
    changePortalUserPassword(values, provider.email, provider.account);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Type:
              <span className={"semi-bold"}>{provider.type}</span>
            </h5>
            <h5>
              Provider:
              <span className={"semi-bold"}>{provider.provider}</span>
            </h5>
            <br />

            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Enable Provider</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  changePortalUserPassword
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableProviderForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(enableProvider)
);

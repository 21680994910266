import { closeModal } from "../modals/modalActions";
import { toastr } from "react-redux-toastr";

import firebase from "../../app/config/firebase";

import { createSIMInventoryObject } from "../../app/common/util/helpers";
import {
  ADD_COMMISSION_RATE,
  FETCH_COMMISSION_RATES
} from "./settingsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import { fetchSampleCommissionData } from "../../app/data/mockAPI";

export const fetchCommissionRates = rates => {
  return {
    type: FETCH_COMMISSION_RATES,
    payload: rates
  };
};

export const addCommissionRate = rate => {
  //   return async (dispatch, getState, { getFirebase, getFirestore }) => {
  //     const firebase = getFirebase();
  //     const firestore = getFirestore();
  //     const user = firebase.auth().currentUser.displayName;
  //     let newInventoryItem = createDeviceInventoryObject(inventoryItem, user);
  //     try {
  //       let createdInventoryItem = await firestore.add(
  //         `inventory`,
  //         newInventoryItem
  //       );
  //       dispatch(closeModal());
  //       toastr.success("Success", "InventoryItem has been created");
  //     } catch (error) {
  //       console.log("Oops", "Something went wrong");
  //       toastr.error("Oops", "Something went wrong");
  //     }
  //   };
};

export const setCommissionRateActive = rate => {
  //   return async (dispatch, getState, { getFirebase, getFirestore }) => {
  //     const firebase = getFirebase();
  //     const firestore = getFirestore();
  //     const user = firebase.auth().currentUser.displayName;
  //     let newInventoryItem = createDeviceInventoryObject(inventoryItem, user);
  //     try {
  //       let createdInventoryItem = await firestore.add(
  //         `inventory`,
  //         newInventoryItem
  //       );
  //       dispatch(closeModal());
  //       toastr.success("Success", "InventoryItem has been created");
  //     } catch (error) {
  //       console.log("Oops", "Something went wrong");
  //       toastr.error("Oops", "Something went wrong");
  //     }
  //   };
};

export const createSeller = seller => {};

export const loadCommissions = () => {
  return async dispatch => {
    try {
      dispatch(asyncActionStart());
      let commissionRates = await fetchSampleCommissionData();
      dispatch(fetchCommissionRates(commissionRates));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  };
};

// export const loadMembers = () => {
//   return async dispatch => {
//     try {
//       dispatch(asyncActionStart());
//       let members = await fetchSampleData();
//       dispatch(fetchMembers(members));
//       dispatch(asyncActionFinish());
//     } catch (error) {
//         dispatch(asyncActionError());
//     }
//   };
// };

import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";

import SelectInput from "../../../../../../../app/common/form/SelectInput";
import validate from "./validate";

import { updateOutletField } from "../../../../../merchantActions";

const editOutletType = props => {
  const {
    name,
    account,
    handleSubmit,
    outletId,
    closePanel,
    updateOutletField,
    outletTypes
  } = props;
  const onFormSubmit = values => {
    let type = "OutletType";
    updateOutletField(values, account, outletId, type);
    closePanel();
  };

  let types = {};

  types =
    outletTypes &&
    outletTypes.map(outletType => {
      return {
        label: outletType.outletType,
        value: outletType.outletType
      };
    });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="outletType"
            options={types}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "neutral0"
              }
            })}
            component={SelectInput}
            placeholder="Outlet Types..."
            className={"form-control"}
            required="true"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons"}
              type="submit"
              // onClick={handleNextClick}
            >
              <span>Update Outlet Type</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const query = [
  {
    collection: "outletTypes",
    orderBy: ["outletType", "desc"]
  }
];

const actions = {
  updateOutletField
};

const mapStateToProps = state => ({
  outletTypes: state.firestore.ordered.outletTypes,
  enableReinitialize: true
});

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "editOutletFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  firestoreConnect(query)
)(editOutletType);

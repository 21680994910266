import React, { Component, Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Layout from "./Layout";

import Merchants from "../../features/merchants/Merchants";
import Merchant from "../../features/merchants/Merchant/Merchant";
// import MerchantReports from "../../features/reports";
// import DepositDashboard from "../../features/deposits";
import Financials from "../../features/financials";

import Users from "../../features/users/Users";
import Login from "../../features/auth/Login/LoginForm";
import Register from "../../features/auth/Register/RegisterForm";
import Outlet from "../../features/merchants/Merchant/MerchantProfile/Outlets/Outlet/Outlet";
import Inventory from "../../features/inventory/Inventory";
import { history } from "../common/util/history";
import Authorisation from "../../features/authorisation";
import Settings from "../../features/settings";
import MerchantReports from "../../features/reports/Merchant";
import CachetReports from "../../features/reports/Cachet/";
import MerchantItemisedReport from "../../features/reports/Merchant/Reports/ItemisedReport";
import MerchantCategoryReport from "../../features/reports/Merchant/Category";
import MerchantUserReport from "../../features/reports/Merchant/User";
import MerchantDateReport from "../../features/reports/Merchant/Date";

import MerchantGroupItemisedReport from "../../features/reports/Group/Itemised/ItemisedReport";
import MerchantGroupCategoryReport from "../../features/reports/Group/Category";
import MerchantGroupDateReport from "../../features/reports/Group/Date";

import CachetItemisedReport from "../../features/reports/Cachet/Itemised/ItemisedReport";
import CachetCategoryReport from "../../features/reports/Cachet/Category";
import CachetDateReport from "../../features/reports/Cachet/Date";
import CachetLevyReport from "../../features/reports/Cachet/Levy";

import CachetFinancials from "../../features/financials/Cachet/";
import Transactions from "../../features/transactions";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route
              path="/(.+)"
              render={() => (
                <Layout>
                  <Switch>
                    <Route path="/merchants" exact component={Merchants} />
                    <Route path="/merchants/:id" exact component={Merchant} />
                    <Route
                      path="/merchants/:account/outlet/:outletId"
                      component={Outlet}
                    />
                    <Route
                      path="/merchants/:account/financials"
                      component={Financials}
                    />
                    <Route
                      path="/merchants/:account/reports"
                      exact
                      component={MerchantReports}
                    />
                    <Route
                      path="/merchants/:account/reports/itemised"
                      component={MerchantItemisedReport}
                    />
                    <Route
                      path="/merchants/:account/reports/category"
                      component={MerchantCategoryReport}
                    />
                    <Route
                      path="/merchants/:account/reports/user"
                      component={MerchantUserReport}
                    />
                    <Route
                      path="/merchants/:account/reports/date"
                      component={MerchantDateReport}
                    />
                    <Route
                      exact
                      path="/merchants/:account/reports/group/category"
                      component={MerchantGroupCategoryReport}
                    />
                    <Route
                      exact
                      path="/merchants/:account/reports/group/itemised"
                      component={MerchantGroupItemisedReport}
                    />
                    <Route
                      exact
                      path="/merchants/:account/reports/group/date"
                      component={MerchantGroupDateReport}
                    />{" "}
                    <Route path="/reports" exact component={CachetReports} />
                    <Route
                      exact
                      path="/reports/itemised"
                      component={CachetItemisedReport}
                    />
                    <Route
                      exact
                      path="/reports/category"
                      component={CachetCategoryReport}
                    />
                    <Route
                      exact
                      path="/reports/date"
                      component={CachetDateReport}
                    />
                    <Route
                      exact
                      path="/reports/levy"
                      component={CachetLevyReport}
                    />
                    <Route
                      path="/transactions"
                      exact
                      component={Transactions}
                    />
                    <Route
                      path="/financials"
                      exact
                      component={CachetFinancials}
                    />
                    <Route path="/users" component={Users} />
                    <Route path="/inventory" component={Inventory} />
                    <Route path="/authorisation" component={Authorisation} />
                    <Route path="/settings" component={Settings} />
                  </Switch>
                </Layout>
              )}
            />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;

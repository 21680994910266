import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { registerUser } from "../authActions";

import TextInput from "../../../app/common/form/TextInput";
import AsyncTextInput from "../../../app/common/form/AsyncTextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import validate from "./validate";
import asyncValidate from "./asyncValidate";

const registerSytemUserForm = ({
  registerUser,
  error,
  handleSubmit,
  userRole,
  merchant
}) => {
  let userRoles;
  let outlets;

  outlets =
    merchant &&
    Object.values(merchant.outlets).map(outlet => {
      return {
        label: outlet.name,
        value: outlet.id
      };
    });

  userRoles =
    userRole &&
    userRole.map(role => {
      return {
        label: role.role,
        value: role.role
      };
    });

  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        <form className={"p-t-15"} onSubmit={handleSubmit(registerUser)}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>First Name</label>
              <Field
                type="text"
                name="firstName"
                component={TextInput}
                placeholder="First Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
            <div className={"col-md-6"}>
              <label>Last Names</label>
              <Field
                type="text"
                name="lastName"
                component={TextInput}
                placeholder="Last Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>User Role</label>
              <Field
                name="role"
                options={userRoles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0"
                  }
                })}
                component={SelectInput}
                placeholder="Select Role..."
                className={"form-control"}
                required="true"
                aria-required="true"
              />
            </div>
          </div>
          <p />
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Email</label>
              <Field
                type="email"
                name="email"
                component={AsyncTextInput}
                placeholder="E-mail"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Password</label>
              <Field
                type="password"
                name="password"
                component={TextInput}
                placeholder="Minimum of 4 Charactors"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <br />

          {error && <label className={"error semi-bold"}>{error}</label>}

          <button className={"btn btn-primary btn-cons m-t-10"}>
            Register a new user
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userRole: state.firestore.ordered.userRole,
  initialValues: {
    userType: "PrepaidPlus",
    outletId: "PrepaidPlus",
    account: "PrepaidPlus"
  },
  enableReinitialize: true
});

let query = [
  {
    collection: "userRole",
    where: ["roleScope", "==", "PrepaidPlus"]
  }
];

const actions = {
  registerUser
};

// export default connect(
//   mapStateToProps,
//   actions
// )(reduxForm({ form: "registerForm", validate })(RegisterForm));

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerSytemUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["email"],
    validate
  }),
  firestoreConnect(query)
)(registerSytemUserForm);

// where: ["roleScope", "==", "Merchant"]

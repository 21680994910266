import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
    // apiKey: "AIzaSyDxM1b5QyYfgJ-6jh5E2sSQosSuiG5G5Ok",
    // authDomain: "cachet-tym2sell-api.firebaseapp.com",
    // databaseURL: "https://cachet-tym2sell-api.firebaseio.com",
    // projectId: "cachet-tym2sell-api",
    // storageBucket: "cachet-tym2sell-api.appspot.com",
    // messagingSenderId: "927922000167"

    apiKey: "AIzaSyDjNhwUfWxWPNeFIxssaupvidf4v7MahkY",
    authDomain: "cachet-tym2sell.firebaseapp.com",
    databaseURL: "https://cachet-tym2sell.firebaseio.com",
    projectId: "cachet-tym2sell",
    storageBucket: "cachet-tym2sell.appspot.com",
    messagingSenderId: "636560698876"
}

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage().ref()
const firestore = firebase.firestore();
const settings = {
    timestampsInSnapshots: true
}
firestore.settings(settings);

export default firebase;
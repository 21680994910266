import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SELECT_USER,
  FETCH_USERS
} from "./usersConstants";

const initialState = [];

export const createUser = (state, payload) => {
  return [...state, Object.assign({}, payload.user)];
};

export const updateUser = (state, payload) => {
  return [
    ...state.filter(user => user.id !== payload.user.id),
    Object.assign({}, payload.user)
  ];
};

export const deleteUser = (state, payload) => {
  return [...state.filter(user => user.id !== payload.userId)];
};

export const selectUser = (selectedUser = null, action) => {
  if (action.type === "SELECT_USER") {
    return action.payload;
  }
  return selectedUser;
};

export const fetchUsers = (state, payload) => {
  console.log("payload", payload);
  return payload.users;
};

export default createReducer(initialState, {
  [CREATE_USER]: createUser,
  [UPDATE_USER]: updateUser,
  [DELETE_USER]: deleteUser,
  [SELECT_USER]: selectUser,
  [FETCH_USERS]: fetchUsers
});

import React, { Fragment, Component } from "react";
import LoadingComponent from "../../../../../../../app/layout/LoadingComponent";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import classes from "./GeneratePairingCode.module.css";
import { unassignDeviceFromMerchant } from "../../../../../merchantActions";

const unassignDevice = props => {
  const {
    handleSubmit,
    outlet,
    inventory,
    merchantName,
    closePanel,
    device,
    unassignDeviceFromMerchant
  } = props;

  let pairingCode;

  const onFormSubmit = values => {
    unassignDeviceFromMerchant(inventory[0].id, device.id, device.cellNumber);

    closePanel();
  };

  return (
    <div label="UnassignDevice">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Assigned to:{" "}
              <span className={"semi-bold"}>
                {inventory && inventory[0].allocatedTo}
              </span>
            </h5>

            <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={"btn btn-primary btn-cons"}
                    type="submit"
                    // onClick={handleNextClick}
                  >
                    <span>Unassign Device</span>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  unassignDeviceFromMerchant
};

const mapStateToProps = state => ({
  inventory: state.firestore.ordered.inventory
});

export default compose(
  firestoreConnect(props => [
    {
      collection: "inventory",
      where: ["serialNumber", "==", props.device.serialNumber]
    }
  ]),
  connect(
    mapStateToProps,
    actions
  )
)(
  reduxForm({
    form: "unAssignDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(unassignDevice)
);

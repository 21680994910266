import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import toMomentObject from "../../app/common/util/dateFormat";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { createTextMask } from "redux-form-input-masks";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";

import { moneyFormat } from "../../app/common/util/moneyFormat";

import { getTransactionsForDashboard } from "./transactionsActions";
import TextInput from "../../app/common/form/TextInput";
import classes from "./Transactions.module.css";
import LoadingComponent from "../../app/layout/LoadingComponent";
import SelectedTransaction from "./SelectedTransaction";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const validate = combineValidators({
  transactionStartDate: isRequired("transactionStartDate"),
  transactionEndDate: isRequired("transactionEndDate"),
  outletId: isRequired("outletId")
});

class Transactions extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    startDate: toMomentObject(new Date()),
    endDate: toMomentObject(new Date()),
    today: toMomentObject(new Date()),
    csvData: [],
    transactionDetailPanelOpen: false,
    selectedTransaction: null,
    selectedRow: -1
  };

  async componentDidMount() {
    await this.props.getTransactionsForDashboard({
      transactionStartDate: new Date(
        this.props.initialValues.transactionStartDate
      ),
      transactionEndDate: new Date(this.props.initialValues.transactionEndDate),
      outletId: this.props.initialValues.outletId
    });

    this.setState({ loadingInitial: false });
  }

  componentWillUnmount() {}

  transactionRowClasses = null;
  transactionDetailPanelOpenClasses = [classes.RevealMore];

  handleDeviceClick = (transaction, index) => {
    console.log("transaction", transaction);
    this.setState({
      transactionDetailPanelOpen: true,
      selectedTransaction: transaction,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    this.setState({
      transactionDetailPanelOpen: false,
      selectedTransaction: null,
      selectedRow: -1
    });
  };

  transactionDetail = transaction => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.saleType + " - " + transaction.provider
          : transaction.saleType + " - " + transaction.transactionDetail;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank + " - " + transaction.depositMethod
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType + " - " + transaction.reversalReference;
        break;
      case "Withdrawal":
        return (
          transaction.withdrawalType + " - " + transaction.withdrawalDetail
        );
        break;
      default:
        return transaction.saleType + " - " + transaction.transactionDetail;
    }
  };

  render() {
    const {
      handleSubmit,
      getTransactionsForDashboard,
      transactions,
      requesting,
      outletId
    } = this.props;
    const loading = requesting[`merchants`];

    let {
      transactionDetailPanelOpen,
      selectedTransaction,
      selectedRow
    } = this.state;

    !selectedRow
      ? (this.transactionRowClasses = [classes.MerchantRow])
      : (this.transactionRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction
        ]);

    const onFormSubmit = async values => {
      const outletId = values.outletId;
      let transactionStartDate = new Date(this.state.startDate);
      transactionStartDate.setHours(0, 0, 0, 0);
      let transactionEndDate = new Date(this.state.endDate);
      transactionEndDate.setHours(23, 59, 59, 999);

      console.log(
        "onFormSubmit",
        outletId,
        transactionStartDate,
        transactionEndDate
      );

      this.setState({ loadingInitial: true });
      await getTransactionsForDashboard({
        transactionStartDate: transactionStartDate,
        transactionEndDate: transactionEndDate,
        outletId: outletId
      });
      this.setState({ loadingInitial: false });
    };

    let transactionDataSet;

    let transactionList = (
      <Fragment>
        <LoadingComponent inverted={true} style={{ width: "100%" }} />
      </Fragment>
    );

    let group;
    let outletName;

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (!this.state.loadingInitial) {
      let salesTotal = transactions.reduce(function(accumulator, transaction) {
        return accumulator + transaction.transactionAmount;
      }, 0);

      let firstrow =
        transactions.length > 0
          ? transactions[0]
          : { group: "None", outletName: "None" };

      group = firstrow.group;
      outletName = firstrow.outletName;

      // let salesTotal = 0;

      transactions.length > 0
        ? (transactionList = (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Time</th>
                  <th scope="col">Outlet ID</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Transaction Detail</th>
                  <th scope="col">Transaction Value</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => this.handleDeviceClick(transaction, index)}
                      className={
                        this.state.selectedRow === index
                          ? classes.SelectedTransaction
                          : classes.MerchantRow
                      }
                    >
                      <td>
                        {format(
                          transaction.transactionDateTime.toDate(),
                          "YYYY-MM-DD  HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {transaction.outletId
                          ? transaction.outletId
                          : transaction.account}
                      </td>
                      <td>{transaction.transactionType}</td>
                      <td>{this.transactionDetail(transaction)}</td>
                      <td>{moneyFormat(transaction.transactionAmount)}</td>
                      {/* <td>{transaction.transactionValue2}</td> */}
                    </tr>
                  );
                })}
                <tr className={classes.Totals}>
                  <td colSpan="4">
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {moneyFormat(salesTotal)}
                  </td>
                  {/* <td className={classes.MoneyTotal}>{salesTotal2}</td> */}
                </tr>
              </tbody>
            </table>
          ))
        : (transactionList = (
            <h4>Selected Outlet has no transactions for selected period.</h4>
          ));

      let transactionData = transactions.map(transaction => {
        return [
          {
            value: format(
              transaction.transactionDateTime.toDate(),
              "YYYY-MM-DD  HH:mm:ss"
            ),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: transaction.outletId
              ? transaction.outletId
              : transaction.account,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: transaction.transactionType,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: this.transactionDetail(transaction),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: moneyFormat(transaction.transactionAmount),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      transactionData.push([
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "Total",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true }
          }
        }
      ]);

      let transactionDate = `${
        this.props.initialValues.transactionStartDate
      } to ${this.props.initialValues.transactionStartDate}`;
      let transactionStartDate = `Start Date: ${
        this.props.initialValues.transactionStartDate
      }`;
      let transactionEndDate = `End Date: ${
        this.props.initialValues.transactionEndDate
      }`;
      // Period: 01 Nov 2018 To 30 Nov 2018

      // {value: "Bold", style: {font: {bold: true}}},

      transactionDataSet = [
        {
          columns: [
            { title: "Headings", width: { wpx: 80 } }, //pixels width
            { title: "Text Style", width: { wch: 40 } }, //char width
            { title: "Colors", width: { wpx: 90 } }
          ],
          data: [
            [
              { value: "H1", style: { font: { sz: "24", bold: true } } },
              { value: "Bold", style: { font: { bold: true } } },
              {
                value: "Red",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
                }
              }
            ]
          ]
        },
        {
          ySteps: 1,
          columns: [
            {
              title: "",
              width: { wpx: 890 },
              style: {
                font: { sz: "18", bold: true }
              }
            },
            { title: "", width: { wpx: 10 } }
          ],
          data: [[group, outletName]]
        },
        {
          ySteps: 1,

          columns: [
            { title: "", width: { wpx: 10 } },
            { title: "", width: { wpx: 10 } }
          ],
          data: [
            [
              {
                value: transactionStartDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              },
              {
                value: transactionEndDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              }
            ]
          ]
        },

        {
          // xSteps: 1, // Will start putting cell with 1 empty cell on left most
          ySteps: 1,
          columns: [
            {
              title: "Store Name",
              width: { wpx: 150 },
              style: {
                font: { sz: "16", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "User Name",
              width: { wpx: 150 },
              style: { font: { bold: true } }
            },
            {
              title: "Date Time",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Sale Type",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Transaction Detail",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Sale Value",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            }
          ],
          data: transactionData
        }
      ];
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <Field
                      type="text"
                      className={"form-control"}
                      component={TextInput}
                      placeholder="Outlet Id (ie 1000-01)"
                      name="outletId"
                      id="outletId"
                      required="required"
                      autoComplete="off"
                    />
                  </div>

                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <DateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={date => date.isAfter(this.state.today)}
                        displayFormat={"YYYY-MM-DD"}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />

                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
              <div className={classes.ToolsPrintIconWrapper}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,0.00300121307373047) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M8.0009928,24.949988L24.000992,24.949988 24.000992,25.949988 8.0009928,25.949988z M8.0009928,22.034003L24.000992,22.034003 24.000992,23.034003 8.0009928,23.034003z M8.0839996,18.991996L24.084,18.991996 24.084,19.991996 8.0839996,19.991996z M6.0009928,17.006983L6.0009928,29.991994 26.000992,29.991994 26.000992,23.011647 26,22.991996 26,17.006983z M2,6.9919958L2,21.991996 4,21.991996 4,15.991996C4,15.439017,4.447998,14.991996,5,14.991996L27,14.991996C27.517502,14.991996,27.943594,15.384886,27.994835,15.889638L27.995474,15.902311 27.995827,15.90462C27.999241,15.938271,28.000992,15.97242,28.000992,16.006982L28.000992,21.991996 30,21.991996 30,6.9919958z M6.0009928,1.9999976L6.0009928,4.983994 26.000992,4.983994 26.000992,1.9999976z M5.0009928,0L27.000992,0C27.552992,0,28.000992,0.44699955,28.000992,0.99999905L28.000992,4.9919958 31,4.9919958C31.552002,4.9919958,32,5.4390173,32,5.9919958L32,22.991996C32,23.544974,31.552002,23.991996,31,23.991996L28.000992,23.991996 28.000992,30.991996C28.000992,31.544995,27.552992,31.991996,27.000992,31.991996L5.0009928,31.991996C4.4479923,31.991996,4.0009928,31.544995,4.0009928,30.991996L4.0009928,23.991996 1,23.991996C0.44799805,23.991996,0,23.544974,0,22.991996L0,5.9919958C0,5.4390173,0.44799805,4.9919958,1,4.9919958L4.0009928,4.9919958 4.0009928,0.99999905C4.0009928,0.44699955,4.4479923,0,5.0009928,0z"
                    />
                  </g>
                </svg>
              </div>
              <div className={classes.ToolsDataExportIconWrapper}>
                <ExcelFile
                  name="Transactions"
                  element={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path
                          id="path1"
                          transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                          fill="#707070"
                          d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                        />
                      </g>
                    </svg>
                  }
                >
                  <ExcelSheet
                    dataSet={transactionDataSet}
                    name="Transactions"
                  />
                </ExcelFile>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            {transactionList}
            {transactionDetailPanelOpen ? (
              <div className={classes.TransactionDetail}>
                <div
                  className={"card card-transparent"}
                  style={{ paddingLeft: "1.5rem" }}
                >
                  <div
                    className={"card-header "}
                    style={{
                      minHeight: "3.7rem",
                      padding: "0.8rem",
                      color: "#666",
                      fontSize: "1.4rem",
                      fontFamily: "Roboto",
                      fontWeight: "500"
                    }}
                  >
                    <div
                      className={"card-title semi-bold"}
                      style={{
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        textTransform: "none",
                        marginLeft: "0.7rem"
                      }}
                    >
                      Transaction Detail
                    </div>
                    <div className={"card-controls"}>
                      <ul>
                        <li>
                          <a
                            data-toggle="close"
                            className={"card-close"}
                            href="#"
                            onClick={this.handleCloseClick}
                          >
                            <i className={"card-icon card-icon-close"} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={"card-body"} style={{ padding: "0 1.5rem" }}>
                    <Fragment>
                      <div className={"row column-seperation"}>
                        <div className={"col-md-12"}>
                          <SelectedTransaction
                            transaction={selectedTransaction}
                            closePanel={this.handleCloseClick}
                          />
                        </div>
                      </div>
                    </Fragment>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  getTransactionsForDashboard
};

// const interval = 1000 * 60 * 60 * 24; // 24 hours in milliseconds

// let startOfDay = Math.floor(Date.now() / interval) * interval;
// let endOfDay = startOfDay + interval - 1; // 23:59:59:9999

const mapStateToProps = (state, interval = 1000 * 60 * 60 * 24) => ({
  transactions: state.transactions,
  requesting: state.firestore.status.requesting,
  initialValues: {
    outletId: "",
    transactionStartDate: moment().format("YYYY-MM-DD 00:00:01"),
    transactionEndDate: moment().format("YYYY-MM-DD 23:59:59")
  },
  enableReinitialize: true
});


export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Transactions)
);


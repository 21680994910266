import React, { Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { combineValidators, composeValidators, isRequired } from "revalidate";
import classes from "./SelectedDevice.module.css";
import { unassignSIMFromDevice } from "../../inventoryActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  reversal_reason: composeValidators(customIsRequired)()
});

const selectedDevice = props => {
  const { device, unassignSIMFromDevice, handleSubmit, closePanel } = props;

  console.log("device", device);

  const onFormSubmit = values => {
    unassignSIMFromDevice(device.id, device.cellNumber);
    closePanel();
  };

  return (
    <div label="Transactions">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className={classes.Header}>
              <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-start", display: "flex" }}
                >
                  <strong>Model:</strong> {"\u00A0"}
                  {device.model}
                </div>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <strong>Model:</strong> {"\u00A0"}
                  {device.model}
                </div>
              </div>
            </div>
            {device.cellNumber && !device.isAllocated ? (
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={"btn btn-primary btn-cons"}
                      type="submit"
                    >
                      <span>Remove Assigned Cellphone</span>
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <strong>Note:</strong> {"\u00A0"}
                <span className={classes.Note}>
                  Devices must be un-allocated from merchants outlets before
                  cellphone numbers are unassigned from devices
                </span>
              </div>
            )}
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  unassignSIMFromDevice
};

// const mapStateToProps = state => ({
//   reversalTransaction: state.firestore.ordered.reversalTransaction,
//   enableReinitialize: true
// });

export default connect(
  null,
  actions
)(
  reduxForm({
    form: "selectedDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(selectedDevice)
);

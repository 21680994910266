import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";

import SelectInput from "../../../../../app/common/form/SelectInput";
import validate from "./validate";

import { updateMerchantField } from "../../../merchantActions";

const editSellerDetails = props => {
  const {
    name,
    account,
    handleSubmit,
    closePanel,
    updateMerchantField,
    sellerDetails
  } = props;
  const onFormSubmit = values => {
    let type = "SellerDetails";
    updateMerchantField(values, account, type);
    closePanel();
  };

  let sellers = {};

  sellers =
    sellerDetails &&
    sellerDetails.map(seller => {
      return {
        label: seller.sellerId,
        value: seller.sellerId
      };
    });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="merchantSellerId"
            options={sellers}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "neutral0"
              }
            })}
            component={SelectInput}
            placeholder="Select Seller Id..."
            className={"form-control"}
            required="true"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons"}
              type="submit"
              // onClick={handleNextClick}
            >
              <span>Update Seller Details</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const query = [
  {
    collection: "sellerId",
    orderBy: ["sellerId", "desc"]
  }
];

const actions = {
  updateMerchantField
};

const mapStateToProps = state => ({
  sellerDetails: state.firestore.ordered.sellerId,
  enableReinitialize: true
});

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "editMerchantFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  firestoreConnect(query)
)(editSellerDetails);

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import SetRateActive from "./SetRateActive";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

import { setCommissionRateActive } from "../../../settings/settingsActions";
import { openSideDrawer } from "../../../sideDrawer/sideDrawerActions";
import classes from "./index.module.css";

class index extends Component {
  state = {
    activateRatePanelOpen: false,
    selectedRate: null
  };

  activateDevicePanelOpenClasses = [classes.RevealMore];

  handleRateClick = commission => {
    this.setState({
      activateRatePanelOpen: true,
      selectedRate: commission
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateRatePanelOpen: false,
      selectedRate: null
    });
  };
  render() {
    let {
      ratesList,
      activatpropseDevicerequesting,
      setCommissionRateActive,
      requesting
    } = this.props;
    const loading = requesting[`commission_rates?where=isActive:==:false`];
    let { activateRatePanelOpen, selectedRate } = this.state;

    let numberOfRecords = ratesList && ratesList.length;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    const rates =
      ratesList &&
      Object.values(ratesList).map((commission, index) => {
        return (
          <tr
            className={classes.MerchantRow}
            key={index}
            onClick={() => this.handleRateClick(commission)}
          >
            <td>{commission.type}</td>
            <td>{commission.measurementType}</td>
            <td>{commission.minimum}</td>
            <td>{commission.maximum}</td>
            <td>{commission.isActive}</td>
            <td>
              {format(
                commission.dateCreated && commission.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {rates && rates.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>No rates to approve.</h3>
            </div>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">measurementType</th>
                      <th scope="col">Minimum</th>
                      <th scope="col">Maximum</th>
                      <th className={"hide-s"} scope="col">
                        Is Active
                      </th>
                      <th className={"hide-s"} scope="col">
                        Date Created
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rates}</tbody>
                </table>

                {/* {console.log("activateRatePanelOpen", activateRatePanelOpen)} */}

                {activateRatePanelOpen ? (
                  <div className={classes.EnableDevice}>
                    <div className={classes.EnableDevice}>
                      <div className={"card card-transparent"}>
                        <div
                          className={"card-header "}
                          style={{
                            padding: "0",
                            minHeight: "4.1rem",
                            padding: "1rem",
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500"
                          }}
                        >
                          <div
                            className={"card-title semi-bold"}
                            style={{
                              color: "#666",
                              fontSize: "1.4rem",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textTransform: "none",
                              marginLeft: "0.7rem"
                            }}
                          >
                            Set Rate Active
                          </div>
                          <div className={"card-controls"}>
                            <ul>
                              <li>
                                <a
                                  data-toggle="close"
                                  className={"card-close"}
                                  href="#"
                                  onClick={this.handleCloseClick}
                                >
                                  <i className={"card-icon card-icon-close"} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"card-body"}>
                          <div className={"panel"}>
                            <Fragment>
                              <ul
                                className={
                                  "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                }
                              >
                                <li className={"nav-item active"}>
                                  <a
                                    data-toggle="tab"
                                    href="#activation"
                                    className={"active show"}
                                  >
                                    Set Rate Active
                                  </a>
                                </li>
                              </ul>
                              <div className={"tab-content"}>
                                <div
                                  className={"tab-pane active"}
                                  id="activation"
                                >
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <SetRateActive
                                        rateId={selectedRate.id}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={classes.Row}>
                <div>
                  <div
                    className={attachedPaginateSimpleNumbersClasses.join(" ")}
                  >
                    <ul>
                      <li>
                        <a href="">
                          <i className={classes.pg_arrow_left} />
                        </a>
                      </li>
                      <li className={attachedPaginateActiveClasses.join(" ")}>
                        <a href="">1</a>
                      </li>
                      <li
                        className={attachedPaginateNextClasses.join(" ")}
                        id="tableWithExportOptions_next"
                      >
                        <a href="">
                          <i className={classes.pg_arrow_right} />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={classes.dataTables_info}
                    id="tableWithExportOptions_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing <b>1 to {numberOfRecords}</b> of {numberOfRecords}{" "}
                    entries
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

let query = [
  {
    collection: "commission_rates",
    where: ["isActive", "==", false]
  }
];

const mapStateToProps = state => ({
  ratesList: state.firestore.ordered.outlet_inventory,
  requesting: state.firestore.status.requesting
});

const actions = {
  setCommissionRateActive,
  openSideDrawer
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(index));

import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import RegisterForm from "../auth/Register/RegisterForm";

const actions = { closeModal };

const registerModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create User Account" onClose={closeModal}>
      <RegisterForm />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(registerModal);

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import LoadingComponent from "../../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
import EnableMerchant from "./EnableMerchant";
import { activateMerchant } from "../../../merchants/merchantActions";

class index extends Component {
  state = {
    activateMerchantPanelOpen: false,
    selectedMerchant: null,
    loadingInitial: true
  };

  activateMerchantPanelOpenClasses = [classes.RevealMore];

  handleMerchantClick = device => {
    this.setState({
      activateMerchantPanelOpen: true,
      selectedMerchant: device
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null
    });
  };
  render() {
    let { merchantList, activateMerchant, requesting } = this.props;
    const loading = requesting[`merchants?where=isMerchantActive:==:false`];
    let {
      activateMerchantPanelOpen,
      selectedMerchant,
      loadingInitial
    } = this.state;

    let numberOfRecords = merchantList && merchantList.length;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    // merchantList &&
    //   this.setState({
    //     loadingInitial: false
    //   });

    const merchants =
      merchantList &&
      Object.values(merchantList).map((merchant, index) => {
        return (
          <tr
            className={classes.MerchantRow}
            key={index}
            onClick={() => this.handleMerchantClick(merchant)}
          >
            <td>{merchant.account}</td>
            <td>{merchant.name}</td>
            <td>{merchant.city}</td>

            <td>
              {format(
                merchant.dateCreated && merchant.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {merchants && merchants.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>No merchants to approve.</h3>
            </div>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Account</th>
                      <th scope="col">Name</th>
                      <th scope="col">City</th>
  
                      <th className={"hide-s"} scope="col">
                        Date Created
                      </th>
                    </tr>
                  </thead>
                  <tbody>{merchants}</tbody>
                </table>

                {activateMerchantPanelOpen ? (
                  <div className={classes.EnableMerchant}>
                    <div className={classes.EnableMerchant}>
                      <div className={"card card-transparent"}>
                        <div
                          className={"card-header "}
                          style={{
                            padding: "0",
                            minHeight: "4.1rem",
                            padding: "1rem",
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500"
                          }}
                        >
                          <div
                            className={"card-title semi-bold"}
                            style={{
                              color: "#666",
                              fontSize: "1.4rem",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textTransform: "none",
                              marginLeft: "0.7rem"
                            }}
                          >
                            Enable Merchant
                          </div>
                          <div className={"card-controls"}>
                            <ul>
                              <li>
                                <a
                                  data-toggle="close"
                                  className={"card-close"}
                                  href="#"
                                  onClick={this.handleCloseClick}
                                >
                                  <i className={"card-icon card-icon-close"} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"card-body"}>
                          <div className={"panel"}>
                            <Fragment>
                              <ul
                                className={
                                  "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                }
                              >
                                <li className={"nav-item active"}>
                                  <a
                                    data-toggle="tab"
                                    href="#activation"
                                    className={"active show"}
                                  >
                                    Enable Merchant
                                  </a>
                                </li>
                              </ul>
                              <div className={"tab-content"}>
                                <div
                                  className={"tab-pane active"}
                                  id="activation"
                                >
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <EnableMerchant
                                        merchant={selectedMerchant}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={classes.Row}>
                <div>
                  <div
                    className={attachedPaginateSimpleNumbersClasses.join(" ")}
                  >
                    <ul>
                      <li>
                        <a href="">
                          <i className={classes.pg_arrow_left} />
                        </a>
                      </li>
                      <li className={attachedPaginateActiveClasses.join(" ")}>
                        <a href="">1</a>
                      </li>
                      <li
                        className={attachedPaginateNextClasses.join(" ")}
                        id="tableWithExportOptions_next"
                      >
                        <a href="">
                          <i className={classes.pg_arrow_right} />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={classes.dataTables_info}
                    id="tableWithExportOptions_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing <b>1 to {numberOfRecords}</b> of {numberOfRecords}{" "}
                    entries
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

let query = [
  {
    collection: "merchants",
    where: ["isMerchantActive", "==", false]
  }
];

const mapStateToProps = state => ({
  merchantList: state.firestore.ordered.merchants,
  requesting: state.firestore.status.requesting
});

const actions = {
  activateMerchant
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(index));

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import EnableProvider from "./EnableProvider";
import { openModal } from "../modals/modalActions";
import classes from "./Providers.module.css";

class Providers extends Component {
  state = {
    loadingInitial: true,
    agreementUrl: "",
    showModal: false,
    openManageUserPanel: false,
    selectedProvider: null,
    selectedRow: -1
  };

  openEnableProviderPanelClasses = [classes.RevealMore];

  handleUserClick = (provider, index) => {
    this.setState({
      openEnableProviderPanel: true,
      selectedProvider: provider,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    this.setState({
      openEnableProviderPanel: false,
      selectedProvider: null,
      selectedRow: -1
    });
  };

  render() {
    let { selectedProvider, openEnableProviderPanel, selectedRow } = this.state;
    !selectedRow
      ? (this.transactionRowClasses = [classes.MerchantRow])
      : (this.transactionRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction
        ]);

    let providers =
      this.props.providers &&
      this.props.providers.map((provider, index) => {
        return (
          <tr
            className={
              this.state.selectedRow === index
                ? classes.SelectedTransaction
                : classes.MerchantRow
            }
            onClick={() => this.handleUserClick(provider, index)}
            key={provider.id}
          >
            <td>{provider.provider}</td>
            <td>{provider.type}</td>
            <td>{provider.isEnabled}</td>
            <td>
              {format(
                provider.dateCreated && provider.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{provider.createdBy}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th>Provider</th>
                  <th>Type</th>
                  <th>Is Enable</th>
                  <th>Date Created</th>
                  <th>Updated By</th>
                </tr>
              </thead>
              <tbody>{providers}</tbody>
            </table>
            {openEnableProviderPanel ? (
              <div className={classes.EnableUser}>
                <div className={classes.EnableUser}>
                  <div className={"card card-transparent"}>
                    <div
                      className={"card-header "}
                      style={{
                        padding: "0",
                        minHeight: "4.1rem",
                        padding: "1rem",
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500"
                      }}
                    >
                      <div
                        className={"card-title semi-bold"}
                        style={{
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          textTransform: "none",
                          marginLeft: "0.7rem"
                        }}
                      >
                        Manage Provider
                      </div>
                      <div className={"card-controls"}>
                        <ul>
                          <li>
                            <a
                              data-toggle="close"
                              className={"card-close"}
                              href="#"
                              onClick={this.handleCloseClick}
                            >
                              <i className={"card-icon card-icon-close"} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={"card-body"}>
                      <div className={"panel"}>
                        <Fragment>
                          <ul
                            className={
                              "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                            }
                          >
                            <li className={"nav-item active"}>
                              <a
                                data-toggle="tab"
                                href="#activation"
                                className={"active show"}
                              >
                                Manage Provider
                              </a>
                            </li>
                          </ul>
                          <div className={"tab-content"}>
                            <div className={"tab-pane active"} id="activation">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <EnableProvider
                                    provider={selectedProvider}
                                    closePanel={this.handleCloseClick}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

let query = props => [
  {
    collection: "providers",
    where: [["account", "==", props.account]]
  }
];

const mapStateToProps = state => ({
  providers: state.firestore.ordered.providers,
  requesting: state.firestore.status.requesting
});

const actions = {
  openModal
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(Providers));

import React, { Component, Fragment } from "react";
import format from "date-fns/format";

import classes from "./InventoryList.module.css";
import SIMCard from "./SIMCard/SIMCard";
import Devices from "./Devices/Devices";
import Computers from "./Computers/Computers";

class InventoryItems extends Component {
  renderSwitch(param) {
    console.log("param", param);
    switch (param) {
      case "SIM":
        return <SIMCard inventoryItemList={this.props.inventoryItemList} />;
      case "Device":
        return <Devices inventoryItemList={this.props.inventoryItemList} />;
      case "Computer":
        return <Computers inventoryItemList={this.props.inventoryItemList} />;
      default:
        return null;
    }
  }

  render() {
    let { inventoryItemList, inventoryType } = this.props;
    console.log("this.props", this.props);

    let numberOfRecords = this.props.inventoryItemList.length;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    console.log("inventoryItemList", Object.values(inventoryItemList));

    let inventoryItems;
    let inventoryItemsHeader;

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            {this.renderSwitch(inventoryType)}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InventoryItems;

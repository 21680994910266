import React, { Fragment } from "react";
import format from "date-fns/format";

import classes from "./Computers.module.css";

const computers = props => {
  let { inventoryItemList } = props;

  let inventoryItems = Object.values(inventoryItemList).map(
    (inventoryItem, index) => {
      return (
        <tr className={classes.MerchantRow} key={index}>
          <td>{inventoryItem.merchant}</td>
          <td>{inventoryItem.outlet}</td>
          <td>{inventoryItem.macAddress}</td>
          <td>{inventoryItem.wifiAddress}</td>
          <td>{inventoryItem.isActive ? "Yes" : "No"}</td>
          <td>{format(inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(), "YYYY-MM-DD")}</td>
        </tr>
      );
    }
  );

  return (
    <Fragment>

          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Merchant</th>
                <th scope="col">Outlet</th>
                <th scope="col">Network Address</th>
                <th className={"hide-s"} scope="col">
                  WIFI Address{" "}
                </th>
                <th className={"hide-s"} scope="col">
                  Is Active?
                </th>
                <th className={"hide-s"} scope="col">
                  Date Added
                </th>
              </tr>
            </thead>
            <tbody>{inventoryItems}</tbody>
          </table>

    </Fragment>
  );
};

export default computers;

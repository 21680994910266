import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import { compose } from "redux";

import classes from "./ItemisedReport.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ItemisedReport from "./Itemised";

class index extends Component {
  state = {
    initialLoading: true
  };

  render() {
    const reportsLink = `/reports`;

    let itemisedReport = <ItemisedReport />;

    // if (this.state.initialLoading) return <LoadingComponent inverted={true} />;

    // if (!this.state.initialLoading) {
    //   itemisedReport = <ItemisedReport />;
    // }

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={reportsLink}>Reports</Link> {" - "} Itemised
              </span>
              <br />
              <span className={classes.ByLine}>
                You are in the PrepaidPlus Reports panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Itemised Report</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {itemisedReport}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default index;

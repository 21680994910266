import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";

import firebase from "../../../../../app/config/firebase";

import { compose } from "redux";

import { openModal } from "../../../../modals/modalActions";
import classes from "./Documents.module.css";

class Documents extends Component {
  state = {
    loadingInitial: true,
    agreementUrl: "",
    showModal: false
  };

  state = {
    //showSideDrawer: false
  };

  render() {
    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let numberOfRecords = this.props.users && this.props.users.length;

    let users =
      this.props.users &&
      this.props.users.map(user => {
        return (
          <tr className={classes.MerchantRow} key={user.email}>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.role}</td>
            {/* <td>{user.dateCreated}</td> */}
            <td>
              {format(
                user.dateCreated && user.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">E-Mail(User Name)</th>
                  <th className={"hide-s"} scope="col">
                    Role
                  </th>
                  <th className={"hide-s"} scope="col">
                    Date Created
                  </th>
                </tr>
              </thead>
              <tbody>{users}</tbody>
            </table>
          </div>
          <div className={classes.Row}>
            <div>
              <div className={attachedPaginateSimpleNumbersClasses.join(" ")}>
                <ul>
                  <li>
                    <a href="">
                      <i className={classes.pg_arrow_left} />
                    </a>
                  </li>
                  <li className={attachedPaginateActiveClasses.join(" ")}>
                    <a href="">1</a>
                  </li>
                  <li
                    className={attachedPaginateNextClasses.join(" ")}
                    id="tableWithExportOptions_next"
                  >
                    <a href="">
                      <i className={classes.pg_arrow_right} />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={classes.dataTables_info}
                id="tableWithExportOptions_info"
                role="status"
                aria-live="polite"
              >
                Showing{" "}
                <b>1 to {this.props.users && this.props.users.length}</b> of{" "}
                {numberOfRecords} entries
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

let storage = firebase.storage();

const mapStateToProps = state => ({
  loading: state.async.loading
});

const actions = {
  openModal
};

export default connect(
  mapStateToProps,
  actions
)(Documents);

//import liraries
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import LoadingComponent from "../../app/layout/LoadingComponent";

import { openModal } from "../modals/modalActions";
import Tabs from "../../app/UI/Tabs/Tabs";
import UserList from "./UserList/UserList";

import classes from "./Users.module.css";

// create a component
class Users extends Component {
  render() {
    let {
      users,
      cachetUsers,
      merchantUsers,
      openModal,
      requesting
    } = this.props;
    const loading = requesting[`users?where=userType:==:System`];

    if (loading)
      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                <span className={classes.Header}>System Users</span>
                <br />
                <span className={classes.ByLine}>
                  You are in the <span className={"bold"}>System Users</span>
                  panel
                </span>
              </div>
            </div>
            <LoadingComponent />
          </div>
        </Fragment>
      );

    // if (users) {
    //   let cachetUserList = users.filter(user => user.accountType === "PrepaidPlus");
    //   let merchantUserList = users.filter(
    //     user => user.accountType === "Merchant"
    //   );
    // }

    const systemUsers = users && <UserList userList={users} />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Portal Users</span>
              <br />
              <span className={classes.ByLine}>
                You are in the <span className={"bold"}>Portal Users</span>
                panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Users</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>System Users</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          <Tabs>
                            <div label="System Users">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>System Users</h4>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    onClick={() =>
                                      openModal("RegisterSystemUserModal")
                                    }
                                  >
                                    Create System User
                                  </button>
                                </div>
                              </header>
                              {systemUsers}
                            </div>
                            <div label={""} />
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const actions = {
  openModal
};

const query = props => {
  // console.log("props", props);
  return [
    {
      collection: "users",
      where: ["userType", "==", "PrepaidPlus"]
    }
  ];
};

const mapStateToProps = state => ({
  users: state.firestore.ordered.users,
  requesting: state.firestore.status.requesting
});

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  firestoreConnect(query)
)(Users);

//import liraries
import React from "react";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";

// create a component
const navigationItems = props => {
  const { auth, profile } = props;
  const authenticated = auth.isLoaded && !auth.isEmpty;
  const userRole = profile.role;
  const userType = profile.userType;

  return (
    authenticated && (
      <ul className={classes.NavigationItems}>
        <NavigationItem link="/merchants">Merchants</NavigationItem>
        {userRole === "Administrator" && userType === "PrepaidPlus" && (
          <div>
            <NavigationItem link="/authorisation">Authorisation</NavigationItem>
            <NavigationItem link="/inventory">Inventory</NavigationItem>
            <NavigationItem link="/users">Users</NavigationItem>{" "}
            <NavigationItem link="/financials">Financials</NavigationItem>
            <NavigationItem link="/transactions">Transactions</NavigationItem>
            <NavigationItem link="/reports">Reports</NavigationItem>
            <NavigationItem link="/settings">Settings</NavigationItem>
          </div>
        )}

        {/* // <NavLink to="/dashboard" >Dashboard</NavLink>
    // <NavLink to="/members" >Members</NavLink>
    // <NavLink to="/users" >Users</NavLink>
    // <NavLink to="/reports" >Reports</NavLink> */}
      </ul>
    )
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});
//make this component available to the app
// export default navigationItems;
export default withFirebase(connect(mapStateToProps)(navigationItems));

// import React from 'react'
// import { Form, Label } from 'semantic-ui-react'

// const TextArea = ({input, rows, width, type, placeholder, meta: {touched, error}}) => {
//   return (
//     <Form.Field error={touched && !!error} width={width}>
//       <textarea {...input} placeholder={placeholder} rows={rows}></textarea>
//       {touched && error && <Label basic color='red'>{error}</Label>}
//     </Form.Field>
//   )
// }

// export default TextArea

import React from "react";

const TextInput = ({
  label,
  width,
  rows,
  required,
  input,
  type,
  placeholder,
  value,
  meta: { touched, error }
}) => {
  let attachedClasses = ["form-group", "form-group-default", required];

  //  error={condition && value}, pass error={condition ? value : undefined}

  return (
    <div className={attachedClasses.join(" ")}>
      {/* <label className={"label-sm"}>{label}</label> */}
      <div className={"controls"}>
        <textarea
          {...input}
          placeholder={placeholder}
          rows={rows}
          type={type}
          className={"form-control"}
          autoComplete="off"
        />
        {touched && error && <label className={"error"}>{error}</label>}
      </div>
    </div>
  );
};

export default TextInput;

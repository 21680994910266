import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withFirestore, firebaseConnect, isEmpty } from "react-redux-firebase";

import { compose } from "redux";

import EnableUser from "./EnableUser";
import DisableUser from "./DisableUser";
import ChangeUserPassword from "./ChangeUserPassword";
import ChangeUserName from "./ChangeUserName";

import { openModal } from "../../../../modals/modalActions";
import classes from "./User.module.css";

class Users extends Component {
  state = {
    loadingInitial: true,
    loadedMerchants: [],
    showModal: false,
    openManageUserPanel: false,
    selectedUser: null,
    selectedRow: -1
  };

  openManageUserPanelClasses = [classes.RevealMore];

  handleUserClick = (user, index) => {
    this.setState({
      openManageUserPanel: true,
      selectedUser: user,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
    this.setState({
      openManageUserPanel: false,
      selectedUser: null,
      selectedRow: -1
    });
  };

  render() {
    let { selectedUser, openManageUserPanel, selectedRow } = this.state;
    let { handleSubmit, requesting, account } = this.props;
    const loading = requesting[`users?where=account:==:${account}`];

    !selectedRow
      ? (this.transactionRowClasses = [classes.MerchantRow])
      : (this.transactionRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction
        ]);

    let users =
      this.props.users &&
      this.props.users.map((user, index) => {
        return (
          <tr
            key={user.email}
            onClick={() => this.handleUserClick(user, index)}
            className={
              this.state.selectedRow === index
                ? classes.SelectedTransaction
                : classes.MerchantRow
            }
          >
            <td>{user.displayName}</td>

            <td>{user.email}</td>
            <td>{user.role}</td>
            <td>{user.disabled ? "No" : "Yes"}</td>
            {/* <td>{user.dateCreated}</td> */}
            <td>
              {format(
                user.dateCreated && user.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Name</th>

                  <th scope="col">E-Mail(User Name)</th>
                  <th className={"hide-s"} scope="col">
                    Role
                  </th>
                  <th scope="col">Enabled</th>
                  <th className={"hide-s"} scope="col">
                    Date Created
                  </th>
                </tr>
              </thead>
              <tbody>{users}</tbody>
            </table>
            {openManageUserPanel ? (
              <div className={classes.EnableUser}>
                <div className={classes.EnableUser}>
                  <div className={"card card-transparent"}>
                    <div
                      className={"card-header "}
                      style={{
                        padding: "0",
                        minHeight: "4.1rem",
                        padding: "1rem",
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500"
                      }}
                    >
                      <div
                        className={"card-title semi-bold"}
                        style={{
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          textTransform: "none",
                          marginLeft: "0.7rem"
                        }}
                      >
                        Manage User
                      </div>
                      <div className={"card-controls"}>
                        <ul>
                          <li>
                            <a
                              data-toggle="close"
                              className={"card-close"}
                              href="#"
                              onClick={this.handleCloseClick}
                            >
                              <i className={"card-icon card-icon-close"} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={"card-body"}>
                      <div className={"panel"}>
                        <Fragment>
                          <ul
                            className={
                              "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                            }
                          >
                            <li className={"nav-item active"}>
                              <a
                                data-toggle="tab"
                                href="#activation"
                                className={"active show"}
                              >
                                {selectedUser.disabled
                                  ? "Enable User"
                                  : "Disable User"}
                              </a>
                            </li>
                            <li className={"nav-item active"}>
                              <a data-toggle="tab" href="#password">
                                Change Password
                              </a>
                            </li>
                            <li className={"nav-item active"}>
                              <a data-toggle="tab" href="#name">
                                Change Names
                              </a>
                            </li>
                          </ul>
                          <div className={"tab-content"}>
                            <div className={"tab-pane active"} id="activation">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  {selectedUser.disabled ? (
                                    <EnableUser
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  ) : (
                                    <DisableUser
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={"tab-pane"} id="password">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <div>
                                    <ChangeUserPassword
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"tab-pane"} id="name">
                              <div className={"row column-seperation"}>
                                <div className={"col-md-12"}>
                                  <div>
                                    <ChangeUserName
                                      user={selectedUser}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

const query = props => {
  // console.log("props", props);
  return [
    {
      collection: "users",
      where: ["account", "==", props.account]
    }
  ];
};

const mapStateToProps = state => ({
  users: state.firestore.ordered.users,
  requesting: state.firestore.status.requesting
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect(query)
)(Users);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import ReactExport from "react-data-export";

import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let startingBalanceCalc = transactions => {
  // let total = 0;
  let arr = [];

  let result = transactions.reduce((total, transaction) => {
    // let transaction = doc.data();

    if (!arr.includes(transaction.account)) {
      arr.push(transaction.account);
      total = total + transaction.balance;
      return total;
    } else {
      total = total + 0;
      return total;
    }
  }, 0);

  console.log("result", result);
  return result;
};

let closingBalanceCalc = balances => {
  // let total = 0;
  let arr = [];

  let balancesCopy = [...balances];
  let transactions = balancesCopy.reverse();

  let result = transactions.reduce((total, transaction) => {
    if (!arr.includes(transaction.account)) {
      arr.push(transaction.account);
      total = total + transaction.balance;
      return total;
    } else {
      total = total + 0;
      return total;
    }
  }, 0);

  return result;
};

class FinancialRecords extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {}
  };

  render() {
    const { financials, startingBalance, closingBalance } = this.props;

    let financialRecords = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    financials && financials.length > 0
      ? (financialRecords = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Amount</th>

                {/* <th scope="col">Sales Value2</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className={classes.MerchantRow}>
                <td>Starting Balance</td>
                <td>{formatMoney(startingBalance)}</td>
              </tr>

              {financials.map((report, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>{report.transactionType}</td>
                    <td>{formatMoney(report.transactionAmount)}</td>
                  </tr>
                );
              })}
              <tr className={classes.MerchantRow}>
                <td>Closing Balance</td>
                <td>{formatMoney(closingBalance)}</td>
              </tr>
            </tbody>
          </table>
        ))
      : (financialRecords = (
          <h3>There are no financial transactions for selected period.</h3>
        ));

    let group;
    let outletName;
    let reportDate;

    return (
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>{financialRecords}</div>
      </div>
    );
  }
}

const actions = {
  // getTransactionsForDashboard
};

const mapStateToProps = state => ({
  startingBalance: startingBalanceCalc(state.financials),
  closingBalance: closingBalanceCalc(state.financials),
  deposits: state.financials
    .filter(item => item.transactionType === "Bank Deposit")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0),
  financials: state.financials
    .map(doc => {
      return {
        transactionType: doc.transactionType,
        transactionAmount: doc.transactionAmount
      };
    })
    .reduce(function(arr, doc) {
      if (!(doc.transactionType in arr)) {
        arr.push((arr[doc.transactionType] = doc));
        return arr;
      }

      arr[doc.transactionType].transactionAmount += parseFloat(
        doc.transactionAmount
      )
        ? parseFloat(doc.transactionAmount)
        : 0;

      return arr;
    }, [])
});

export default connect(
  mapStateToProps,
  actions
)(FinancialRecords);

import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import firebase from "../../../../../../../app/config/firebase";
import classes from "./EnableDevice.module.css";
import { activateDevice } from "../../../../../merchantActions";

class EnableDevice extends Component {
  render() {
    const {
      activateDevice,
      handleSubmit,
      closePanel,
      device,
      outletId,
      account
    } = this.props;

    const onFormSubmit = values => {
      activateDevice(device.id);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={classes.TabHeader}>
          <div className={classes.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Serial:{" "}
                <span className={"semi-bold"}>{device.serialNumber}</span>
              </h5>
              <h5>
                Cell Number:{" "}
                <span className={"semi-bold"}>{device.cellNumber}</span>
              </h5>

              <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={"btn btn-primary btn-cons"}
                      type="submit"
                      // onClick={handleNextClick}
                    >
                      <span>Enable Device</span>
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  activateDevice
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(EnableDevice)
);

import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { formatMoney } from "../../../app/common/util/helpers";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
// import EnableMerchant from "./EnableMerchant";
// import { activateMerchant } from "../../../merchants/merchantActions";

class Index extends Component {
  state = {
    openAdditionalInfoPanel: false,
    selectedDeposit: null
  };

  activateMerchantPanelOpenClasses = [classes.RevealMore];

  handleDepositClick = deposit => {
    this.setState({
      openAdditionalInfoPanel: true,
      selectedDeposit: null
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null
    });
  };

  handleSalesReport = (deposits, depositsTotal) => {
    let reportData = [];

    reportData = deposits.map(record => {
      return [
        {
          value: record.saleType,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: record.provider,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: formatMoney(record.transactionAmount),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        }
      ];
    });

    reportData.push([
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } }
        }
      },
      {
        value: "Total",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
        }
      },
      {
        value: formatMoney(depositsTotal),
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
        }
      }
    ]);

    let reportDataSet = [
      {
        columns: ["Type", "Provider", "Amount"],
        data: reportData
      }
    ];

    return reportDataSet;
  };

  render() {
    let { depositsList, depositsTotal } = this.props;

    const deposits =
      depositsList &&
      Object.values(depositsList).map((deposit, index) => {
        return (
          <tr className={classes.MerchantRow} key={index}>
            <td>
              {format(
                deposit.transactionDateTime &&
                  deposit.transactionDateTime.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{deposit.bank}</td>
            <td>{deposit.depositMethod}</td>
            <td>{formatMoney(deposit.transactionAmount)}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {deposits && deposits.length === 0 ? (
            <h3>Merchant has no deposits for the selected period.</h3>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Deposit Date</th>
                      <th scope="col"> Bank </th>
                      <th scope="col"> Deposit Method</th>
                      <th scope="col">Amount Deposited</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposits}
                    <tr className={classes.Totals}>
                      <td colSpan="3">
                        <div className={classes.MoneyTotalLabel}> Total:</div>
                      </td>
                      <td className={classes.MoneyTotal}>
                        {formatMoney(depositsTotal)}
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  depositsList: state.financials.filter(
    item => item.transactionType === "Deposit"
  ),
  depositsTotal: state.financials
    .filter(item => item.transactionType === "Deposit")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0)
});

export default connect(mapStateToProps)(Index);

import { createReducer } from "../../app/common/util/reducerUtil";
import { FETCH_BALANCES } from "./financialsConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  // console.log('payload',payload)

  return payload.balances;
};

export default createReducer(initialState, {
  [FETCH_BALANCES]: getTransactionsForDashboard
});

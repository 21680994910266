import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";

import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";

class Index extends Component {
  state = {
    openAdditionalInfoPanel: false,
    selectedReversal: null
  };

  activateMerchantPanelOpenClasses = [classes.RevealMore];

  handleReversalClick = reversal => {
    this.setState({
      openAdditionalInfoPanel: true,
      selectedReversal: null
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null
    });
  };

  handleSalesReport = (reversals, reversalsTotal) => {
    let reportData = [];

    reportData = reversals.map(record => {
      return [
        {
          value: record.saleType,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: record.provider,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        },
        {
          value: formatMoney(record.transactionAmount),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
          }
        }
      ];
    });

    reportData.push([
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } }
        }
      },
      {
        value: "Total",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } }
        }
      },
      {
        value: formatMoney(reversalsTotal),
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } }
        }
      }
    ]);

    let reportDataSet = [
      {
        columns: ["Type", "Provider", "Amount"],
        data: reportData
      }
    ];

    return reportDataSet;
  };

  render() {
    let { reversalsList, reversalsTotal } = this.props;

    const reversals =
      reversalsList &&
      Object.values(reversalsList).map((reversal, index) => {
        return (
          <tr className={classes.MerchantRow} key={index}>
            <td>
              {format(
                reversal.transactionDateTime &&
                  reversal.transactionDateTime.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>
              {reversal.reversalType + " - " + reversal.reversalReference}
            </td>
            <td>{formatMoney(reversal.transactionAmount)}</td>
            {/* <td>{formatMoney(reversal.balance)}</td> */}
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {reversals && reversals.length === 0 ? (
            <h3>Merchant has no reversals for the selected period.</h3>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Reversal Date</th>
                      <th scope="col"> Reversal Detail</th>
                      <th scope="col">Amount Reversaled</th>
                      {/* <th scope="col">Balance After Reversal</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {reversals}
                    <tr className={classes.Totals}>
                      <td colSpan="2">
                        <div className={classes.MoneyTotalLabel}> Total:</div>
                      </td>
                      <td className={classes.MoneyTotal}>
                        {formatMoney(reversalsTotal)}
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  reversalsList: state.financials.filter(
    item => item.transactionType === "Reversal"
  ),
  reversalsTotal: state.financials
    .filter(item => item.transactionType === "Reversal")
    .reduce(function(total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0)
});

export default connect(mapStateToProps)(Index);

import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import CreateCommission from "../settings/commissions/Forms/CreateCommission";

const actions = { closeModal };

const createCommissionModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create Commission" onClose={closeModal}>
      <CreateCommission />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(createCommissionModal);

import moment from "moment";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";
import firebase from "../../app/config/firebase";

import { FETCH_TRANSACTIONS } from "./reportConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

export const getTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  // console.log("reportEndDate is ", reportEndDate);

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  console.log("reportEndDateYear is ", reportEndDateYear);
  console.log("reportEndDateMonth is ", reportEndDateMonth);
  console.log("reportEndDateDay is ", reportEndDateDay);

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  console.log("reportEndDate is ", reportEndDate);
  // console.log("outletId is ", outletId);
  // console.log("reportStartDate is ", reportStartDate);
  // console.log("reportEndDate is ", reportEndDate);
  // console.log("today is ", today);

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("outletId", "==", outletId)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime");

    let querySnap = await query.get();

    console.log("querySnap.docs.length", querySnap.docs.length);
    let transactions = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      transactions.push(transaction);
    }

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByCategory = values => async (dispatch, getState) => {
  const outletId = values.outletId;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  // console.log("reportEndDate is ", reportEndDate);

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("outletId", "==", outletId)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime");

    let transactions = await query.get().then(function(querySnapshot) {
      const result = querySnapshot.docs
        .map(doc => {
          return {
            saleType: doc.data().saleType,
            transactionAmount: doc.data().transactionAmount
          };
        })
        .reduce(
          function(arr, doc) {
            if (!(doc.saleType in arr))
              arr.__array.push((arr[doc.saleType] = doc));
            else {
              arr[doc.saleType].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: []
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByDate = values => async (dispatch, getState) => {
  const outletId = values.outletId;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("outletId", "==", outletId)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime");

    let transactions = await query.get().then(function(querySnapshot) {
      const result = querySnapshot.docs
        .map(doc => {
          return {
            transactionDateTime: doc
              .data()
              .transactionDateTime.toDate()
              .toISOString()
              .slice(0, 10),
            transactionAmount: doc.data().transactionAmount
          };
        })
        .reduce(
          function(arr, doc) {
            if (!(doc.transactionDateTime in arr))
              arr.__array.push((arr[doc.transactionDateTime] = doc));
            else {
              arr[doc.transactionDateTime].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: []
          }
        );

      return result.__array;
    });

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByUser = values => async (dispatch, getState) => {
  const outletId = values.outletId;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("outletId", "==", outletId)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime");

    let transactions = await query.get().then(function(querySnapshot) {
      const result = querySnapshot.docs
        .map(doc => {
          return {
            userId: doc.data().createdBy,
            transactionAmount: doc.data().transactionAmount
          };
        })
        .reduce(
          function(arr, doc) {
            if (!(doc.userId in arr)) arr.__array.push((arr[doc.userId] = doc));
            else {
              arr[doc.userId].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: []
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getGroupTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;
  let account = values.account;

  console.log("getGroupTransactions account ", account);

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const accountRef = firestore.collection("merchants");
  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let querySnap = await accountRef
      .doc(account)
      .get()
      .then(doc => {
        if (doc.exists) {
          return transactionsRef
            .where("transactionDateTime", ">=", reportStartDate)
            .where("transactionDateTime", "<=", reportEndDate)
            .where("group", "==", doc.data().group)
            .where("transactionType", "==", "Sale")
            .where("transactionStatus", "==", "Successful")
            .orderBy("transactionDateTime")
            .get();
        } else {
          return (querySnap = {
            docs: []
          });
        }
      });

    let transactions = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      transactions.push(transaction);
    }

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const groupReportByCategory = values => async (dispatch, getState) => {
  console.log("account is ", values);

  const account = values.account;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  // console.log("reportEndDate is ", reportEndDate);

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");
  const accountRef = firestore.collection("merchants");

  try {
    dispatch(asyncActionStart());

    let transactions = await accountRef
      .doc(account)
      .get()
      .then(doc => {
        console.log("doc.exists", doc.exists);

        if (doc.exists) {
          const group = doc.data().group;
          console.log("group", group);
          console.log("reportStartDate", reportStartDate);
          console.log("reportEndDate", reportEndDate);

          return transactionsRef
            .where("transactionDateTime", ">=", reportStartDate)
            .where("transactionDateTime", "<=", reportEndDate)
            .where("transactionType", "==", "Sale")
            .where("group", "==", group)
            .where("transactionStatus", "==", "Successful")
            .orderBy("transactionDateTime")
            .get()
            .then(function(querySnapshot) {
              const result = querySnapshot.docs
                .map(doc => {
                  console.log("doc", doc);
                  return {
                    saleType: doc.data().saleType,
                    transactionAmount: doc.data().transactionAmount
                  };
                })
                .reduce(
                  function(arr, doc) {
                    if (!(doc.saleType in arr))
                      arr.__array.push((arr[doc.saleType] = doc));
                    else {
                      arr[doc.saleType].transactionAmount += parseFloat(
                        doc.transactionAmount
                      )
                        ? parseFloat(doc.transactionAmount)
                        : 0;
                    }
                    return arr;
                  },
                  {
                    __array: []
                  }
                );

              return result.__array;
            });
        } else {
          return (transactions = []);
        }
      });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const cachetReportByCategory = values => async (dispatch, getState) => {
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  // console.log("reportEndDate is ", reportEndDate);

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let transactions = await transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime")
      .get()
      .then(function(querySnapshot) {
        const result = querySnapshot.docs
          .map(doc => {
            console.log("doc", doc);
            return {
              saleType: doc.data().saleType,
              transactionAmount: doc.data().transactionAmount
            };
          })
          .reduce(
            function(arr, doc) {
              if (!(doc.saleType in arr))
                arr.__array.push((arr[doc.saleType] = doc));
              else {
                arr[doc.saleType].transactionAmount += parseFloat(
                  doc.transactionAmount
                )
                  ? parseFloat(doc.transactionAmount)
                  : 0;
              }
              return arr;
            },
            {
              __array: []
            }
          );

        return result.__array;
      });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const groupReportByDate = values => async (dispatch, getState) => {
  const account = values.account;
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");
  const accountRef = firestore.collection("merchants");

  try {
    dispatch(asyncActionStart());

    // let query = transactionsRef
    //   .where("transactionDateTime", ">=", reportStartDate)
    //   .where("transactionDateTime", "<=", reportEndDate)
    //   .where("outletId", "==", outletId)
    //   .where("transactionStatus", "==", "Successful")
    //   .orderBy("transactionDateTime");

    let transactions = await accountRef
      .doc(account)
      .get()
      .then(doc => {
        if (doc.exists) {
          return transactionsRef
            .where("transactionDateTime", ">=", reportStartDate)
            .where("transactionDateTime", "<=", reportEndDate)
            .where("group", "==", doc.data().group)
            .where("transactionType", "==", "Sale")
            .where("transactionStatus", "==", "Successful")
            .orderBy("transactionDateTime")
            .get()
            .then(function(querySnapshot) {
              const result = querySnapshot.docs
                .map(doc => {
                  return {
                    transactionDateTime: doc
                      .data()
                      .transactionDateTime.toDate()
                      .toISOString()
                      .slice(0, 10),
                    transactionAmount: doc.data().transactionAmount
                  };
                })
                .reduce(
                  function(arr, doc) {
                    if (!(doc.transactionDateTime in arr))
                      arr.__array.push((arr[doc.transactionDateTime] = doc));
                    else {
                      arr[
                        doc.transactionDateTime
                      ].transactionAmount += parseFloat(doc.transactionAmount)
                        ? parseFloat(doc.transactionAmount)
                        : 0;
                    }
                    return arr;
                  },
                  {
                    __array: []
                  }
                );

              return result.__array;
            });
        } else {
          return (transactions = []);
        }
      });

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const cachetReportByDate = values => async (dispatch, getState) => {
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    // let query = transactionsRef
    //   .where("transactionDateTime", ">=", reportStartDate)
    //   .where("transactionDateTime", "<=", reportEndDate)
    //   .where("outletId", "==", outletId)
    //   .where("transactionStatus", "==", "Successful")
    //   .orderBy("transactionDateTime");

    let transactions = await transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime")
      .get()
      .then(function(querySnapshot) {
        const result = querySnapshot.docs
          .map(doc => {
            return {
              transactionDateTime: doc
                .data()
                .transactionDateTime.toDate()
                .toISOString()
                .slice(0, 10),
              transactionAmount: doc.data().transactionAmount
            };
          })
          .reduce(
            function(arr, doc) {
              if (!(doc.transactionDateTime in arr))
                arr.__array.push((arr[doc.transactionDateTime] = doc));
              else {
                arr[doc.transactionDateTime].transactionAmount += parseFloat(
                  doc.transactionAmount
                )
                  ? parseFloat(doc.transactionAmount)
                  : 0;
              }
              return arr;
            },
            {
              __array: []
            }
          );

        return result.__array;
      });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getCachetTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let querySnap = await transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime")
      .get();

    let transactions = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      transactions.push(transaction);
    }

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getPrepaidPlusTransactionsForDashboard = values => async (
  dispatch,
  getState
) => {
  console.log("getPrepaidPlusTransactionsForDashboard", values);
  const reportStartDate = values.reportStartDate;
  let reportEndDate = values.reportEndDate;

  let reportEndDateYear = reportEndDate.getFullYear();
  let reportEndDateMonth = reportEndDate.getMonth();
  let reportEndDateDay = reportEndDate.getDate();

  reportEndDate = new Date(
    reportEndDateYear,
    reportEndDateMonth,
    reportEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let querySnap = await transactionsRef
      .where("transactionDateTime", ">=", reportStartDate)
      .where("transactionDateTime", "<=", reportEndDate)
      .where("saleType", "==", "Electricity")
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .orderBy("transactionDateTime")
      .get();

    let transactions = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id
      };
      transactions.push(transaction);
    }

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

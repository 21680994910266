import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { openModal } from "../modals/modalActions";
import Tabs from "../../app/UI/Tabs/Tabs";
import InventoryList from "./InventoryList/InventoryList";
import LoadingComponent from "../../app/layout/LoadingComponent";

import classes from "./Inventory.module.css";

// create a component
class Inventory extends Component {
  state = {
    simFiltered: [],
    deviceFiltered: [],
    cellSearchText: "",
    deviceSearchText: ""
  };

  filterByCellNumber = event => {
    let simList = this.props.inventory.filter(item => item.type === "SIM");
    let keyword = event.target.value;
    let simFiltered = simList.filter(sim => {
      return sim.cellNumber.indexOf(keyword) > -1;
    });
    this.setState({
      simFiltered,
      cellSearchText: event.target.value
    });
  };

  filterBySerialNumber = event => {
    let deviceList = this.props.inventory.filter(
      item => item.type === "Device"
    );
    let keyword = event.target.value;
    let deviceFiltered = deviceList.filter(device => {
      return device.serialNumber.indexOf(keyword) > -1;
    });
    this.setState({
      deviceFiltered,
      deviceSearchText: event.target.value
    });
  };

  render() {
    let simFiltered = this.state.simFiltered;
    let { deviceFiltered, deviceSearchText, cellSearchText } = this.state;

    const { inventory, openModal, requesting } = this.props;
    const loading = requesting[`inventory`];
    let inventoryList = "Spinner.....";
    let simCard;
    let devices;
    let computers;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];
    if (inventory) {
      let simList = inventory.filter(item => item.type === "SIM");
      let deviceList = inventory.filter(item => item.type === "Device");
      let computerList = inventory.filter(item => item.type === "Computer");

      cellSearchText.length > 0 && simFiltered.length === 0
        ? (simCard = (
            <div ref={this.handleContextRef}>
              <div style={{ paddingBottom: "30px" }}>
                <h5> No SIM cards found for query "{cellSearchText}" </h5>
              </div>
            </div>
          ))
        : (simCard = (
            <div ref={this.handleContextRef}>
              <InventoryList
                inventoryItemList={
                  simFiltered.length === 0 ? simList : simFiltered
                }
                inventoryType="SIM"
              />
            </div>
          ));

      deviceSearchText.length > 0 && deviceFiltered.length === 0
        ? (devices = (
            <div ref={this.handleContextRef}>
              <div style={{ paddingBottom: "30px" }}>
                <h5> No devices found for query "{deviceSearchText}" </h5>
              </div>
            </div>
          ))
        : (devices = (
            <div ref={this.handleContextRef}>
              <InventoryList
                inventoryItemList={
                  deviceFiltered.length === 0 ? deviceList : deviceFiltered
                }
                inventoryType="Device"
              />
            </div>
          ));

      computers = (
        <InventoryList
          inventoryItemList={computerList}
          inventoryType="Computer"
        />
      );
    }
    if (loading)
      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                <span className={classes.Header}>PrepaidPlus Inventory</span>

                <br />
                <span className={classes.ByLine}>
                  You are in the{" "}
                  <span className={"bold"}>PrepaidPlus Inventory</span> panel
                </span>
              </div>
            </div>
            <LoadingComponent inverted={true} />
          </div>
        </Fragment>
      );

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>PrepaidPlus Inventory</span>

              <br />
              <span className={classes.ByLine}>
                You are in the <span className={"bold"}>PrepaidPlus Inventory</span>{" "}
                panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>PrepaidPlus Inventory</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          <Tabs>
                            <div label="Devices">
                              <header className={classes.TabHeader}>
                                {/* <div className={classes.PageHeader}>
                                  <h4>Devices</h4>
                                </div> */}

                                <div className={classes.PageHeader}>
                                  <div className={classes.PageTitle}>
                                    <h4>Devices</h4>
                                  </div>
                                  <div className={classes.PageFilter}>
                                    {/* <h4 className="m-r-20"> Search:</h4> */}

                                    {/* <!-- START Form Control--> */}
                                    <div className={attachedClasses.join(" ")}>
                                      <label>Serial Number</label>
                                      <div className={"controls"}>
                                        <input
                                          type="text"
                                          className={classes.FormControl}
                                          placeholder="Filter by Serial Number"
                                          value={this.state.deviceSearchText}
                                          onChange={this.filterBySerialNumber}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    onClick={() =>
                                      openModal("RegisterDeviceModal")
                                    }
                                  >
                                    Add New Device
                                  </button>
                                </div>
                              </header>
                              {devices}
                            </div>
                            <div label="SIM Cards">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <div className={classes.PageTitle}>
                                    <h4>SIM Cards</h4>
                                  </div>
                                  <div className={classes.PageFilter}>
                                    {/* <h4 className="m-r-20"> Search:</h4> */}

                                    {/* <!-- START Form Control--> */}
                                    <div className={attachedClasses.join(" ")}>
                                      <label>Cell Number</label>
                                      <div className={"controls"}>
                                        <input
                                          type="text"
                                          className={classes.FormControl}
                                          placeholder="Filter by Cell Number"
                                          value={this.state.cellSearchText}
                                          onChange={this.filterByCellNumber}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.AddUser}>
                                  <button
                                    className={classes.AddUserBtn}
                                    onClick={() =>
                                      openModal("RegisterSIMCardModal")
                                    }
                                  >
                                    Register New SIM Card
                                  </button>
                                </div>
                              </header>
                              {simCard}
                            </div>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  inventory: state.firestore.ordered.inventory,
  requesting: state.firestore.status.requesting
});

const actions = {
  openModal
};

// export default connect(null,actions)(Outlet);

// export default Outlet;
export default connect(
  mapStateToProps,
  actions
)(firestoreConnect([{ collection: "inventory" }])(Inventory));

import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import RegisterDeviceWithOutlet from "../inventory/Devices/Registration/RegisterDeviceWithOutlet"

const actions = { closeModal };

const registerDeviceWithOutletModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Register Device with Outlet" onClose={closeModal}>
      <RegisterDeviceWithOutlet />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(registerDeviceWithOutletModal);

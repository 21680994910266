import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric
} from "revalidate";
import format from "date-fns/format";
import moment from "moment";
import { isNumber } from "util";

import DateInput from "../../../app/common/form/DateInput";
import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";

import { createDeposit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  deposit_method: composeValidators(customIsRequired)(),
  deposit_bank: composeValidators(customIsRequired)(),
  deposit_amount: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    })
  )(),
  deposit_date: composeValidators(customIsRequired)()
});

const $ = window.$;

const depositBank = [
  {
    label: "First National Bank",
    value: "First National Bank"
  },
  {
    label: "Barclays Bank",
    value: "Barclays Bank"
  }
];

const depositType = [
  {
    label: "EFT",
    value: "EFT"
  },
  {
    label: "Cash",
    value: "Cash"
  },
  {
    label: "Cheque",
    value: "Cheque"
  }
];

class DepositForm extends Component {
  state = {
    depositDate: null
  };

  componentDidMount() {
    $(findDOMNode(this.depositDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "0d"
    });
    var _this = this;
    $(findDOMNode(this.depositDate)).on("changeDate", function(e) {
      _this.props.change("deposit_date", e.date);
    });
  }

  componentWillUnmount() {
    $(findDOMNode(this.depositDate)).datepicker("destroy");
  }

  render() {
    const { handleSubmit, createDeposit, error } = this.props;

    const onFormSubmit = values => {
      console.log("values", values);
      createDeposit({
        values
      });
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              name="deposit_bank"
              options={depositBank}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0"
                }
              })}
              component={SelectInput}
              placeholder="Bank"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              name="deposit_method"
              options={depositType}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0"
                }
              })}
              component={SelectInput}
              placeholder="Method"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="Deposit Amount"
              name="deposit_amount"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Amount"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <div className={"input-group date col-md-12 p-l-0"}>
              <Field
                type="text"
                className={"form-control"}
                component="input"
                placeholder="Pick Deposit date"
                name="deposit_date"
                ref={input => {
                  this.depositDate = input;
                }}
              />

              <div className={"input-group-append"}>
                <span className={"input-group-text"}>
                  <i className={"fa fa-calendar"} />
                </span>
              </div>

              {/* <Field
                type="text"
                className={"form-control"}
                component={renderField}
                placeholder="Pick Deposit date"
                name="deposit_date"
                ref={input => {
                  this.depositDate = input;
                }}
              /> */}
            </div>
          </div>
        </div>

        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Create Deposit
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    account: state.modals.modalProps.account,
    merchantName: state.modals.modalProps.merchantName,
    transactionCategory: "Credit",
    transactionType: "Deposit",
    depositType: "Bank Deposit",
    isTransactionCharged: false
  },
  enableReinitialize: true
});

const actions = {
  createDeposit,
  change
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "depositForm", validate })(DepositForm));
